import dayjs from 'dayjs';

import request from '../../utils/Request';
import { Methods, NotificationType } from '../../config';
import { downloadFile } from '../../utils/download';
import { ResponseType } from '../../config/responetype';
import addNotification from '../../utils/addNotification';

const baseURL = 'resourceOne';

const resourceOneService = {
  getResources: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/resources`,
      data,
    });
  },

  createResource: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateResource: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  deleteResource: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  orderResource: (id, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/orderResource/${id}`,
      data,
    });
  },

  addResourceToList: (data, id) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/projectResource/${id}`,
      data,
    });
  },

  deleteResourceFromList: (data, listId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/projectResource/${listId}`,
      data,
    });
  },

  updateProjectResource: (data, listId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/projectResource/${listId}`,
      data,
    });
  },

  addCommunicationToList: (data, id) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/resourceOneCommunication/${id}`,
      data,
    });
  },

  deleteCommunicationFromList: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/resourceOneCommunication/${id}`,
      data,
    });
  },

  updateCommunication: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/resourceOneCommunication/${id}`,
      data,
    });
  },

  getClassificationBySubMenuId: (subMenuId) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/classificationItems/${subMenuId}`,
    });
  },

  getExcelTemplate: async () => {
    const excelFromServer = await request({
      responseType: 'blob',
      resource: `${baseURL}/exceltemplate`,
    });
    return downloadFile(excelFromServer, `Eroforras_1_template-${dayjs().format('YYYYMMDD_HHmm')}.xlsx`);
  },

  getExcelData: async () => {
    const excelFromServer = await request({
      responseType: 'blob',
      resource: `${baseURL}/exceldata`,
    });
    return downloadFile(excelFromServer, `Eroforras_1-${dayjs().format('YYYYMMDD_HHmm')}.xlsx`);
  },

  importExcel: async (files, userId) => {
    const formData = new FormData();

    for (const file of files) {
      formData.append('files', file);
    }
    formData.append('userId', userId);

    const resp = await request({
      method: Methods.POST,
      resource: `${baseURL}/excelimport`,
      formData: true,
      data: formData,
      responseType: 'blob',
    });

    if (resp?.type === ResponseType.xlsx) {
      addNotification({
        content: 'Az import sikertelen!',
        type: NotificationType.ERROR,
      });

      downloadFile(resp, `ERR_${files[0].name}`);
      return Promise.reject(new Error('Error in uploaded file!'));
    }

    const reader = new FileReader();
    reader.readAsText(resp);
    reader.onload = () => {
      const jsonData = JSON.parse(reader.result);
      if (jsonData?.error?.message) {
        return addNotification({
          content: jsonData.error.message,
          type: NotificationType.ERROR,
        });
      }
      return jsonData.data;
    };
  },
};

export default resourceOneService;
