import React, { useState, useEffect } from 'react';

import appService from '../../service/app.service';
import { ButtonUI, DialogUI, FormLabelUI, SelectUI } from '../Interface';

const DashboardModify = (props) => {
  const { dashboarRoleState } = props;
  const { dashboarRolePermissions, setDashboardRolePermissions } = dashboarRoleState;
  const [permission, setPermission] = useState(false);

  useEffect(() => {
    setPermission(props.hasPermission);
  }, [props.hasPermission, props.show]);

  const handleChangeRole = async () => {
    appService.updateDashboardRolePermission(props.roleId, props.selectedDashboard, permission).then(() => {
      const updatedSubMenuList = dashboarRolePermissions.map((p) =>
        p.dashboardId === props.selectedDashboard ? { ...p, hasPermission: permission } : p
      );
      setDashboardRolePermissions(updatedSubMenuList);
      props.setRefreshPage(!props.refreshPage);
      props.onHide();
    });
  };

  const options = [
    { value: true, label: 'Jogosult' },
    { value: false, label: 'Nem jogosult' },
  ];

  return (
    <DialogUI open={props.show} headerContent={'Jogosultság módosítás'} fullWidth>
      <div className="flex justify-center">
        <div className="mt-2 mx-2 w-2/5">
          <SelectUI
            value={permission ?? ''}
            onChange={(e) => {
              const value = e.target.value;
              setPermission(value);
            }}
            option={options}
            label={<FormLabelUI text="Jogosultság beállítása" />}
          />
        </div>
      </div>
      <div className="px-4 mt-8 flex justify-center gap-3">
        <ButtonUI text="Hozzáadás" type="button" className="bg-success" onClick={handleChangeRole}>
          Mentés
        </ButtonUI>

        <ButtonUI text="Mégse" type="button" onClick={props.onHide} color="blue-gray" variant="gradient"></ButtonUI>
      </div>
    </DialogUI>
  );
};

export default DashboardModify;
