import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'bi/base';

const biService = {
  getBiViews: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/views`,
    });
  },

  getViewFields: (viewElement) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/viewFields/${viewElement}`,
    });
  },

  createBiHead: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/biHead`,
      data,
    });
  },

  updateBiHead: (data, biHeadId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/biHead/${biHeadId}`,
      data,
    });
  },

  deleteBiHead: (biHeadId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/biHead/${biHeadId}`,
    });
  },

  deleteBiReport: (biReportId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/biReport/${biReportId}`,
    });
  },

  getBiHeads: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/biHeads`,
      data,
    });
  },

  getBiReports: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/biReports`,
      data,
    });
  },

  getAllBiHeads: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/allBiHeads`,
    });
  },

  upsertBiReportWithFields: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/upsertBiReportWithFields`,
      data,
    });
  },

  getReport: (id) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/biReport/${id}`,
    });
  },

  getReportData: (id, activeLevel, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/reportData/${id}/${activeLevel ?? ''}`,
      data,
    });
  },

  allBiReports: () => {
    return request({
      resource: `${baseURL}/allBiReports`,
    });
  },

  getReportReportGridState: (id) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/getReportGridState/${id}`,
    });
  },

  saveReportGridState: (id, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/saveReportGridState/${id}`,
      data,
    });
  },
};

export default biService;
