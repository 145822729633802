import React from 'react';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { grey } from '@mui/material/colors';

const OrderArrowsUI = ({ onUpClick, onDownClick, disabled }) => {
  return (
    <div className="flex gap-1">
      <NorthIcon
        fontSize="small"
        className="cursor-pointer"
        sx={{
          color: disabled && grey[500],
        }}
        onClick={!disabled ? onUpClick : null}
      />
      <SouthIcon
        fontSize="small"
        className="cursor-pointer"
        sx={{
          color: disabled && grey[500],
        }}
        onClick={!disabled ? onDownClick : null}
        disabled={disabled}
      />
    </div>
  );
};

export default OrderArrowsUI;
