import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Formik, Form } from 'formik';

import biService from '../../../service/bi.service';
import Yup from '../../../utils/yup';
import { useLoader } from '../../../provider/LoaderProvider';
import addNotification from '../../../utils/addNotification';
import { NotificationType } from '../../../config';
import useMenus from '../../../context/MenuContext';
import { FormHeaderUI, FormButtonPanelUI, DeleteDialogUI } from '../../Interface';
import BiDataSourceField from './BiDataSourceFields';
import BiDataSourceReports from './BiDataSourceReports';

const BiDataSourcePage = () => {
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const defaultValues = useMemo(() => ({ name: '', element: '' }), []);
  const { showLoader, hideLoader } = useLoader();
  const [initValues, setInitValues] = useState({});
  const [refetchTable, setRefetchTable] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [biViews, setBiViews] = useState([]);
  const [canEdit, setCanEdit] = useState(true);

  const valuesToForm = useCallback(() => {
    const { name, element } = selectedValues;

    setInitValues({
      name,
      element,
    });
  }, [selectedValues]);

  useEffect(() => {
    setInitValues(defaultValues);
    getBiViews();
  }, [defaultValues]);

  useEffect(() => {
    getMenus();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('bi/datasource');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (selectedValues) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues, defaultValues, valuesToForm]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    element: Yup.string().required(),
  });

  const getBiViews = () => {
    biService.getBiViews().then((data) => {
      const biData = data.map((dbObj) => {
        return { value: dbObj.TABLE_NAME, label: dbObj.TABLE_NAME };
      });
      setBiViews(biData);
    });
  };

  const handleInsertObject = (values, resetForm) => {
    showLoader();

    biService
      .createBiHead(values)
      .then((_data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        resetForm();
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
        setInitValues({});
        setSelectedValues({});
      })
      .finally(() => {
        hideLoader();
      });
  };

  const updateObject = async (values, resetForm) => {
    biService.updateBiHead(values, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setEditButtonvisible(true);
      resetForm();
      setInitValues({});
      setSelectedValues({});
      setViewField(true);
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values, resetForm);
    } else if (!addField) {
      handleInsertObject(values, resetForm);
    }
  };

  const handleRemoveElement = async () => {
    biService.deleteBiHead(selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues({});
    });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-6 h-screen gap-2 p-4 bg-gray-100 mt-[-4rem] pt-[5rem]">
      <div className="col-span-1 lg:col-span-1 xl:col-span-2 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="h-full overflow-auto custom-scrollbar">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues({});
              setInitValues(defaultValues);
              setViewField(false);
              setAddField(false);
            }}
            addDisabled={!viewField || !canEdit}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled || !canEdit}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, resetForm, validateField, setFieldValue }) => (
              <Form>
                <BiDataSourceField
                  viewField={viewField}
                  selectedValues={selectedValues}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  validateField={validateField}
                  dataset={{ biViews }}
                  isEdit={!editButtonVisible}
                />
                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      resetForm();
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="col-span-1 lg:col-span-1 xl:col-span-4 overflow-hidden bg-white shadow sm:rounded-lg  ">
        <div className="h-full overflow-auto custom-scrollbar">
          <BiDataSourceReports
            refetch={{ refetchTable, setRefetchTable }}
            values={{ selectedValues, setSelectedValues }}
            viewField={viewField}
            operatorsDisabled={operatorsDisabled}
            dataset={{ biViews }}
          />
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </div>
  );
};

export default BiDataSourcePage;
