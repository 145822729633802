import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'innovation';

const innovationService = {
  getInnovations: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/innovations`,
      data,
    });
  },

  createInnovation: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateInnovationStatus: (data) => {
    return request({
      method: Methods.PATCH,
      resource: `${baseURL}/status`,
      data,
    });
  },

  updateInnovation: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  deleteInnovation: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  addCommunicationToList: (data, id) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/communication/${id}`,
      data,
    });
  },

  deleteCommunicationFromList: (data, communicationId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/communication/${communicationId}`,
      data,
    });
  },

  updateCommunication: (data, communicationId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/updateCommunication/${communicationId}`,
      data,
    });
  },
};

export default innovationService;
