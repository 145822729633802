import React from 'react';

import SimpleTaskReports from './SimpleTaskReports';
import { SimpleTaskForm } from './SimpleTaskForm';
import { useSimpleTask } from '../../hooks/useSimpleTask';

export const SimpleTaskMain = () => {
  const simpleTaskState = useSimpleTask();

  return (
    <div className="grid grid-cols-1 xl:grid-cols-6 grid-rows-[auto_1fr] gap-y-4 xl:gap-4 bg-gray-100 p-4">
      <SimpleTaskForm simpleTaskState={simpleTaskState} />
      <SimpleTaskReports simpleTaskState={simpleTaskState} />
    </div>
  );
};
