import { Tooltip } from '@mui/material';
import React from 'react';

const TooltipUI = (props) => {
  const { title } = props;
  return (
    <Tooltip
      title={title}
      {...props}
      slotProps={{
        tooltip: {
          sx: {
            color: '#514E6A',
            backgroundColor: '#ffff',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            fontSize: '0.8rem',
            maxWidth: '200px',
          },
        },
        arrow: {
          sx: {
            color: '#ffff',
          },
        },
      }}
    />
  );
};

export default TooltipUI;
