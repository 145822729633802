import React from 'react';
import { Field } from 'formik';

import { AutoCompleteSelectUI, FormErrorUI, FormLabelUI, InputUI } from '../Interface';

const FormFields = ({ viewField, values, errors, params, submenuId, setFieldValue, validateField, selectedValues }) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-3 mx-4 mb-5">
      <div className="col-span-4">
        <Field
          type="text"
          name="companyName"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Cég neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.companyName} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.companyName !== '' }}
        />
      </div>

      <div className="w-full col-span-4">
        <h2 className="mt-2 text-xl text-labelColor">{params.COMPANY_TITLE ?? 'Cím'}</h2>
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="postCode"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Irányítószám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.postCode} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.postCode !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="city"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Helység" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.city} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.city !== '' }}
        />
      </div>
      <div className="col-span-2">
        <Field
          type="text"
          name="street"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Cím" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.street} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.street !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="statusId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Státusz" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.statusId} />}
          onChange={(_e, newVal) => {
            setFieldValue('statusId', newVal?.value ?? null).then(() => {
              validateField('statusId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.statusId !== '' }}
          selectedValue={values.statusId}
          selectedLabelValue={selectedValues?.status?.itemName}
          table={['statusitems', 'status']}
          listType={{ id: 'itemId', name: 'itemName' }}
          joins={'statusitems.statusId = status.statusId'}
          conditions={[`statusitems.archive = 'N'`, `status.subMenuId = ${submenuId}`]}
        />
      </div>
      {/*
      <div className="w-full col-span-4">
        <h2 className="mt-2 text-xl text-labelColor">Számlázási cím</h2>
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="billPostcode"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Irányítószám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.billPostcode} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.billPostcode !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="billCity"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Helység" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.billCity} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.billCity !== '' }}
        />
      </div>
      <div className="col-span-4">
        <Field
          type="text"
          name="billAddress"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Cím" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.billAddress} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.billAddress !== '' }}
        />
      </div>
     */}
    </div>
  );
};

export default FormFields;
