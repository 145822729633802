import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'projectResourcePlanner';

const projectResourcePlannerService = {
  getProjectResourcesByType: (type, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/${type}`,
      data,
    });
  },

  getTasksByType: (type, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/tasks/${type}`,
      data,
    });
  },

  updateTask: (data, simpleTaskId) => {
    return request({
      method: Methods.PATCH,
      resource: `${baseURL}/task/${simpleTaskId}`,
      data,
    });
  },

  getWorkHours: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/getWorkhours`,
      data,
    });
  },
};
export default projectResourcePlannerService;
