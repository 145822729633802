import React from 'react';
import { Field } from 'formik';

import { InputUI, DatePickerUI, AutoCompleteSelectUI, TimePickerUi, FormErrorUI, FormLabelUI } from '../Interface';

const VehiclePlanFields = ({
  viewField,
  selectedValues,
  values,
  validateField,
  setFieldValue,
  errors,
  autoCompleteData,
}) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-2 mx-4 mb-5">
      <div className="col-span-4 md:col-span-2 ">
        <Field
          type="date"
          name="startDate"
          component={DatePickerUI}
          value={values.startDate}
          fullWidth
          label={<FormLabelUI text="Kezdő dátum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.startDate} />}
          onChange={(date) => {
            setFieldValue('startDate', date).then(() => {
              validateField('startDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.startDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="time"
          name="startDate"
          component={TimePickerUi}
          value={values.startDate}
          fullWidth
          label={<FormLabelUI text="Kezdő időpont" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.startDate} />}
          onChange={(date) => {
            setFieldValue('startDate', date).then(() => {
              validateField('startDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.startDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="duration"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Időintervallum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.duration} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.duration !== '' }}
          onChange={(e) => {
            const newendDate = values.startDate.add(e.target.value, 'hour');
            setFieldValue('duration', e.target.value ?? '');
            setFieldValue('endDate', newendDate).then(() => {
              validateField('endDate');
            });
          }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="date"
          name="endDate"
          component={DatePickerUI}
          value={values.endDate}
          fullWidth
          label={<FormLabelUI text="Befejező dátum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.endDate} />}
          onChange={(date) => {
            setFieldValue('endDate', date).then(() => {
              validateField('endDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.endDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="employeeId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Dolgozó" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.employeeId} />}
          onChange={(_e, newVal) => {
            setFieldValue('employeeId', newVal?.value ?? null).then(() => {
              validateField('employeeId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.employeeId !== '' }}
          selectedValue={values.employeeId}
          selectedLabelValue={selectedValues?.employee?.name}
          dataset={autoCompleteData.employees}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="vehicleId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Autó" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.vehicleId} />}
          onChange={(_e, newVal) => {
            setFieldValue('vehicleId', newVal?.value ?? null).then(() => {
              validateField('vehicleId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.vehicleId !== '' }}
          selectedValue={values.vehicleId}
          selectedLabelValue={selectedValues?.vehicle?.name}
          dataset={autoCompleteData.vehicles}
        />
      </div>
    </div>
  );
};

export default VehiclePlanFields;
