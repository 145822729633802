import { useState, useEffect } from 'react';
import _, { isEqual } from 'lodash';
import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import companyService from '../../service/company.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import useMenus from '../../context/MenuContext';
import useParam from '../../context/ParamContext';
import useUsers from '../../context/UsersContext';
import { getDynVals } from '../../utils/DynHelper';

import FormTabs from './FormTabs';
import CompanyReports from './CompanyReports';
import FormFields from './FormFields';
import { FormHeaderUI, FormButtonPanelUI, DeleteDialogUI } from '../Interface';

export const CompanyForm = () => {
  const { user, getUser } = useUsers();
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getParam, params } = useParam();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [dynProps, setDynProps] = useState([]);

  const [submenu, setSubmenu] = useState(null);
  const [refetchTable, setRefetchTable] = useState(true);
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [canEdit, setCanEdit] = useState(true);
  const [contactList, setContactList] = useState([]);

  useEffect(() => {
    getMenus();
    getParam();
    getUser();

    const def = {
      companyId: null,
      companyName: '',
      billPostcode: '',
      billCity: '',
      billAddress: '',
      postCode: '',
      city: '',
      street: '',
      companyDescription: '',
      statusId: null,
    };

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('company');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!isEqual(selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
        setContactList(selectedValues.companyContact ?? []);
      } else {
        setOperatorsDisabled(true);
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required(),
    billPostcode: Yup.number().nullable().positive(),
    billCity: Yup.string().nullable(),
    billAddress: Yup.string().nullable(),
    postCode: Yup.number().nullable().nullable(),
    city: Yup.string().nullable(),
    street: Yup.string().nullable(),
    companyDescription: Yup.string().nullable(),
    statusId: Yup.string().nullable(),
  });

  const valuesToForm = () => {
    const {
      companyId,
      companyName,
      billPostcode,
      billCity,
      billAddress,
      postCode,
      city,
      street,
      companyDescription,
      statusId,
    } = selectedValues;

    setInitValues({
      companyId,
      companyName,
      billPostcode,
      billCity,
      billAddress,
      postCode,
      city,
      street,
      companyDescription,
      statusId,
      dynamicFields: getDynVals(dynProps, selectedValues.dynamicProperties),
    });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    companyService.updateCompany(updData, selectedValues.companyId).then((_company) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setSelectedValues({});
      setRefetchTable(true);
      setEditButtonvisible(true);
      setAddField(true);
      setInitValues({});
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
    };

    companyService
      .createCompany(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setSelectedValues({});
        setRefetchTable(true);
        setEditButtonvisible(true);
        setAddField(true);
        setInitValues({});
        setViewField(true);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    companyService.deleteCompany(deleteData, selectedValues.companyId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultValues);
    });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-6 h-screen gap-2 p-4 bg-gray-100 mt-[-4rem] pt-[5rem]">
      <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="h-full overflow-auto custom-scrollbar">
          <FormHeaderUI
            addClick={() => {
              setInitValues(defaultValues);
              setSelectedValues({});
              setViewField(false);
              setAddField(false);
              setContactList([]);
            }}
            addDisabled={!viewField || !canEdit}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled || !canEdit}
            copyClick={() => {
              setViewField(false);
              setAddField(false);
            }}
            copyDisabled={!viewField || operatorsDisabled || !canEdit}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
            //validateOnMount={false}
          >
            {({ isSubmitting, values, errors, resetForm, setFieldValue, validateField }) => (
              <Form>
                <FormFields
                  values={values}
                  errors={errors}
                  viewField={viewField}
                  params={params}
                  setFieldValue={setFieldValue}
                  validateField={validateField}
                  submenuId={submenu}
                  selectedValues={selectedValues}
                />

                <FormTabs
                  values={values}
                  errors={errors}
                  disabled={viewField}
                  setFieldValue={setFieldValue}
                  selectedValues={selectedValues}
                  validateField={validateField}
                  submenuId={submenu}
                  contact={{ contactList, setContactList }}
                  params={params}
                  user={user}
                  setRefetchTable={setRefetchTable}
                  setDefaultValues={setDefaultValues}
                  dynProperties={{ dynProps, setDynProps }}
                />

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      resetForm();
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white shadow sm:rounded-lg  ">
        <div className="h-full overflow-auto custom-scrollbar">
          <CompanyReports
            refetch={{ refetchTable, setRefetchTable }}
            values={{ selectedValues, setSelectedValues }}
            viewField={viewField}
            operatorsDisabled={operatorsDisabled}
          />
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </div>
  );
};
