import React from 'react';
import { Field } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, AutoCompleteSelectUI } from '../Interface';

const PropertyGroupFields = ({
  viewField,
  values,
  errors,
  autoCompleteData,
  setFieldValue,
  validateField,
  selectedValues,
}) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="name"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Csoport neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.name !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="formId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Form" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.formId} />}
          onChange={(_e, newVal) => {
            setFieldValue('formId', newVal?.value ?? null).then(() => {
              validateField('formId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.formId !== '' }}
          selectedValue={values.formId}
          selectedLabelValue={selectedValues?.subMenu?.subMenuName}
          dataset={autoCompleteData.submenus}
        />
      </div>
    </div>
  );
};

export default PropertyGroupFields;
