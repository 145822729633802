import React, { useEffect, useState } from 'react';
import { Typography } from '@material-tailwind/react';

import sapResourcePlannerService from '../../../service/sapResourcePlanner.service';
import { DataGridUI, DataGridIdentifiers, DebouncedInputUI } from '../../Interface';
import { dataGridColumns } from '../../../utils/helper';
import DependecyListSelectedOpPanel from './DependecyListSelectedOpPanel';

const DependencyList = ({ task, getAllProductionOrdersWithSapProDependencies }) => {
  const defaultGridState = { rows: [], rowCount: 0 };
  const [operations, setOperations] = useState(defaultGridState);
  const [selectedOperation, setSelectedOperation] = useState({});
  const [dependencies, setDependencies] = useState(defaultGridState);
  // ki kell deríteni, hogy ennek a selectjével mit tudok kezdeni
  const [selectedDependencies, setSelectedDepencies] = useState({});
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (task) {
      getSapDependencyList();
    }
  }, [task]);

  useEffect(() => {
    setOperations(defaultGridState);
    setDependencies(defaultGridState);
  }, []);

  useEffect(() => {
    if (filter) {
      sapResourcePlannerService
        .getSapProdOperationForDependency(filter)
        .then((data) => setOperations({ rows: data, rowCount: data?.length }));
    } else {
      setOperations(defaultGridState);
    }
  }, [filter]);

  const getSapDependencyList = () => {
    getAllProductionOrdersWithSapProDependencies();
    sapResourcePlannerService.getSapDependencyList(task.DocEntry, task.LineNum).then((data) => {
      setDependencies({ rows: data, rowCount: data?.length });
      setFilter('');
      setOperations(defaultGridState);
      setSelectedOperation({});
    });
  };

  const operationGridColumns = dataGridColumns([
    {
      field: 'DocNum',
      headerName: 'GYU',
      flex: 1,
    },
    {
      field: 'Project',
      headerName: 'Projekt',
      flex: 1,
    },
    {
      field: 'ItemCode',
      headerName: 'Művelet',
      flex: 1,
    },
    {
      field: 'U_QNT_GEP',
      headerName: 'Gép',
      flex: 1,
    },
  ]);

  const dependenciesGridColumns = dataGridColumns([
    {
      field: 'Project',
      headerName: 'Projekt',
      valueGetter: (data) => {
        return data.row.prodOrder.Project;
      },
      flex: 1,
    },
    {
      field: 'operation',
      headerName: 'Művelet',
      valueGetter: (data) => {
        return data.row.prodOrder.ItemCode;
      },
      flex: 1,
    },
    {
      field: 'operation',
      headerName: 'Művelet',
      valueGetter: (data) => {
        return data.row.prodOrder.U_QNT_GEP;
      },
      flex: 1,
    },
  ]);

  return (
    <div className=" grid-cols-4 gap-x-4 gap-y-2 mx-2 md:grid">
      {!Object.keys(selectedOperation).length && (
        <>
          <div className="col-span-4 flex justify-center mt-5">
            <DebouncedInputUI
              label="Feladat neve"
              debounceMs={800}
              quickFilterSearchValue={filter}
              setQuickFilterSearchValue={(newValue) => {
                setFilter(newValue);
              }}
            />
          </div>

          <div className="col-span-4 mt-2">
            <Typography>
              <h4 className="font-semibold">Művelet</h4>
            </Typography>
          </div>

          <div className="col-span-4 mt-2">
            <DataGridUI
              datagridIdentifier={DataGridIdentifiers.operations}
              rows={operations.rows}
              columns={operationGridColumns}
              sx={{
                height: '200px',
                width: '100%',
              }}
              hideFooter
              onRowClick={(rowData) => {
                setSelectedOperation(rowData.row);
              }}
            />
          </div>
        </>
      )}
      {Object.keys(selectedOperation).length && (
        <DependecyListSelectedOpPanel
          parent={task}
          selectedOperationState={{ selectedOperation, setSelectedOperation }}
          getSapDependencyList={getSapDependencyList}
        />
      )}
      <div className="col-span-4 mt-2">
        <Typography>
          <h4 className="font-semibold">Függőségek</h4>
        </Typography>
      </div>
      <div className="col-span-4 mt-2">
        <DataGridUI
          datagridIdentifier={DataGridIdentifiers.dependencies}
          rows={dependencies.rows}
          columns={dependenciesGridColumns}
          sx={{
            height: '250px',
            width: '100%',
          }}
          onRowClick={(rowData) => {
            setSelectedDepencies(rowData.row);
          }}
        />
      </div>
    </div>
  );

  //törlést kitalálni
  //update
  //teszt adatok bemutatóra
  //tesztelés
};

export default DependencyList;
