import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'vehicleResourcePlanner';

const vehicleResourcePlannerService = {
  getEmployees: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/employees`,
    });
  },

  getVehicles: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/vehicles`,
      data,
    });
  },

  updateVehicle: (data, id) => {
    return request({
      method: Methods.PATCH,
      resource: `${baseURL}/vehicle/${id}`,
      data,
    });
  },
};
export default vehicleResourcePlannerService;
