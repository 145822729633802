import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DownloadIcon from '@mui/icons-material/Download';
import TableRowsIcon from '@mui/icons-material/TableRows';

import FileUploadDialogUI from './FileUploadDialogUI';

const buttonStyle = {
  height: 80,
  width: 150,
  backgroundColor: '#cedfe8',
  color: '#667280',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#a3b9c3',
  },
};

const ExcelImportTab = ({ handleTemplate, handleExport, handleImport }) => {
  const [uploadDialog, setUploadDialog] = useState(false);

  const handleClick = () => {
    setUploadDialog(true);
  };
  const handleClose = () => {
    setUploadDialog(false);
  };

  return (
    <>
      <Stack spacing={2} alignItems="center" mt={4} sx={{ width: 'auto' }}>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" color="primary" onClick={handleTemplate} sx={buttonStyle}>
            <TableRowsIcon />
            Template
          </Button>
          <Button variant="contained" color="primary" onClick={handleExport} sx={buttonStyle}>
            <DownloadIcon />
            Exportálás
          </Button>
        </Stack>
        <Button variant="contained" color="primary" onClick={handleClick} sx={buttonStyle}>
          <FileUploadIcon />
          Import
        </Button>
      </Stack>
      <FileUploadDialogUI
        open={uploadDialog}
        handleClose={handleClose}
        handleUpload={(files) => {
          handleImport(files);
          handleClose();
        }}
        accept={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] }}
      />
    </>
  );
};

export default ExcelImportTab;
