import React from 'react';
import { Field } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, DatePickerUI, AutoCompleteSelectUI } from '../Interface';

const FormFields = ({ viewField, values, errors, selectedValues, submenuId, hasAccess }) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
      <div className="col-span-4 ">
        <Field
          type="text"
          name="title"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Innovációs javaslat címe" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.title} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.title !== '' }}
        />
      </div>
      <div className="col-span-4">
        <Field
          type="text"
          name="detailedDescription"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Részletes leírás" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.detailedDescription} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.detailedDescription !== '' }}
          multiline
          rows={4}
        />
      </div>
      <div className="col-span-4">
        <Field
          type="text"
          name="investmentCalculation"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Megtérülés számítás" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.investmentCalculation} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.investmentCalculation !== '' }}
          multiline
          rows={4}
        />
      </div>
      <div className="col-span-4 md:col-span-2 ">
        <Field
          type="autocomplete"
          name="statusId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Státusz" />}
          variant="standard"
          disabled={!hasAccess}
          InputLabelProps={{ shrink: values.statusId !== '' }}
          selectedValue={values.statusId}
          selectedLabelValue={selectedValues?.status?.itemName}
          table={['statusitems', 'status']}
          listType={{ id: 'itemId', name: 'itemName' }}
          joins={'statusitems.statusId = status.statusId'}
          conditions={[`statusitems.archive = 'N'`, `status.subMenuId = ${submenuId}`]}
        />
      </div>
      <div className="col-span-4 md:col-span-2 ">
        <Field
          type="date"
          component={DatePickerUI}
          value={values.dateSubmission}
          name="dateSubmission"
          fullWidth
          label={<FormLabelUI text="Beküldés időpontja" />}
          variant="standard"
          disabled
          InputLabelProps={{ shrink: values.dateSubmission !== '' }}
        />
      </div>
    </div>
  );
};

export default FormFields;
