import React from 'react';

import { GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { GridRowModes } from '@mui/x-data-grid';

const ActionButtonUI = ({
  id,
  rowModesModel,
  handleSaveClick,
  handleCancelClick,
  handleEditClick,
  handleDeleteClick,
  disabled,
}) => {
  const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
  const isAnyRowInEditMode = Object.values(rowModesModel).some((row) => row.mode === GridRowModes.Edit);

  if (isInEditMode) {
    return (
      <>
        <GridActionsCellItem
          icon={<SaveIcon />}
          label="Save"
          sx={{ color: 'primary.main' }}
          onClick={handleSaveClick(id)}
        />
        <GridActionsCellItem
          icon={<CancelIcon />}
          label="Cancel"
          className="textPrimary"
          onClick={handleCancelClick(id)}
          color="inherit"
        />
      </>
    );
  }

  return (
    <>
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={handleEditClick(id)}
        disabled={isAnyRowInEditMode || disabled}
        color="inherit"
      />
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Delete"
        onClick={handleDeleteClick(id)}
        disabled={disabled}
        color="inherit"
      />
    </>
  );
};

export default ActionButtonUI;
