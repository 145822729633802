import React, { useRef, useState } from 'react';
import { MenuItem, Select, FormControl, IconButton, FormHelperText, InputLabel } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const SelectUI = (props) => {
  const { option, label, helperText, onClear, value } = props;
  const selectRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleClearClick = (e) => {
    e.stopPropagation();
    onClear();
    // open after clear
    //setOpen(true);
  };

  return (
    <FormControl variant="standard" sx={{ width: '100%' }}>
      {label ? <InputLabel>{label}</InputLabel> : null}
      <Select
        ref={selectRef}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        MenuProps={{
          style: {
            zIndex: 9999,
          },
        }}
        IconComponent={
          value && onClear
            ? () => (
                <IconButton size="small" onClick={handleClearClick}>
                  <ClearIcon sx={{ height: '20px' }} />
                </IconButton>
              )
            : undefined
        }
        {...props}
      >
        {option &&
          option.map((item, index) => (
            <MenuItem key={index} value={item.value ?? item}>
              {item.label ?? item.value ?? item}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SelectUI;
