export const ResourceColumnDynamicColsObj = [
  {
    name: 'MB',
    dynPropertyName: 'Területi képviselő',
  },
  {
    name: 'K',
    dynPropertyName: 'Kereskedő',
  },
  {
    name: 'GK',
    dynPropertyName: 'Autó',
  },
  {
    name: 'Okt. dátuma',
    dynPropertyName: 'Oktatás dátuma',
  },
  {
    name: 'Rend. szám',
    dynPropertyName: 'Rendelési szám',
  },
  {
    name: 'Újévi kezdés',
    dynPropertyName: 'Újévi kezdés dátum',
  },
];
