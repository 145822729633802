/**
 * Generates an array of years from a given minimum year up to the current year.
 *
 * @param {number} minYear - The minimum year to start from.
 * @returns {number[]} An array of years from minYear to the current year.
 */

export const generateYears = (minYear) => {
  const currentYear = new Date().getFullYear();
  let years = [];
  for (let year = minYear; year <= currentYear; year++) {
    years.push(year);
  }
  return years;
};
