import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import huLocale from '@fullcalendar/core/locales/hu';
import dayjs from 'dayjs';
import calendarService from '../../service/calendar.service';
import CalendarFilter from './CalendarFilter';
import { generateFilter } from '../../utils/helper';
import useUsers from '../../context/UsersContext';
import CalendarDialog from './CalendarDialog';
import crmTaskService from '../../service/crmTask.service';

export const CalendarPage = () => {
  const { user, getUser } = useUsers();
  const calendarRef = useRef(null);
  const [events, setEvents] = useState({ days: [] });
  const [dateRange, setDateRange] = useState({});
  const [filterVal, setFilterVal] = useState({
    createdBy: [],
  });
  const [query, setQuery] = useState({
    filter: [],
  });
  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [calendarDialogOpen, setCalendarDialogOpen] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const [singleCalendarDay, setSingleCalendarDay] = useState({});

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (dataChanged) {
      getAllCalendarDays();
      setDataChanged(false);
    }
  }, [dataChanged]);

  useEffect(() => {
    if (date.startDate !== null && date.endDate !== null && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();

      if (calendarApi.view.type !== 'timeGridDay') {
        calendarApi.changeView('timeGridDay', date.startDate.toDate());
      } else {
        calendarApi.gotoDate(date.startDate.toDate());
      }
    }
  }, [date.startDate, date.endDate]);

  useEffect(() => {
    if (date.startDate !== null && date.endDate !== null) {
      setDateRange(date);
    }
  }, [date.startDate, date.endDate]);

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate && query.filter.length > 0 && user.userId) {
      getAllCalendarDays();
    }
  }, [dateRange.startDate, dateRange.endDate, query, user.userId]);

  const conditions = {};

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'deletedBy',
        value: null,
      },
    ];
    const newStatus = filterVal.createdBy.map((personIds) => personIds.value);
    const newFilterVal = { ...filterVal, createdBy: newStatus };
    const newFilter = generateFilter(newFilterVal, conditions);

    if (JSON.stringify(query.filter) !== JSON.stringify([...defaultFilter, ...newFilter])) {
      setQuery((prev) => ({
        ...prev,
        filter: [...defaultFilter, ...newFilter],
      }));
    }
  }, [filterVal]);

  const calendarViews = {
    dayGridMonth: {
      dayHeaderFormat: { weekday: 'long' },
    },
    timeGridWeek: {
      dayHeaderFormat: { month: 'long', day: 'numeric', weekday: 'long' },
      slotLabelFormat: [{ hour: 'numeric', minute: '2-digit' }],
    },
    timeGridDay: {
      slotLabelFormat: [{ hour: 'numeric', minute: '2-digit' }],
    },
  };

  const getTaskById = (id) => {
    crmTaskService.getTaskById(id).then((data) => {
      setSingleCalendarDay(data);
      setCalendarDialogOpen(true);
    });
  };

  const getAllCalendarDays = () => {
    const reqData = {
      query,
      dateRange,
      createdByUserId: user.userId,
    };
    calendarService.getCalendarDays(reqData).then((data) => {
      setEvents((prev) => ({
        ...prev,
        days: data,
      }));
    });
  };

  const handleDatesSet = (dateInfo) => {
    const startDate = dayjs(dateInfo.start).startOf('day');
    const endDate = dayjs(dateInfo.end).endOf('day');

    if (dateRange.startDate?.isSame(startDate, 'day') && dateRange.endDate?.isSame(endDate, 'day')) {
      return;
    }
    setDateRange({ startDate, endDate });
  };

  return (
    <div className="calendarView flex bg-[#f3f4f6]">
      <div className="w-[350px]">
        <CalendarFilter filter={{ filterVal, setFilterVal }} dates={{ date, setDate }} />
      </div>
      <div className="w-full bg-white">
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: 'prev,next,today',
            center: 'title',
            right: 'timeGridDay,timeGridWeek,dayGridMonth',
          }}
          locales={[huLocale]}
          views={calendarViews}
          events={[...events.days]}
          datesSet={handleDatesSet}
          nowIndicator={true}
          eventClick={(clickInfo) => {
            if (clickInfo.event.display !== 'background') {
              const id = clickInfo.event.id;

              getTaskById(id);
            }
          }}
        />
      </div>
      <CalendarDialog
        changed={{ dataChanged, setDataChanged }}
        open={calendarDialogOpen}
        handleClose={() => {
          setCalendarDialogOpen(false);
        }}
        data={singleCalendarDay}
      />
    </div>
  );
};
