import { TextField } from '@mui/material';

const InputUI = (props) => {
  return (
    <TextField
      sx={{ width: '100%' }}
      autoComplete="off"
      InputLabelProps={{
        style: { color: '#607d8b' },
      }}
      inputProps={{ step: props.precision }}
      {...props}
    />
  );
};

export default InputUI;
