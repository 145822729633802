import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'company';

const companyService = {
  getCompanies: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/companies`,
      data,
    });
  },

  createCompany: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateCompany: (data, companyId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${companyId}`,
      data,
    });
  },

  deleteCompany: (data, companyId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${companyId}`,
      data,
    });
  },

  addContactToList: (data, companyId) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/companyContact/${companyId}`,
      data,
    });
  },

  deleteContactFromList: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/companyContact/${id}`,
      data,
    });
  },

  updateContact: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/companyContact/${id}`,
      data,
    });
  },
};

export default companyService;
