import { Chip, IconButton } from '@material-tailwind/react';
import CircularProgress from '@mui/material/CircularProgress';
import { KanbanCard } from '../../config/kanban';
import { ColorPicker } from '../../config/ColorPicker';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import kanbanService from '../../service/kanban.service';

const Field = ({ label, value, chipColor, isChip, dateType, funnelView }) => {
  let formattedValue = value;
  if (dateType) {
    switch (dateType) {
      case 'date':
        formattedValue = dayjs(value).format('YYYY-MM-DD');
        break;
      case 'datetime':
        formattedValue = dayjs(value).format('YYYY-MM-DD HH:mm:ss');
        break;
      case 'time':
        formattedValue = dayjs(value).format('HH:mm:ss');
        break;
      default:
        formattedValue = value;
    }
  }
  if (isChip) {
    return (
      <div className={`flex items-center gap-2 ${funnelView && 'truncate'}`}>
        {label}
        <Chip
          value={formattedValue}
          className="rounded-full truncate block"
          variant="ghost"
          style={{ backgroundColor: chipColor }}
        />
      </div>
    );
  } else {
    return (
      <div className={`mb-1 ${funnelView && 'truncate'}`}>
        {label} {formattedValue}
      </div>
    );
  }
};

const Card = ({
  data,
  onDragStart,
  getItemById,
  statusColor,
  loading,
  type,
  params,
  funnelView,
  user,
  setRefetch,
  showMessageDialogParam,
}) => {
  const [cardParams, setCardParams] = useState(null);

  useEffect(() => {
    const kanbanCardConfig = KanbanCard.find((card) => card.type === type);
    const paramName = kanbanCardConfig?.paramName;

    if (params[paramName]) {
      const parsedParams = JSON.parse(params[paramName]);
      setCardParams(parsedParams);
    }
  }, [params, type]);

  const getFieldValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => {
      if (Array.isArray(acc)) {
        return acc.map((item) => item[part]);
      } else {
        return acc ? acc[part] : null;
      }
    }, obj);
  };

  const getChipColor = (fieldColor) => {
    if (!fieldColor) return null;
    const color = getFieldValue(data, fieldColor);
    const colorObj = ColorPicker.find((col) => col.colorName === color);
    return colorObj ? colorObj.colorCode : color;
  };

  const iAmTheAddress =
    showMessageDialogParam.find((pr) => pr.kanbanType.length > 0) &&
    data.messages.length > 0 &&
    data.messages.some((message) => {
      return message.addressedTo === user?.userId && message.readAt === null;
    });

  const cannotReadAt = data.messages.find((message) => message.addressedTo === user?.userId);

  const handleUpdateReadAt = () => {
    const findedItem = data.messages.find((msg) => msg.lineId === data.id);
    if (iAmTheAddress && cannotReadAt.readAt === null) {
      kanbanService.updateReadAt(findedItem.id, type).then(() => {
        setRefetch(true);
      });
    }
  };

  const handleShowMore = () => {
    handleUpdateReadAt();
    getItemById(data.id);
  };

  return (
    <div
      key={data.id}
      draggable
      onDragStart={(event) => onDragStart(event, data)}
      className={`px-2 ${!funnelView && iAmTheAddress ? 'bg-red-100' : 'bg-white'} ${funnelView ? 'w-[200px] h-[35px] font-bold shadow-xl overflow-hidden py-1.5' : 'mb-3 py-2'} ${loading ? 'relative opacity-40 cursor-not-allowed' : 'cursor-pointer rounded hover:bg-kanbanHoverBackground'}`}
      style={
        funnelView
          ? { borderRadius: '26px', background: statusColor?.kanbanChipBgColor, color: statusColor.textColor }
          : { borderLeft: `3px solid ${statusColor?.colorCode}` }
      }
    >
      {loading && (
        <div className="h-full w-full rounded z-50 left-0 absolute top-0 text-white flex items-center justify-center">
          <CircularProgress size={50} />
        </div>
      )}
      <div className={`grid grid-cols-4 ${!funnelView && 'text-xl'}`}>
        {cardParams && cardParams.length > 0 && (
          <div className="col-span-3 mb-2">
            <Field
              label={cardParams[0].label}
              value={getFieldValue(data, cardParams[0].fieldName) || 'Nincs megadva.'}
              chipColor={getChipColor(cardParams[0].color)}
              isChip={cardParams[0].view === 'chip'}
              dateType={cardParams[0].dateType}
              funnelView={funnelView}
            />
          </div>
        )}
        <div className="col-span-1 justify-self-end -mt-0.5">
          <IconButton
            onClick={handleShowMore}
            variant="gradient"
            color="blue-gray"
            className={`${funnelView ? 'h-[28px] w-[28px]' : ''}`}
            size="sm"
          >
            <svg
              className={`${funnelView ? 'h-[18px] w-[18px]' : 'h-[22px] w-[22px]'}`}
              viewBox="0 0 256 256"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M251 123.13C250.63 122.32 241.87 102.87 222.52 83.52C196.63 57.67 164 44 128 44C92 44 59.37 57.67 33.51 83.52C14.16 102.87 5.40002 122.32 5.00002 123.13C4.32255 124.666 3.97266 126.326 3.97266 128.005C3.97266 129.684 4.32255 131.344 5.00002 132.88C5.37002 133.7 14.13 153.14 33.49 172.49C59.37 198.34 92 212 128 212C164 212 196.63 198.34 222.48 172.49C241.84 153.14 250.6 133.7 250.97 132.88C251.652 131.346 252.007 129.687 252.012 128.008C252.018 126.329 251.673 124.668 251 123.13ZM204.94 156.13C183.47 177.27 157.59 188 128 188C98.41 188 72.53 177.27 51.09 156.12C42.6535 147.772 35.3964 138.312 29.52 128C35.3981 117.693 42.655 108.235 51.09 99.89C72.54 78.73 98.41 68 128 68C157.59 68 183.46 78.73 204.91 99.89C213.346 108.235 220.603 117.692 226.48 128C220.603 138.311 213.346 147.772 204.91 156.12L204.94 156.13ZM128 84C119.298 84 110.791 86.5806 103.555 91.4153C96.3192 96.2501 90.6796 103.122 87.3493 111.162C84.0191 119.202 83.1477 128.049 84.8455 136.584C86.5432 145.119 90.7338 152.959 96.8873 159.113C103.041 165.266 110.881 169.457 119.416 171.155C127.951 172.852 136.798 171.981 144.838 168.651C152.878 165.32 159.75 159.681 164.585 152.445C169.419 145.209 172 136.702 172 128C171.987 116.335 167.347 105.151 159.098 96.9019C150.849 88.6532 139.665 84.0132 128 84ZM128 148C124.044 148 120.178 146.827 116.889 144.629C113.6 142.432 111.036 139.308 109.522 135.654C108.009 131.999 107.613 127.978 108.384 124.098C109.156 120.219 111.061 116.655 113.858 113.858C116.655 111.061 120.219 109.156 124.098 108.384C127.978 107.613 131.999 108.009 135.654 109.522C139.308 111.036 142.432 113.6 144.629 116.889C146.827 120.178 148 124.044 148 128C148 133.304 145.893 138.391 142.142 142.142C138.391 145.893 133.304 148 128 148Z"
                fill="white"
              />
            </svg>
          </IconButton>
        </div>
      </div>

      <div>
        {cardParams &&
          cardParams
            .slice(1)
            .map((item, index) => (
              <Field
                key={index}
                label={item.label}
                value={getFieldValue(data, item.fieldName) || 'Nincs megadva.'}
                chipColor={getChipColor(item.color)}
                isChip={item.view === 'chip'}
                dateType={item.dateType}
                funnelView={funnelView}
              />
            ))}
      </div>
    </div>
  );
};

export default Card;
