import React from 'react';
import { FormGroup, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { useNavigate } from 'react-router-dom';

import usePages from '../../context/PageContext';
import { generateViewType } from '../../utils/GanttHelper';
import { GanttType } from '../../config/gantt';
import { AutoCompleteSelectUI, ButtonUI, CheckboxUI, DebouncedInputUI, SelectUI } from '../Interface';

const GanttFilter = ({
  type,
  setType,
  params,
  submenuNames,
  saveView,
  setSimpleTaskDialogOpen,
  toggleResourceGroup,
  setStatusDialogOpen,
  showWorHourState,
  disappearedWorkHour,
  filter,
  setDynamicColDialogOpen,
  canEdit,
  activeTimeState,
  showActiveTimeCheckbox,
  setActiveTimeDialogOpen,
}) => {
  const { showWorkhour, setShowWorkHour } = showWorHourState;
  const { filterVal, setFilterVal } = filter;
  const { activeTime, setActiveTime } = activeTimeState;
  const navigate = useNavigate();
  const { setPages } = usePages();
  const projectTabFields = params.PROJECT_TAB_FIELDS ? JSON.parse(params.PROJECT_TAB_FIELDS) : [];

  if (!Array.isArray(projectTabFields)) {
    return null;
  }
  const resourceParams = projectTabFields.reduce((acc, obj) => {
    Object.keys(obj).forEach((key) => {
      if (key.startsWith('RESOURCE_') && obj[key] === 'Y') {
        acc[key] = obj[key];
      }
    });
    return acc;
  }, {});

  const ViewType = generateViewType(submenuNames, GanttType);
  const filteredViewOption = ViewType.filter((view) => resourceParams[view.value] === 'Y');

  const navigateToEmployeeResourcePlannerPage = () => {
    setPages({ subMenuName: submenuNames[5] });
    navigate('/app/employeeResourcePlanner');
  };

  return (
    <FormGroup>
      <div className="flex items-center gap-2">
        <div className="w-[200px]">
          <SelectUI
            label={<span className="text-white text-lg">Erőforrás választó</span>}
            option={filteredViewOption}
            value={type}
            inputProps={{
              sx: {
                color: 'white',
              },
            }}
            onChange={(e) => {
              const value = e.target.value;
              setType(value);
              saveView('ganttFilter', { selectedResource: value });
            }}
          />
        </div>
        <div className="w-[200px]">
          <AutoCompleteSelectUI
            label={<span className="text-white text-lg">Dolgozó választó</span>}
            sx={{
              input: {
                color: 'white',
              },
            }}
            selectedValue={filterVal.resourceId}
            onChange={(_e, newVal, reason) => {
              if (reason === 'clear') {
                setFilterVal((prevState) => ({
                  ...prevState,
                  resourceId: '',
                }));
              } else {
                setFilterVal((prevState) => ({
                  ...prevState,
                  resourceId: newVal.value,
                }));
              }
            }}
            disabled={type !== GanttType.RESOURCE_ONE}
            table={['resourceOne']}
            listType={{ id: 'id', name: 'name' }}
            joins={'resourceOne.id = projectResourceOne.resourceId'}
            conditions={`resourceOne.id IN (SELECT DISTINCT projectResourceOne.resourceId FROM projectResourceOne WHERE projectResourceOne.archive = 'N')`}
          />
        </div>
        <div className="w-[200px]">
          <DebouncedInputUI
            label={<span className="text-white text-lg">Projekt neve</span>}
            sx={{
              '& .MuiInputBase-input': {
                color: '#fff',
              },
            }}
            debounceMs={800}
            setQuickFilterSearchValue={(newValue) => {
              setFilterVal((prevState) => ({
                ...prevState,
                projectName: newValue,
              }));
            }}
            quickFilterSearchValue={filterVal.projectName}
          />
        </div>
        <IconButton onClick={toggleResourceGroup}>
          <div className="bg-white text-black text-sm font-medium py-1.5 px-1 rounded hover:bg-ganttHoverButtonColor hover:text-white">
            <NorthIcon className="text-xl" />
            <SouthIcon className="text-xl" />
          </div>
        </IconButton>
        <div>
          <IconButton size="small" onClick={() => setSimpleTaskDialogOpen(true)} disabled={!canEdit}>
            <div className="-ml-4 bg-white text-black text-sm font-medium  py-1.5 px-2 rounded flex items-center justify-center gap-1 hover:bg-ganttHoverButtonColor hover:text-white">
              <AddIcon className="text-xl" />
              <span className="text-base">Feladat</span>
            </div>
          </IconButton>
        </div>
        <div className="gap-1 flex items-center">
          <ButtonUI
            text="Státuszok"
            className="bg-white text-black px-2 py-2.5 -ml-2 hover:bg-ganttHoverButtonColor hover:text-white"
            onClick={() => setStatusDialogOpen(true)}
          />
          <ButtonUI
            text="Jelzők"
            className="bg-white text-black py-2.5 px-2 hover:bg-ganttHoverButtonColor hover:text-white"
            onClick={() => setDynamicColDialogOpen(true)}
          />
          <ButtonUI
            text={submenuNames[5]}
            className="bg-white text-black px-2 py-2.5 hover:bg-ganttHoverButtonColor hover:text-white"
            onClick={navigateToEmployeeResourcePlannerPage}
          />
        </div>
        {!disappearedWorkHour && (
          <CheckboxUI label="Tényidő" checked={showWorkhour} onChange={(e) => setShowWorkHour(e)} />
        )}
        {showActiveTimeCheckbox && (
          <CheckboxUI
            label="Aktív idő"
            checked={activeTime}
            onChange={(e) => {
              setActiveTime(e.target.checked);
              saveView('ganttFilter', { activeTime: e.target.checked });
            }}
          />
        )}
        {!showActiveTimeCheckbox && (
          <ButtonUI
            text="Aktív idő"
            className="bg-white text-black px-2 py-2.5 hover:bg-ganttHoverButtonColor hover:text-white"
            onClick={() => setActiveTimeDialogOpen(true)}
          />
        )}
      </div>
    </FormGroup>
  );
};

export default GanttFilter;
