import React from 'react';

export const ProductionInfoPanel = ({ productionList }) => {
  return (
    <div className="mt-5">
      <h2 className="text-2xl text-labelColor mb-2">Művelet infó panel</h2>
      <div className="gap-4 py-4 bg-gray-100 rounded-lg flex justify-around shadow-md">
        <div className=" text-labelColor">
          <p className="font-semibold">Késztermék neve</p>
          <p>{productionList.articleName}</p>
        </div>
        <div className="text-labelColor">
          <p className="font-semibold">Késztermék cikkszáma</p>
          <p>{productionList.articleNumber}</p>
        </div>
        <div className="text-labelColor">
          <p className="font-semibold">Gyártandó mennyiség</p>
          <p>
            {productionList.productionQuantity} {productionList.quantityUnit}
          </p>
        </div>
        <div className="text-labelColor">
          <p className="font-semibold">Vevő neve</p>
          <p>{productionList.company}</p>
        </div>
        <div className="text-labelColor">
          <p className="font-semibold">Művelet azonosító</p>
          <p>{productionList.operationIdlabel}</p>
        </div>
        <div className="text-labelColor">
          <p className="font-semibold">Művelet neve</p>
          <p>{productionList.operationName}</p>
        </div>
        <div className="text-labelColor">
          <p className="font-semibold">Tervezett mennyiség</p>
          <p>
            {productionList.productionQuantity} {productionList.quantityUnit}
          </p>
        </div>
        <div className="text-labelColor">
          <p className="font-semibold">Tény mennyiség</p>
          <p>
            {productionList.total?.quantity} {productionList.quantityUnit}
          </p>
        </div>
        <div className="text-labelColor">
          <p className="font-semibold">Tervezett idő</p>
          <p>{productionList && productionList.timeSpent ? productionList.timeSpent + ' óra' : ''}</p>
        </div>
        <div className="text-labelColor">
          <p className="font-semibold"> Tény idő</p>
          <p>{productionList && productionList.total?.timeSpent ? productionList.total?.timeSpent + ' óra' : ''}</p>
        </div>
      </div>
    </div>
  );
};
