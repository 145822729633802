import { useState, useEffect } from 'react';

import dayjs from 'dayjs';

import { dataGridColumns, generateFilter } from '../../utils/helper';
import attendenceService from '../../service/attendence.service';
import {
  AutoCompleteSelectUI,
  DataGridUI,
  DataGridIdentifiers,
  DatePickerUI,
  FormLabelUI,
  CheckboxUI,
} from '../Interface';

const AttendanceReports = ({
  refetch,
  values,
  viewField,
  operatorsDisabled,
  submenuId,
  selectedRows,
  defaultValues,
  editButtonVisible,
}) => {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { selectedRow, setSelectedRow } = selectedRows;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [{ field: 'date', sort: 'desc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  const [filterFormState, setFilterFormState] = useState({
    projectId: '',
    userId: '',
    date: null,
    statusId: '',
  });

  const conditions = {};

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'archive',
        value: 'N',
      },
    ];

    const newFilter = generateFilter(filterFormState, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
  }, [filterFormState]);

  useEffect(() => {
    if (operatorsDisabled && selectedRow.id.length > 0) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getList();
    }
  }, [query]);

  useEffect(() => {
    if (refetchTable && query.filter?.length > 0) {
      getList();
    }
  }, [refetchTable]);

  const onPaginitonChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  const getList = () => {
    attendenceService.getAttendence(query).then((data) => {
      setReports(data);
      setRefetchTable(false);
    });
  };

  const columns = dataGridColumns([
    {
      field: 'batchStatus',
      headerName: 'S',
      flex: 0,
      renderCell: (params) => {
        const isChecked = selectedRow.id?.includes(params.id);
        return (
          <CheckboxUI
            checked={isChecked}
            disabled={!editButtonVisible}
            onChange={() => {
              setRowSelectionModel([]);
              setSelectedValues(defaultValues);
              setSelectedRow((prev) => {
                const isSelected = prev.id?.includes(params.id);

                return {
                  ...prev,
                  id: isSelected ? prev.id.filter((itemId) => itemId !== params.id) : [...(prev.id || []), params.id],
                };
              });
            }}
          />
        );
      },
      sortable: false,
    },
    {
      field: 'date',
      headerName: 'Dátum',
      flex: 1,
      renderCell: (params) => {
        return dayjs(params.row.date).format('YYYY.MM.DD.');
      },
    },
    {
      field: 'projectId',
      headerName: 'Projekt',
      flex: 1,
      renderCell: (params) => {
        return params.row.project?.projectName;
      },
      sortable: false,
    },
    {
      field: 'statusId',
      headerName: 'Státusz',
      flex: 1,
      renderCell: (params) => {
        return params.row.status?.itemName;
      },
      sortable: false,
    },
  ]);

  const handleCellClick = (param, event) => {
    if (param.field === 'batchStatus') {
      event.stopPropagation();
    }
  };

  return (
    <div className="flex  overflow-hidden m-4 ">
      <div className="h-full w-full  overflow-x-auto ">
        <div className="grid grid-cols-4 gap-x-4 mx-4">
          <div className="col-span-2 md:col-span-1">
            <DatePickerUI
              label={<FormLabelUI text="Dátum" />}
              value={filterFormState.date}
              onChange={(newDate) => {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  date: newDate ? dayjs(newDate).startOf('hour').add(newDate.utcOffset(), 'minute') : null,
                }));
              }}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <AutoCompleteSelectUI
              label={<FormLabelUI text={'Projekt'} />}
              selectedValue={filterFormState.projectId}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    projectId: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    projectId: newVal.value,
                  }));
                }
              }}
              table={'project'}
              listType={{ id: 'projectId', name: 'projectName' }}
              conditions={[`project.archive = 'N'`]}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <AutoCompleteSelectUI
              id="status"
              label={<FormLabelUI text="Státusz" />}
              selectedValue={filterFormState.statusId}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    statusId: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    statusId: newVal.value,
                  }));
                }
              }}
              table={['statusitems', 'status']}
              listType={{ id: 'itemId', name: 'itemName' }}
              joins={'statusitems.statusId = status.statusId'}
              conditions={[`statusitems.archive = 'N'`, `status.subMenuId = ${submenuId}`]}
            />
          </div>
        </div>

        <div className="inline-block w-full border-b border-gray-200 align-middle mt-4 overflow-y-auto overflow-x-auto ">
          <DataGridUI
            datagridIdentifier={DataGridIdentifiers.attendance}
            initialState={query}
            rows={reports.rows}
            rowCount={reports.rowCount}
            columns={columns}
            onRowClick={(rowData) => {
              if (!viewField || selectedRow.id.length > 0) return;
              setSelectedValues(rowData.row);
            }}
            paginationMode="server"
            onPaginationModelChange={onPaginitonChange}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            disableRowSelectionOnClick={!viewField || selectedRow.id.length > 0}
            height={750}
            onCellClick={handleCellClick}
          />
        </div>
      </div>
    </div>
  );
};

export default AttendanceReports;
