import { useState } from 'react';
import DragAndDrop from '../ui/DragAndDrop';
import { TabsUI } from '../Interface';
import FormTable from './FormTable';

const FormTabs = (props) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      id: 0,
      name: 'Kommunikáció',
      content: props.addField && (
        <FormTable
          id={props.id}
          communicationList={props.communicationList}
          setCommunicationList={props.setCommunicationList}
          setRefetchTable={props.setRefetchTable}
          user={props.user}
        />
      ),
    },
    {
      id: 1,
      name: 'Dokumentumok',
      content: props.addField && (
        <DragAndDrop
          SimpleTaskId={props.SimpleTaskId}
          disabled={props.disabled}
          fileList={props.fileList}
          setFileList={props.setFileList}
          newFiles={props.newFiles}
          setNewFiles={props.setNewFiles}
        />
      ),
    },
  ];

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="my-4 mt-6">
      <div className="px-3">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {tabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </div>
    </div>
  );
};

export default FormTabs;
