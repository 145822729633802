import request from '../../utils/Request';
import { Methods } from '../../config';

const baseURL = 'resourceTwo';

const resourceTwoService = {
  getResources: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/resources`,
      data,
    });
  },

  createResource: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateResource: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  deleteResource: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  orderResource: (id, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/orderResource/${id}`,
      data,
    });
  },

  addResourceToList: (data, id) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/projectResource/${id}`,
      data,
    });
  },

  deleteResourceFromList: (data, listId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/projectResource/${listId}`,
      data,
    });
  },

  updateProjectResource: (data, listId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/projectResource/${listId}`,
      data,
    });
  },
};

export default resourceTwoService;
