import dayjs from 'dayjs';
import { PropertyType } from '../config/property';
import { getDynVals } from './DynHelper';

export const objectToArray = (obj) => {
  return Object.entries(obj).map(([key, value]) => ({ field: key, value }));
};

export const generateFilter = (formValues, conditions) => {
  const { like, gte, lte } = conditions;

  const values = objectToArray(formValues);
  const retVal = values.filter((value) => {
    if (value.value !== '' && value.value !== null) {
      if (like?.includes(value.field)) {
        value.like = true;
      } else if (gte?.includes(value.field)) {
        value.gte = true;
      } else if (lte?.includes(value.field)) {
        value.lte = true;
      }
      return value;
    }
    return false;
  });

  return retVal;
};

export const dataGridColumns = (gridCols) => {
  return gridCols.map((col) => {
    return {
      ...col,
      filterable: false,
      headerClassName: 'bg-tableHeaderColor',
    };
  });
};

export const dataGridPremiumColumns = (gridCols) => {
  return gridCols.map((col) => {
    return {
      ...col,
      flex: 1,
      groupable: false,
      aggregable: false,
      headerClassName: 'bg-tableHeaderColor',
    };
  });
};

export const generateColumnsFromDynProps = (dynProps) => {
  return dynProps.map((d) => {
    const column = {
      field: `dynamicProperty.dyn_${d.propertyId}`,
      headerName: d.property.name,
      flex: 1,
      groupable: false,
      aggregable: false,
      filterable: false,
      headerClassName: 'bg-tableHeaderColor',
    };

    switch (d.property.type) {
      case PropertyType.DATE:
        column.type = 'date';
        column.valueGetter = (_data, row) => {
          const findedDynVal = row.dynamicProperties.find((f) => f.propertyId === d.propertyId);
          return new Date(findedDynVal?.dateVal);
        };
        column.renderCell = (data) => {
          const findedDynVal = data.row.dynamicProperties.find((f) => f.propertyId === d.propertyId);
          return findedDynVal?.dateVal ? dayjs(findedDynVal?.dateVal).format('YYYY.MM.DD') : '';
        };

        break;
      case PropertyType.DECIMAL:
        column.type = 'number';
        column.valueGetter = (_data, row) => {
          const findedDynVal = row.dynamicProperties.find((f) => f.propertyId === d.propertyId);
          return findedDynVal?.numberVal ? parseFloat(findedDynVal.numberVal) : '';
        };
        break;
      case PropertyType.NUMBER:
        column.type = 'number';
        column.valueGetter = (_data, row) => {
          const findedDynVal = row.dynamicProperties.find((f) => f.propertyId === d.propertyId);
          return findedDynVal?.numberVal ? parseFloat(findedDynVal.numberVal) : '';
        };
        break;
      case PropertyType.LONGTEXT:
        column.valueGetter = (_data, row) => {
          const findedDynVal = row.dynamicProperties.find((f) => f.propertyId === d.propertyId);
          return findedDynVal?.longTextVal;
        };
        break;
      case PropertyType.COMBO:
        column.type = 'singleSelect';
        column.valueGetter = (_data, row) => {
          const findedDynVal = row.dynamicProperties.find((f) => f.propertyId === d.propertyId);
          const findedOption = findedDynVal?.property?.propertyOption.find((po) => po.id === findedDynVal.comboBoxVal);
          return findedOption?.name;
        };
        break;
      case PropertyType.TEXT:
        column.valueGetter = (_data, row) => {
          const findedDynVal = row.dynamicProperties.find((f) => f.propertyId === d.propertyId);
          return findedDynVal?.textVal;
        };
        break;
    }

    return column;
  });
};

export const generateAutocompleteItems = (data = [], labelKey, valueKey) => {
  return data.map((d) => ({
    label: `${d[labelKey]}`,
    value: d[valueKey],
  }));
};
