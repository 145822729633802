import React from 'react';
import { Field } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, AutoCompleteSelectUI } from '../../Interface';

const BiDataSourceFields = ({
  viewField,
  selectedValues,
  values,
  validateField,
  setFieldValue,
  errors,
  dataset,
  isEdit,
}) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
      <div className="col-span-4 ">
        <Field
          type="autocomplete"
          name="element"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Forrás" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.element} />}
          onChange={(_e, newVal) => {
            setFieldValue('element', newVal?.value ?? null).then(() => {
              validateField('element');
            });
          }}
          disabled={viewField || isEdit}
          InputLabelProps={{ shrink: values.element !== '' }}
          selectedValue={values.element}
          selectedLabelValue={selectedValues?.element?.machineName}
          dataset={dataset.biViews}
        />
      </div>
      <div className="col-span-4 ">
        <Field
          type="text"
          name="name"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Alias név" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.name !== '' }}
        />
      </div>
    </div>
  );
};

export default BiDataSourceFields;
