import React, { useState } from 'react';
import { Button, Dialog, DialogHeader, DialogBody, DialogFooter } from '@material-tailwind/react';
import { Details, DependencyList, DependenciesGantt } from './SapProductOrderDialogTabs';
import TabsUI from '../Interface/TabsUI';

const SapProductOrderDialog = ({
  open,
  handleClose,
  task,
  allProductionOrdersWithSapProDependencies,
  getAllProductionOrdersWithSapProDependencies,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const SapProductOrderDialogTabs = [
    {
      name: 'Details',
      content: <Details task={task} />,
    },
    {
      name: 'Dependency List',
      content: (
        <DependencyList
          task={task}
          getAllProductionOrdersWithSapProDependencies={getAllProductionOrdersWithSapProDependencies}
        />
      ),
    },
    {
      name: 'Dependencies Gantt',
      content: (
        <DependenciesGantt
          task={task}
          allProductionOrdersWithSapProDependencies={allProductionOrdersWithSapProDependencies}
        />
      ),
    },
  ];

  return (
    <Dialog open={open} handler={handleClose}>
      <DialogHeader className="bg-labelColor">
        <h2 className="bg-labelColor text-white py-1 px-3 rounded">
          GYU szám - {task.DocNum} Sor száma: {task.LineNum}
        </h2>
      </DialogHeader>
      <DialogBody className="h-96 overflow-y-auto md:h-auto">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {SapProductOrderDialogTabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </DialogBody>
      <DialogFooter>
        <Button variant="gradient" color="blue-gray" onClick={handleClose}>
          <span>Bezárás</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default SapProductOrderDialog;
