import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';
import dayjs from 'dayjs';
import _ from 'lodash';

import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import { useLoader } from '../../provider/LoaderProvider';
import innovationService from '../../service/innovation.service';
import useUsers from '../../context/UsersContext';
import useMenus from '../../context/MenuContext';
import useParam from '../../context/ParamContext';
import masterDataService from '../../service/masterData.service';
import FormTabs from './FormTabs';
import FormFields from './FormFields';
import InnovationReports from './InnovationReports';
import { ButtonUI, DeleteDialogUI, FormButtonPanelUI, FormHeaderUI } from '../Interface';

export const InnovationForm = () => {
  const { getUser, user } = useUsers();
  const { params, getParam } = useParam();
  const { menus, getMenus, getMenuSubMenuId } = useMenus();
  const { showLoader, hideLoader } = useLoader();
  const [initValues, setInitValues] = useState({});
  const [refetchTable, setRefetchTable] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [newFiles, setNewFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [submenu, setSubmenu] = useState(null);
  const [autocompleteData, setAutocompleteData] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [communicationList, setCommunicationList] = useState([]);

  useEffect(() => {
    getUser();
    getMenus();
    getParam();

    const def = {
      title: '',
      detailedDescription: '',
      investmentCalculation: '',
      statusId: null,
      dateSubmission: dayjs(new Date()),
    };

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          statusId: autocompleteData?.status?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          status: autocompleteData?.status?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    setSubmenu(getMenuSubMenuId('innovation'));
  }, [menus]);

  useEffect(() => {
    if (submenu) initDefaultValues();
  }, [submenu]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        setCommunicationList(selectedValues.innovationCommunication ?? []);
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    masterDataService
      .statusToForm(submenu)
      .then((data) => {
        const aData = {
          status: data,
        };
        setAutocompleteData(aData);
      })
      .finally(() => {});
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    detailedDescription: Yup.string().required(),
    investmentCalculation: Yup.string().required(),
  });

  const submitStatus = params.INNOVATION_SUBMIT_STATUS ? parseInt(params.INNOVATION_SUBMIT_STATUS) : null;

  const innovationAccess =
    params.INNOVATION_HAS_ACCESS && params.INNOVATION_HAS_ACCESS.split(',').map((access) => parseInt(access));

  const hasAccess = innovationAccess && innovationAccess.includes(user.userId) ? true : false;

  const inactive = selectedValues.statusId === submitStatus;

  const valuesToForm = () => {
    const { title, detailedDescription, investmentCalculation, dateSubmission, statusId } = selectedValues;

    setInitValues({
      title,
      detailedDescription,
      investmentCalculation,
      dateSubmission: dayjs(dateSubmission),
      statusId,
    });
  };

  const handleInsertObject = (values, resetForm) => {
    showLoader();
    const insertObject = {
      ...values,
      userId: user.userId,
      createdBy: user.userId,
    };

    innovationService
      .createInnovation(insertObject)
      .then(() => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        resetForm();
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values, resetForm);
    } else if (!addField) {
      handleInsertObject(values, resetForm);
    }
  };

  const updateObject = async (values, resetForm) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    innovationService.updateInnovation(updData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setEditButtonvisible(true);
      resetForm();
      setInitValues({});
      setSelectedValues(defaultValues);
      setViewField(true);
    });
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
      deletedAt: dayjs(new Date()),
    };
    innovationService.deleteInnovation(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultValues);
    });
  };

  const handleStatusUpdate = (statusId) => {
    showLoader();
    innovationService
      .updateInnovationStatus({ id: selectedValues.id, statusId })
      .then(() => {
        addNotification({
          content: 'Sikeres módosítás',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        setSelectedValues((prevValues) => ({
          ...prevValues,
          statusId: statusId,
        }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-6 grid-rows-[auto_1fr] gap-y-4 xl:gap-4 bg-gray-100 p-4">
      <div className="col-span-4 md:col-span-3 w-full overflow-x-auto overflow-y-auto">
        <div className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg">
          <div className="flex items-center">
            <FormHeaderUI
              addClick={() => {
                setSelectedValues(defaultSelectedValues);
                setViewField(false);
                setAddField(false);
              }}
              addDisabled={!viewField}
              modClick={() => {
                setViewField(false);
                setEditButtonvisible(false);
              }}
              modDisabled={!viewField || operatorsDisabled || (!hasAccess && inactive)}
              deleteClick={() => {
                setShowDeleteDialog(true);
              }}
              deleteDisabled={!viewField || operatorsDisabled || (!hasAccess && inactive)}
            />
            <ButtonUI
              text="Javaslat beküldése"
              size="sm"
              className="bg-orange-500 py-3"
              onClick={() => {
                handleStatusUpdate(submitStatus);
              }}
              disabled={!viewField || operatorsDisabled || inactive}
            />
          </div>

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ isSubmitting, values, errors, resetForm }) => (
              <Form>
                <FormFields
                  values={values}
                  errors={errors}
                  viewField={viewField}
                  selectedValues={selectedValues}
                  submenuId={submenu}
                  hasAccess={hasAccess}
                />

                <FormTabs
                  values={values}
                  user={user}
                  setRefetchTable={setRefetchTable}
                  addField={addField}
                  // Drag and Drop tartozó formrészek
                  fileList={fileList}
                  communicationList={communicationList}
                  setCommunicationList={setCommunicationList}
                  setFileList={setFileList}
                  newFiles={newFiles}
                  setNewFiles={setNewFiles}
                  id={selectedValues?.id}
                />

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);

                      resetForm();
                    }}
                    cancelDisabled={isSubmitting}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
      <InnovationReports
        refetch={{ refetchTable, setRefetchTable }}
        values={{ selectedValues, setSelectedValues }}
        viewField={viewField}
        operatorsDisabled={operatorsDisabled}
      />
    </div>
  );
};
