import React, { useRef, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { FrappeGantt, Task, ViewMode } from 'frappe-gantt-react';
import { isEmpty } from 'lodash';

const DependenciesGantt = ({ task, allProductionOrdersWithSapProDependencies }) => {
  const collectRelatedRecords = (structure, filterId) => {
    const initialRecord = structure.find((item) => item.id === filterId);
    if (!initialRecord) {
      return [];
    }
    const relatedRecords = new Set();
    const queue = [initialRecord];

    while (queue.length) {
      const currentItem = queue.shift();
      if (!currentItem || relatedRecords.has(currentItem.id)) continue;

      relatedRecords.add(currentItem.id);

      // Add parent records
      structure.forEach((item) => {
        item.dependencies.forEach((dep) => {
          currentItem.dependencies.forEach((d) => {
            if (d.parentId === dep.id) {
              if (!relatedRecords.has(item.id)) {
                queue.push(item);
              }
            }
          });
        });
      });

      // Add child records
      structure.forEach((item) => {
        item.dependencies.forEach((dep) => {
          currentItem.dependencies.forEach((d) => {
            if (dep.parentId === d.id) {
              if (!relatedRecords.has(item.id)) {
                queue.push(item);
              }
            }
          });
        });
      });
    }

    return Array.from(relatedRecords);
  };

  const relatedIds = collectRelatedRecords(allProductionOrdersWithSapProDependencies, task.id);

  const filteredDepStructure = allProductionOrdersWithSapProDependencies.filter((item) => relatedIds.includes(item.id));

  const mapDependencies = (structure) => {
    return structure.map((item) => {
      const dependencies = item.dependencies.filter((dep) => dep.parentId !== null).map((dep) => dep.parentId);

      let taskId;
      if (item.dependencies.length === 0) {
        taskId = item.id;
      } else if (item.dependencies.length === 1) {
        taskId = item.dependencies[0].id;
      } else if (item.dependencies.some((dep) => dep.parentId === null)) {
        taskId = item.dependencies.find((dep) => dep.parentId === null).id;
      } else {
        taskId = item.dependencies[0].id;
      }

      return new Task({
        id: taskId.toString(),
        name: item.title,
        start: item.start,
        end: item.end,
        dependencies: dependencies.join(', '),
      });
    });
  };

  const tasks = mapDependencies(filteredDepStructure);

  // Event handlers for task actions
  const onClick = (task) => {
    console.log('Task clicked:', task);
  };

  const onDateChange = (task, start, end) => {
    console.log('Task date changed:', task, start, end);
  };

  const onProgressChange = (task, progress) => {
    console.log('Task progress changed:', task, progress);
  };

  const onViewChange = (mode) => {
    console.log('View mode changed:', mode);
  };

  // Refs and states for setting the size of the Gantt chart container
  const ganttContainerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    if (ganttContainerRef.current) {
      setContainerHeight(ganttContainerRef.current.clientHeight);
    }
  }, [ganttContainerRef]);

  return (
    <div ref={ganttContainerRef} className="flex-grow h-full">
      {isEmpty(allProductionOrdersWithSapProDependencies) ? (
        <CircularProgress size={70} />
      ) : (
        <FrappeGantt
          tasks={tasks}
          viewMode={ViewMode.Day}
          onClick={onClick}
          onDateChange={onDateChange}
          onProgressChange={onProgressChange}
          onViewChange={onViewChange}
          customPopupHtml={(task) => {
            return `<div class="details-container">
                            <h5>${task.name}</h5>
                            <p>Start: ${task._start.format('MMM DD, YYYY')}</p>
                            <p>End: ${task._end.format('MMM DD, YYYY')}</p>
                        </div>`;
          }}
          height={containerHeight}
        />
      )}
    </div>
  );
};

export default DependenciesGantt;
