import React, { useState } from 'react';
import { Field } from 'formik';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { InputUI, FormLabelUI, FormErrorUI } from '../Interface';

export const UserProfileFields = ({ viewField, values, errors }) => {
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmedNewPassword: false,
  });

  const handleClickShowPassword = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <h2 className="mt-2 text-xl text-labelColor mx-4 mb-5">Jelszó módosítás</h2>
      <div className="grid grid-cols-3 gap-x-4 mx-4 mb-5">
        <div className="col-span-4 md:col-span-1">
          <Field
            type={showPassword.oldPassword ? 'text' : 'password'}
            name="oldPassword"
            as={InputUI}
            fullWidth
            label={<FormLabelUI text="Régi jelszó" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.oldPassword} />}
            InputLabelProps={{ shrink: values.oldPassword !== '' }}
            disabled={viewField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword('oldPassword')}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                    edge="end"
                    disabled={viewField}
                  >
                    {showPassword.oldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="col-span-4 md:col-span-1">
          <Field
            type={showPassword.newPassword ? 'text' : 'password'}
            name="newPassword"
            as={InputUI}
            fullWidth
            label={<FormLabelUI text="Új jelszó" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.newPassword} />}
            disabled={viewField}
            InputLabelProps={{ shrink: values.newPassword !== '' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword('newPassword')}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                    edge="end"
                    disabled={viewField}
                  >
                    {showPassword.newPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="col-span-4 md:col-span-1">
          <Field
            type={showPassword.confirmedNewPassword ? 'text' : 'password'}
            name="confirmedNewPassword"
            as={InputUI}
            fullWidth
            label={<FormLabelUI text="Új jelszó megerősítése" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.confirmedNewPassword} />}
            disabled={viewField}
            InputLabelProps={{ shrink: values.confirmedNewPassword !== '' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword('confirmedNewPassword')}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                    edge="end"
                    disabled={viewField}
                  >
                    {showPassword.confirmedNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </>
  );
};
