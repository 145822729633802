import { DialogUI, ButtonUI } from '.';

export default function DeleteDialogUI({ handleRemoveElement, handleOpen, show, onHide }) {
  return (
    <DialogUI open={show} handleOpen={handleOpen} headerContent="Elem törlése" fullWidth={true}>
      <div className="relative">
        <div className="mt-2 flex gap-10 items-center justify-center z-50">
          <div className="relative">
            <div className="relative mt-5">
              <p className="text-2xl text-labelColor">Biztosan törölni szeretné?</p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 mt-8 flex justify-center gap-3">
        <ButtonUI text="Törlés" className="bg-danger" onClick={handleRemoveElement}></ButtonUI>
        <ButtonUI text="Mégse" className="" color="blue-gray" variant="gradient" onClick={onHide}></ButtonUI>
      </div>
    </DialogUI>
  );
}
