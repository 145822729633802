import React from 'react';
import { Field } from 'formik';

import { InputUI, AutoCompleteSelectUI, FormErrorUI, FormLabelUI } from '../Interface';

export const OperationFormFields = ({
  selectedValues,
  values,
  validateField,
  setFieldValue,
  errors,
  productionList,
}) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-2">
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="userId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Dolgozó" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.userId} />}
          onChange={(_e, newVal) => {
            setFieldValue('userId', newVal?.value ?? null).then(() => {
              validateField('userId');
            });
          }}
          InputLabelProps={{ shrink: values.userId !== '' }}
          selectedValue={values.userId}
          selectedLabelValue={selectedValues?.user?.userName}
          table={'WebUser'}
          listType={{ id: 'userId', name: 'userName' }}
          conditions={[`WebUser.archive = 'N'`]}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="number"
          name="quantity"
          as={InputUI}
          fullWidth
          label={
            <FormLabelUI
              text={`Mennyiség ${productionList && productionList.quantityUnit ? `(${productionList.quantityUnit})` : ''}`}
            />
          }
          helperText={<FormErrorUI message={errors.quantity} />}
          InputLabelProps={{ shrink: values.quantity !== '' }}
          variant="standard"
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="number"
          name="netWeight"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Nettó súly (kg)" />}
          helperText={<FormErrorUI message={errors.netWeight} />}
          InputLabelProps={{ shrink: values.netWeight !== '' }}
          variant="standard"
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="number"
          name="packingWeight"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Csomagolás súly (kg)" />}
          helperText={<FormErrorUI message={errors.packingWeight} />}
          InputLabelProps={{ shrink: values.packingWeight !== '' }}
          variant="standard"
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="timeSpent"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Ráfordított idő" />}
          helperText={<FormErrorUI message={errors.timeSpent} />}
          InputLabelProps={{ shrink: values.timeSpent !== '' }}
          variant="standard"
        />
      </div>
    </div>
  );
};
