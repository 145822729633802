import React, { useEffect, useState } from 'react';

import { dataGridColumns } from '../../utils/helper';
import propertyService from '../../service/property.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';

import { DataGridUI, DataGridIdentifiers } from '../Interface';

const GroupsDataGrid = ({ formPropertyGroup, id, setRefetchTable, disabled }) => {
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    const selectedIds = formPropertyGroup.filter((row) => row.checked).map((row) => row.id);
    setSelectedRows(selectedIds);
    setRefetchTable(true);
  }, [formPropertyGroup]);

  const updateformPropertyGroup = (formId, groupId) => {
    propertyService.updateFormGroupProperty(id, formId, groupId);
    if (formId !== null) {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
    }
  };

  const handleRowSelectionModelChange = (newRowSelectionModel) => {
    const changedRows = [
      ...newRowSelectionModel.filter((row) => !selectedRows.includes(row)),
      ...selectedRows.filter((row) => !newRowSelectionModel.includes(row)),
    ];

    changedRows.forEach((rowId) => {
      const formRow = formPropertyGroup.find((row) => row.id === rowId);
      if (formRow) {
        updateformPropertyGroup(formRow.formId, formRow.id);
      }
    });

    setSelectedRows(newRowSelectionModel);
  };

  const columns = dataGridColumns([
    {
      field: 'name',
      headerName: 'Csoportok',
      flex: 1,
    },
  ]);

  return (
    <DataGridUI
      datagridIdentifier={DataGridIdentifiers.groupsDataGrid}
      sx={{
        width: '100%',
        height: 300,
        '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
          display: 'none',
        },
        '& .MuiDataGrid-columnHeaderDraggableContainer': {
          backgroundColor: '#CEDFE8',
        },
      }}
      columns={columns}
      rows={formPropertyGroup}
      checkboxSelection
      disableRowSelectionOnClick={true}
      rowSelectionModel={selectedRows}
      isRowSelectable={() => !disabled}
      onRowSelectionModelChange={handleRowSelectionModelChange}
      hideFooter
    />
  );
};

export default GroupsDataGrid;
