import { DialogUI } from '../Interface';
import { SimpleTaskForm } from '../SimpleTask/SimpleTaskForm';
import { useSimpleTask } from '../../hooks/useSimpleTask';
import { useEffect, useState } from 'react';

const SimpleTaskDialog = ({ open, handleClose, changed, task }) => {
  const { taskChanged, setTaskChanged } = changed;
  const simpleTaskState = useSimpleTask();
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    if (task) {
      simpleTaskState.setSelectedValues(task);
      setIsUpdate(true);
    }
  }, [task, simpleTaskState]);

  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent={task ? 'Feladat módosítása' : 'Új feladat létrehozása'}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <div className="mt-5">
        <SimpleTaskForm
          dialog={{
            handleClose: handleClose,
            taskChanged: taskChanged,
            setTaskChanged: setTaskChanged,
            isUpdate: isUpdate,
          }}
          simpleTaskState={simpleTaskState}
        />
      </div>
    </DialogUI>
  );
};

export default SimpleTaskDialog;
