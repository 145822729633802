import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

const TrendIcon = ({ rowData, field, threshold, hasFeedback, setDialogData }) => {
  const type = threshold.min !== null && threshold.max !== null ? 'interval' : threshold.min !== null ? 'from' : 'to';
  const value = parseFloat(rowData.row?.[field?.field]);
  const iconProps = {
    color: '',
    direction: '',
    good: false,
  };

  switch (type) {
    case 'interval':
      if (threshold.min <= value && value <= threshold.max) {
        iconProps.color = 'green';
        iconProps.direction = 'up';
        iconProps.good = true;
      } else {
        iconProps.color = 'red';
        iconProps.direction = 'down';
        iconProps.good = false;
      }
      break;
    case 'from':
      if (threshold.min <= value) {
        iconProps.color = 'green';
        iconProps.direction = 'up';
        iconProps.good = true;
      } else {
        iconProps.color = 'red';
        iconProps.direction = 'down';
        iconProps.good = false;
      }
      break;
    case 'to':
      if (threshold.max >= value) {
        iconProps.color = 'green';
        iconProps.direction = 'down';
        iconProps.good = true;
      } else {
        iconProps.color = 'red';
        iconProps.direction = 'up';
        iconProps.good = false;
      }
      break;
  }

  return (
    <div
      onClick={() => {
        if (!iconProps.good && hasFeedback) {
          setDialogData(rowData.row);
        }
      }}
    >
      {threshold.arrow ? (
        iconProps.direction === 'up' ? (
          <ArrowDropUpIcon sx={{ color: iconProps.color, fontSize: 30 }} />
        ) : (
          <ArrowDropDownIcon sx={{ color: iconProps.color, fontSize: 30 }} />
        )
      ) : iconProps.good ? (
        <DoneIcon sx={{ color: iconProps.color, fontSize: 20, ml: 1 }} />
      ) : (
        <ClearIcon sx={{ color: iconProps.color, fontSize: 20, ml: 1 }} />
      )}
    </div>
  );
};

export default TrendIcon;
