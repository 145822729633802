import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'machine';

const machineService = {
  getMachines: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/machines`,
      data,
    });
  },

  getSingleMachines: (data) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/machines/singleMachines`,
      data,
    });
  },

  createMachine: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateMachine: (data, machineId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${machineId}`,
      data,
    });
  },

  deleteMachine: (data, machineId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${machineId}`,
      data,
    });
  },

  orderMachine: (machineId, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/orderMachine/${machineId}`,
      data,
    });
  },

  addResourceToList: (data, id) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/projectResource/${id}`,
      data,
    });
  },

  deleteResourceFromList: (data, listId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/projectResource/${listId}`,
      data,
    });
  },

  updateProjectResource: (data, listId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/projectResource/${listId}`,
      data,
    });
  },
};

export default machineService;
