import React, { useState, useEffect } from 'react';

import { dataGridColumns, generateFilter } from '../../utils/helper';
import propertyService from '../../service/property.service';
import { FieldType } from '../../config/property';

import { AutoCompleteSelectUI, DataGridUI, DataGridIdentifiers, DebouncedInputUI, FormLabelUI } from '../Interface';

const PropertyReports = ({ refetch, values, viewField, operatorsDisabled, rowSelection }) => {
  const { refetchTable, setRefetchTable } = refetch;
  const { selectedValues, setSelectedValues } = values;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  const [filterFormState, setFilterFormState] = useState({
    formId: '',
    name: '',
    groupId: '',
  });

  const conditions = {
    like: ['name'],
  };

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'archive',
        value: 'N',
      },
    ];

    const newFilter = generateFilter(filterFormState, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
    setSelectedValues({});
    setRowSelectionModel([]);
  }, [filterFormState]);

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getList();
    }
  }, [query]);

  useEffect(() => {
    if (refetchTable && query.filter?.length > 0) {
      getList();
    }
  }, [refetchTable]);

  useEffect(() => {
    if (!Object.keys(selectedValues).length) {
      setRowSelectionModel([]);
    }
  }, [selectedValues]);

  const onPaginitonChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  const getList = () => {
    propertyService.properties(query).then((data) => {
      setReports(data);
      setRefetchTable(false);
    });
  };

  const columns = dataGridColumns([
    {
      field: 'name',
      headerName: 'Megnevezés',
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Típus',
      flex: 1,
      renderCell: (params) => {
        const fieldType = FieldType.find((field) => field.value === params.value);
        return fieldType ? fieldType.label : params.value;
      },
    },
    {
      field: 'visOrder',
      headerName: 'Sorrend',
      flex: 1,
    },
  ]);

  return (
    <>
      <div className="flex  overflow-hidden m-4 ">
        <div className="h-full w-full  overflow-x-auto ">
          <div className="grid grid-cols-4 gap-x-4 mx-4">
            <div className="col-span-2 md:col-span-1">
              <DebouncedInputUI
                label={<FormLabelUI text="Megnevezés" />}
                debounceMs={800}
                setQuickFilterSearchValue={(newValue) => {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    name: newValue,
                  }));
                }}
                quickFilterSearchValue={filterFormState.name}
              />
            </div>
            <div className="col-span-2 md:col-span-1">
              <AutoCompleteSelectUI
                id="Submenus"
                label={<FormLabelUI text="Form" />}
                table="submenus"
                listType={{ id: 'subMenuId', name: 'subMenuName' }}
                conditions={[`submenus.isFormType = '1'`]}
                onChange={(_e, newVal, reason) => {
                  if (reason === 'clear') {
                    setFilterFormState((prevState) => ({
                      ...prevState,
                      formId: '',
                    }));
                  } else {
                    setFilterFormState((prevState) => ({
                      ...prevState,
                      formId: newVal.value,
                    }));
                  }
                }}
                selectedValue={filterFormState.formId}
                disabled={!viewField}
              />
            </div>
            <div className="col-span-2 md:col-span-1">
              <AutoCompleteSelectUI
                id="groupId"
                label={<FormLabelUI text="Csoport" />}
                table="propertyGroup"
                listType={{ id: 'id', name: 'name' }}
                onChange={(_e, newVal, reason) => {
                  if (reason === 'clear') {
                    setFilterFormState((prevState) => ({
                      ...prevState,
                      groupId: '',
                    }));
                  } else {
                    setFilterFormState((prevState) => ({
                      ...prevState,
                      groupId: newVal.value,
                    }));
                  }
                }}
                selectedValue={filterFormState.groupId}
                disabled={!viewField}
              />
            </div>
          </div>
          <div className="inline-block w-full border-b border-gray-200 align-middle mt-4 overflow-y-auto overflow-x-auto ">
            <DataGridUI
              datagridIdentifier={DataGridIdentifiers.PropertyReports}
              initialState={query}
              rows={reports.rows}
              rowCount={reports.rowCount}
              columns={columns}
              onRowClick={(rowData) => {
                if (!viewField) return;
                setSelectedValues(rowData.row);
              }}
              paginationMode="server"
              onPaginationModelChange={onPaginitonChange}
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
              disableRowSelectionOnClick={!viewField}
              name="property"
              height={750}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyReports;
