const dayjs = require('dayjs');
const isBetween = require('dayjs/plugin/isBetween');
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);

export const parseTime = (time) => {
  return dayjs(`1970-01-01T${time}`);
};
const defaultDuration = 30;

export const intervalsOverlapTime = (start1, end1, start2, end2) => {
  return (
    start1.isBetween(start2, end2, null, '[)') ||
    end1.isBetween(start2, end2, null, '(]') ||
    start2.isBetween(start1, end1, null, '[)') ||
    end2.isBetween(start1, end1, null, '(]')
  );
};

export const isOverlappingTime = (newInterval, existingIntervals) => {
  let newStart, newEnd;

  if (newInterval.start && !newInterval.end) {
    newStart = parseTime(newInterval.start);
    newEnd = newStart.add(defaultDuration, 'minute');
  } else if (!newInterval.start && newInterval.end) {
    newEnd = parseTime(newInterval.end);
    newStart = newEnd.subtract(defaultDuration, 'minute');
  } else if (newInterval.start && newInterval.end) {
    newStart = parseTime(newInterval.start);
    newEnd = parseTime(newInterval.end);
  } else {
    throw new Error('Both start and end times are missing.');
  }

  return existingIntervals.some((interval) => {
    const start = parseTime(interval.start);
    const end = parseTime(interval.end);

    if (newStart.isSameOrAfter(newEnd)) {
      return (
        intervalsOverlapTime(newStart, parseTime('23:59'), start, end) ||
        intervalsOverlapTime(parseTime('00:00'), newEnd, start, end)
      );
    }

    if (start.isSameOrAfter(end)) {
      return (
        intervalsOverlapTime(newStart, newEnd, start, parseTime('23:59')) ||
        intervalsOverlapTime(newStart, newEnd, parseTime('00:00'), end)
      );
    }

    return intervalsOverlapTime(newStart, newEnd, start, end);
  });
};
