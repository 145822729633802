import React from 'react';
import KanbanView from './KanbanView';

export const FunnelView = () => {
  return (
    <div>
      <KanbanView funnelView={true} />
    </div>
  );
};
