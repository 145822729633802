import { useState, useEffect } from 'react';

import { DataGridUI, DataGridIdentifiers, DebouncedInputUI, FormLabelUI, AutoCompleteSelectUI } from '../Interface';
import { dataGridColumns, generateFilter } from '../../utils/helper';
import propertyGroupService from '../../service/propertyGroup.service';

export default function PropertyGroupReports({
  refetch,
  values,
  viewField,
  operatorsDisabled,
  rowSelection,
  autoCompleteData,
}) {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  const [filterFormState, setFilterFormState] = useState({
    name: '',
    formId: '',
  });

  const conditions = {
    like: ['name'],
  };

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'deletedBy',
        value: null,
      },
    ];

    const newFilter = generateFilter(filterFormState, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
  }, [filterFormState]);

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getList();
    }
  }, [query]);

  useEffect(() => {
    if (refetchTable && query.filter?.length > 0) {
      getList();
    }
  }, [refetchTable]);

  const onPaginitonChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  const getList = () => {
    propertyGroupService.getPropertyGroups(query).then((data) => {
      setReports(data);
      setRefetchTable(false);
    });
  };

  const columns = dataGridColumns([
    {
      field: 'name',
      headerName: 'Csoport neve',
      flex: 1,
    },
    {
      field: 'formId',
      headerName: 'Megjelenés helye',
      flex: 1,
      renderCell: (params) => {
        return params.row.submenus.subMenuName;
      },
    },
  ]);

  return (
    <div className="flex  overflow-hidden m-4 ">
      <div className="h-full w-full  overflow-x-auto ">
        <div className="grid grid-cols-4 gap-x-4 mx-4">
          <div className="col-span-2 md:col-span-1">
            <DebouncedInputUI
              label={<FormLabelUI text="Csoport neve" />}
              debounceMs={800}
              setQuickFilterSearchValue={(newValue) => {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  name: newValue,
                }));
              }}
              quickFilterSearchValue={filterFormState.name}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <AutoCompleteSelectUI
              id="formId"
              label={<FormLabelUI text="Form" />}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    formId: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    formId: newVal.value,
                  }));
                }
              }}
              selectedValue={filterFormState.formId}
              dataset={autoCompleteData.submenus}
            />
          </div>
        </div>
        <div className="inline-block w-full border-b border-gray-200 align-middle mt-4 overflow-y-auto overflow-x-auto ">
          <DataGridUI
            datagridIdentifier={DataGridIdentifiers.QuantityUnitReports}
            initialState={query}
            rows={reports.rows}
            rowCount={reports.rowCount}
            columns={columns}
            onRowClick={(rowData) => {
              if (!viewField) return;
              setSelectedValues(rowData.row);
            }}
            paginationMode="server"
            onPaginationModelChange={onPaginitonChange}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            disableRowSelectionOnClick={!viewField}
            height={750}
          />
        </div>
      </div>
    </div>
  );
}
