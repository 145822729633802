export const getFieldOptions = (tableName, projectSelectFirst, disabledField, projectId) => {
  if (projectSelectFirst && !disabledField) {
    return {
      table: tableName,
      listType: { id: 'id', name: 'name' },
      joins: `${tableName}.id = project${tableName.charAt(0).toUpperCase() + tableName.slice(1)}.resourceId AND project${tableName.charAt(0).toUpperCase() + tableName.slice(1)}.id = project.projectId`,
      conditions: `${tableName}.id IN (
          SELECT pr.resourceId
          FROM project${tableName.charAt(0).toUpperCase() + tableName.slice(1)} pr
          JOIN project p ON pr.id = p.projectId
          WHERE p.projectId = ${projectId}
          AND pr.archive = "N"
        )`,
    };
  } else {
    return {
      table: tableName,
      listType: { id: 'id', name: 'name' },
      conditions: [`${tableName}.archive = 'N'`],
    };
  }
};
