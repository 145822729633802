import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'province';

const provinceService = {
  getProvince: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/province`,
      data,
    });
  },

  createProvince: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateProvince: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  deleteProvince: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  addProvinceDayOffToList: (data, id) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/provinceDayOff/${id}`,
      data,
    });
  },

  deleteProvinceDayOffFromList: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/provinceDayOff/${id}`,
      data,
    });
  },

  updateProvinceDayOff: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/provinceDayOff/${id}`,
      data,
    });
  },
};

export default provinceService;
