import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'crmTask';

const crmTaskService = {
  getTaskById: (id) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/task/${id}`,
    });
  },

  getTasks: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/tasks`,
      data,
    });
  },

  createTask: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateTask: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  deleteTask: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },
};

export default crmTaskService;
