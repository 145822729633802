export const MainCategoryOption = [
  { value: 1, label: 'Szabadság' },
  { value: 2, label: 'Betegszabadság' },
];

export const SubCategoryOption = {
  1: [
    { value: 1, label: 'Engedélyezett', code: 'U', color: '#4ade80' },
    { value: 2, label: 'Nem engedélyezett' },
    { value: 6, label: 'Fizetetlen szabadság', code: 'UU', color: '#7b2cbf' },
    { value: 7, label: 'Oktatás', code: 'O', color: '#d1d5db' },
    { value: 8, label: 'Kényszerszabadság', code: 'ZU' },
  ],
  2: [
    { value: 3, label: 'Beteg, de nem küldött még betegpapírt', code: 'K' },
    { value: 4, label: 'Beteg, megküldte a papírját', code: 'K', color: '#67e8f9' },
    { value: 5, label: '6 héten túli beteg', code: 'K', color: '#bae6fd' },
  ],
};
