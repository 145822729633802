import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/hu';
import { StaticDatePicker } from '@mui/x-date-pickers';

const StaticDatePickerUI = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="hu">
      <StaticDatePicker {...props} />
    </LocalizationProvider>
  );
};

export default StaticDatePickerUI;
