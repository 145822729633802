import { useEffect, useMemo, useState } from 'react';
import useMenus from '../context/MenuContext';
import useParam from '../context/ParamContext';

export const useSimpleTask = () => {
  const { menus, getMenus, getMenuSubMenuId, getSubMenuNamesByUrls, getCanEdit } = useMenus();
  const { getParam, params } = useParam();
  const [refetchTable, setRefetchTable] = useState(true);
  const [viewField, setViewField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [submenu, setSubmenu] = useState(null);
  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    getMenus();
    getParam();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('simpleTask');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  const submenuNames = useMemo(() => {
    return getSubMenuNamesByUrls(['resourceOne', 'resourceTwo', 'resourceThree', 'resourceFour', 'machine']);
  }, [menus]);

  return {
    refetchTable,
    setRefetchTable,
    viewField,
    setViewField,
    selectedValues,
    setSelectedValues,
    operatorsDisabled,
    setOperatorsDisabled,
    submenu,
    submenuNames,
    params,
    canEdit,
  };
};
