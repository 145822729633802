export const DashboardType = {
  //columns x rows
  _1x1: '1X1',
  _2x1: '2X1',
  _3x1: '3X1',
  _4x1: '4X1',
  _1x2: '1X2',
  _2x2: '2X2',
  _3x2: '3X2',
  _4x2: '4X2',
};

export const DashboardTypeList = [
  { label: '1X1', value: DashboardType._1x1, column: 1, row: 1 },
  { label: '2X1', value: DashboardType._2x1, column: 2, row: 1 },
  { label: '3x1', value: DashboardType._3x1, column: 3, row: 1 },
  { label: '4X1', value: DashboardType._4x1, column: 4, row: 1 },
  { label: '1X2', value: DashboardType._1x2, column: 1, row: 2 },
  { label: '2X2', value: DashboardType._2x2, column: 2, row: 2 },
  { label: '3x2', value: DashboardType._3x2, column: 3, row: 2 },
  { label: '4X2', value: DashboardType._4x2, column: 4, row: 2 },
];
