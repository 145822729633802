import React, { useState, useEffect } from 'react';

import biService from '../../../service/bi.service';
import BiQueryTable from './BiQueryTable';
import BiQueryPreview from './BiQueryPreview';

const BiQueryPage = () => {
  const [selectedValues, setSelectedValues] = useState({});
  const [biHeads, setBiHeads] = useState([]);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    getAllBiHeads();
  }, []);

  const getAllBiHeads = () => {
    biService.getAllBiHeads().then((data) => {
      const biData = data.map((dbObj) => {
        return { value: dbObj.id, label: dbObj.name };
      });
      setBiHeads(biData);
    });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-6 h-screen gap-2 p-4 bg-gray-100 mt-[-4rem] pt-[5rem]">
      <div className="col-span-1 lg:col-span-1 xl:col-span-2 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="h-full overflow-auto custom-scrollbar">
          <BiQueryTable
            values={{ selectedValues, setSelectedValues }}
            dataset={{ biHeads }}
            refetchState={{ refetch, setRefetch }}
          />
        </div>
      </div>
      <div className="col-span-1 lg:col-span-1 xl:col-span-4 overflow-hidden bg-white shadow sm:rounded-lg  ">
        <div className="h-full overflow-auto custom-scrollbar">
          <BiQueryPreview
            selectedValuesState={{ selectedValues, setSelectedValues }}
            refetchState={{ refetch, setRefetch }}
          />
        </div>
      </div>
    </div>
  );
};

export default BiQueryPage;
