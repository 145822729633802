import { Field } from 'formik';

import { MainCategoryOption, SubCategoryOption } from '../../config/absence';
import { FormErrorUI, FormLabelUI, DatePickerUI, AutoCompleteSelectUI, SelectUI } from '../Interface';

const AbsenceFields = ({ values, errors, setFieldValue, validateField, disabled, selectedValues }) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
      <div className="col-span-4 md:col-span-2">
        <Field
          type="date"
          name="startDate"
          component={DatePickerUI}
          value={values.startDate}
          fullWidth
          label={<FormLabelUI text="Kezdő dátum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.startDate} />}
          onChange={(date) => {
            setFieldValue('startDate', date).then(() => {
              validateField('startDate');
            });
          }}
          disabled={disabled}
          InputLabelProps={{ shrink: values.startDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="date"
          name="endDate"
          component={DatePickerUI}
          value={values.endDate}
          fullWidth
          label={<FormLabelUI text="Befejező dátum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.endDate} />}
          onChange={(date) => {
            setFieldValue('endDate', date).then(() => {
              validateField('endDate');
            });
          }}
          disabled={disabled}
          InputLabelProps={{ shrink: values.endDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="userId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Dolgozó" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.userId} />}
          onChange={(_e, newVal) => {
            setFieldValue('userId', newVal?.value ?? null).then(() => {
              validateField('userId');
            });
          }}
          disabled={disabled}
          InputLabelProps={{ shrink: values.userId !== '' }}
          selectedValue={values.userId}
          selectedLabelValue={selectedValues?.user?.name}
          table={'resourceOne'}
          listType={{ id: 'id', name: 'name' }}
          conditions={[`resourceOne.archive = 'N'`]}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          name="mainCategory"
          component={SelectUI}
          fullWidth
          label={<FormLabelUI text="Főkategória" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.mainCategory} />}
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue('mainCategory', value ?? null).then(() => {
              validateField('mainCategory');
              setFieldValue('subCategory', null);
            });
          }}
          value={values.mainCategory ?? ''}
          disabled={disabled}
          option={MainCategoryOption}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          name="subCategory"
          component={SelectUI}
          fullWidth
          label={<FormLabelUI text="Alkategória" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.subCategory} />}
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue('subCategory', value ?? null).then(() => {
              validateField('subCategory');
            });
          }}
          value={values.subCategory ?? ''}
          disabled={disabled}
          option={SubCategoryOption[values.mainCategory] || []}
        />
      </div>
    </div>
  );
};

export default AbsenceFields;
