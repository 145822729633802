import React, { createContext, useContext, useReducer } from 'react';
import { pageReducer } from '../reducer/pageReducer';

const PageContext = createContext({});

export const PageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(pageReducer, {});

  const setPages = (pages) => {
    return dispatch({ type: 'SET', payload: pages });
  };

  const getPages = () => {
    return dispatch({ type: 'GET' });
  };

  const value = {
    pages: state,
    setPages,
    getPages,
  };

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

const usePages = () => {
  return useContext(PageContext);
};

export default usePages;
