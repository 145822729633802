import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'activetime/company';

const companyActiveService = {
  getCompanyActive: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/companyActiveList`,
      data,
    });
  },

  createCompanyActive: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/companyActive`,
      data,
    });
  },

  deleteCompanyActive: (id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/companyActive/${id}`,
    });
  },

  updateCompanyActive: (id, data) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/companyActive/${id}`,
      data,
    });
  },

  createCompanyActiveItem: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/companyActiveItem`,
      data,
    });
  },

  updateCompanyActiveItem: (id, data) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/companyActiveItem/${id}`,
      data,
    });
  },

  deleteCompanyActiveItem: (id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/companyActiveItem/${id}`,
    });
  },

  getCompanyActiveItemsById: (id) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/companyActiveItem/${id}`,
    });
  },

  getCompanyDayOff: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/companyDayOffList`,
      data,
    });
  },

  createCompanyDayOff: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/companyDayOff`,
      data,
    });
  },

  deleteCompanyDayOff: (id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/companyDayOff/${id}`,
    });
  },

  updateCompanyDayOff: (id, data) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/companyDayOff/${id}`,
      data,
    });
  },
};

export default companyActiveService;
