import { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import { dataGridColumns, generateFilter } from '../../utils/helper';
import { AutoCompleteSelectUI, DataGridUI, DataGridIdentifiers, DatePickerUI, FormLabelUI } from '../Interface';
import vehiclePlanService from '../../service/vehiclePlanning.service';

const VehiclePlanReports = ({ refetch, values, viewField, operatorsDisabled, rowSelection, autoCompleteData }) => {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [{ field: 'startDate', sort: 'desc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  const [filterFormState, setFilterFormState] = useState({
    startDate: null,
    endDate: null,
    employeeId: '',
    vehicleId: '',
  });

  const conditions = {
    gte: ['startDate'],
    lte: ['endDate'],
  };

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'deletedBy',
        value: null,
      },
    ];

    const newFilter = generateFilter(filterFormState, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
  }, [filterFormState]);

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getList();
    }
  }, [query]);

  useEffect(() => {
    if (refetchTable && query.filter?.length > 0) {
      getList();
    }
  }, [refetchTable]);

  const onPaginitonChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  const getList = () => {
    vehiclePlanService.getVehiclePlans(query).then((data) => {
      setReports(data);
      setRefetchTable(false);
    });
  };

  const columns = dataGridColumns([
    {
      field: 'startDate',
      headerName: 'Kezdő dátum',
      flex: 1,
      renderCell: (params) => {
        return dayjs(params.row.startDate).format('YYYY.MM.DD.');
      },
    },
    {
      field: 'endDate',
      headerName: 'Befejező dátum',
      flex: 1,
      renderCell: (params) => {
        return dayjs(params.row.endDate).format('YYYY.MM.DD.');
      },
    },
    {
      field: 'employeeId',
      headerName: 'Dolgozó',
      flex: 1,
      renderCell: (params) => {
        return params.row?.employee?.name;
      },
      sortable: false,
    },
    {
      field: 'vehicleId',
      headerName: 'Autó',
      flex: 1,
      renderCell: (params) => {
        return params.row?.vehicle?.name;
      },
      sortable: false,
    },
  ]);

  return (
    <div className="flex  overflow-hidden mx-4 mt-3">
      <div className="h-full w-full  overflow-x-auto ">
        <div className="grid grid-cols-4 gap-x-4 mx-4">
          <div className="col-span-2 md:col-span-1">
            <DatePickerUI
              label={<FormLabelUI text="Kezdő dátum" />}
              value={filterFormState.startDate}
              onChange={(newDate) => {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  startDate: newDate ? newDate.startOf('day') : null,
                }));
              }}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <DatePickerUI
              label={<FormLabelUI text="Befejező dátum" />}
              value={filterFormState.endDate}
              onChange={(newDate) => {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  endDate: newDate ? newDate.endOf('day') : null,
                }));
              }}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <AutoCompleteSelectUI
              id="worker"
              label={<FormLabelUI text="Dolgozó" />}
              selectedValue={filterFormState.employeeId}
              dataset={autoCompleteData.employees}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    employeeId: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    employeeId: newVal.value,
                  }));
                }
              }}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <AutoCompleteSelectUI
              id="vehicle"
              label={<FormLabelUI text="Autó" />}
              selectedValue={filterFormState.vehicleId}
              dataset={autoCompleteData.vehicles}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    vehicleId: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    vehicleId: newVal.value,
                  }));
                }
              }}
            />
          </div>
        </div>

        <div className="inline-block w-full border-b border-gray-200 align-middle mt-4 overflow-y-auto overflow-x-auto ">
          <DataGridUI
            datagridIdentifier={DataGridIdentifiers.vehiclePlanDatagrid}
            initialState={query}
            rows={reports.rows}
            rowCount={reports.rowCount}
            columns={columns}
            onRowClick={(rowData) => {
              if (!viewField) return;
              setSelectedValues(rowData.row);
            }}
            paginationMode="server"
            onPaginationModelChange={onPaginitonChange}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            disableRowSelectionOnClick={!viewField}
            height={750}
          />
        </div>
      </div>
    </div>
  );
};

export default VehiclePlanReports;
