import React, { useState } from 'react';
import Switch from '@mui/material/Switch';

const SwitchUI = ({ disabled, onClick, itemId, checked: initialChecked }) => {
  const [checked, setChecked] = useState(initialChecked);

  const handleChange = async (event) => {
    const newChecked = event.target.checked;
    setChecked(newChecked);
    if (onClick) {
      onClick(itemId, newChecked);
    }
  };

  return <Switch disabled={disabled} checked={checked} onChange={handleChange} />;
};

export default SwitchUI;
