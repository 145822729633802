import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'taskevaluation/tasks';

const taskevaluationTaskService = {
  getTasks: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/tasksChartData`,
      data,
    });
  },
};
export default taskevaluationTaskService;
