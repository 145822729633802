import React, { useEffect, useState } from 'react';
import useParam from '../../../context/ParamContext';

const ChipContent = ({ eventInfo }) => {
  const { params } = useParam();
  const [chipObj, setChipObj] = useState(null);

  useEffect(() => {
    let paramKey = 'VEHICLE_RESOURCE_PLANNER_CHIP_CONTENT';

    if (params[paramKey]) {
      const parsedObj = JSON.parse(params[paramKey]);
      setChipObj(parsedObj);
    }
  }, [params]);

  const task = eventInfo.event._def.extendedProps.eventData;

  const getNestedValue = (obj, path) => {
    if (!obj || !path) return null;
    const keys = path.split('.');

    return keys.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : null), obj);
  };

  const setChipContent = () => {
    if (!chipObj || !task) {
      return null;
    }

    return (
      <span className="truncate block" style={{ marginTop: '2px', marginBottom: '2px' }}>
        {chipObj.map((item, index) => {
          const { label } = item;
          const taskValue = getNestedValue(task, label);

          return (
            <span key={index}>
              {index > 0 && ' - '}
              {taskValue !== null ? taskValue : 'N/A'}
            </span>
          );
        })}
      </span>
    );
  };

  return <div>{setChipContent()}</div>;
};

export default ChipContent;
