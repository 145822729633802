import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ButtonUI, DeleteDialogUI } from '../../Interface';
import BiReport from '../BiReport/BiReport';
import biService from '../../../service/bi.service';
import addNotification from '../../../utils/addNotification';
import { NotificationType } from '../../../config';

const BiQueryPreview = ({ selectedValuesState, refetchState }) => {
  const { setRefetch } = refetchState;
  const { selectedValues, setSelectedValues } = selectedValuesState;
  const navigate = useNavigate();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleRemoveElement = async () => {
    biService.deleteBiReport(selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });

      setShowDeleteDialog(false);
      setRefetch(true);
      setSelectedValues({});
    });
  };

  return Object.keys(selectedValues).length ? (
    <>
      <div className="lg:flex-[8] xl:flex-[9] 2xl:flex-[11] flex-1 min-h-[450px] overflow-hidden">
        <BiReport reportId={selectedValues.id} />
      </div>
      <div className="flex space-x-4 mx-4 mb-5">
        <ButtonUI
          text={'Szerkeszt'}
          className="bg-warning"
          type="button"
          onClick={() => navigate(`/app/bi/editor/${selectedValues.id}`)}
        />
        <ButtonUI text={'Törlés'} className="bg-danger" type="button" onClick={() => setShowDeleteDialog(true)} />
      </div>

      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  ) : (
    <div>Nincs kiválasztott riport</div>
  );
};

export default BiQueryPreview;
