import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { huHU } from '@mui/x-data-grid/locales';
import { isEqual } from 'lodash';
import {
  DataGridPremium,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridLogicOperator,
} from '@mui/x-data-grid-premium';

import { datagridLangHunCustomized } from '../../../config/datagridLangHunCustomized';
import useSaveView from '../../../hooks/useSaveView';
import useView from '../../../hooks/useView';
import { getDataGridStyles } from '../Datagrid/dataGridStyles';
import { DataGridIdentifiers } from '../Datagrid/DataGridIdentifiers';
import useUsers from '../../../context/UsersContext';

const DataGridPremiumUI = ({
  datagridIdentifier,
  dataLoader,
  paginationAndfilterMode = 'client',
  datagridInitialState,
  ...props
}) => {
  const parentNode = document.body.parentNode;
  const height = parentNode.clientHeight * 0.85;

  const { getUser, user } = useUsers();
  const [isLoading, setisLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [queryOptions, setQueryOptions] = useState({
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });
  const [datagridState, setDatagridState] = useState(null);
  const [prevState, setPrevState] = useState(null);
  const [currentState, setCurrentState] = useState(null);
  const apiRef = useGridApiRef();
  const saveView = useSaveView(user);
  const dataGrid = useView('dataGrid', user);

  const handleEvent = (_params, _event, _details) => {
    if (Object.keys(_params).length > 0) {
      const currentState = apiRef.current.exportState();
      setCurrentState(currentState);
    }
  };

  const loader = async (tableState) => {
    setisLoading(true);
    await dataLoader(tableState);
    setisLoading(false);
  };

  useEffect(() => {
    if (!Object.keys(user).length) getUser();
  }, []);

  useEffect(() => {
    if (apiRef.current) {
      apiRef.current.subscribeEvent('aggregationModelChange', handleEvent);
      apiRef.current.subscribeEvent('columnVisibilityModelChange', handleEvent);
      apiRef.current.subscribeEvent('columnOrderChange', handleEvent);
      apiRef.current.subscribeEvent('columnWidthChange', handleEvent);
      apiRef.current.subscribeEvent('filterModelChange', handleEvent);
      apiRef.current.subscribeEvent('paginationModelChange', handleEvent);
      apiRef.current.subscribeEvent('rowGroupingModelChange', handleEvent);
      apiRef.current.subscribeEvent('sortModelChange', handleEvent);
      apiRef.current.subscribeEvent('onDensityChange', handleEvent);
      //apiRef.current.subscribeEvent('rowOrderChange', handleEvent);
      //apiRef.current.subscribeEvent('rowCountChange', handleEvent);
    }
  }, [apiRef.current]);

  useEffect(() => {
    if (datagridIdentifier && !isEqual(prevState, currentState)) {
      saveView('dataGrid', { [datagridIdentifier]: currentState });
    }
  }, [prevState, currentState]);

  useEffect(() => {
    const savedDatagridState = dataGrid?.[datagridIdentifier];

    const options = {
      filter: savedDatagridState?.filter ?? datagridInitialState?.filter,
      pagination: savedDatagridState?.pagination ?? datagridInitialState?.pagination,
      sorting: savedDatagridState?.sorting ?? datagridInitialState?.sorting,
    };
    if (savedDatagridState && apiRef && user) {
      setDatagridState(savedDatagridState);
      apiRef.current.restoreState(savedDatagridState);

      setPrevState(savedDatagridState);
      setQueryOptions(options);

      if (paginationAndfilterMode === 'server' && isInitialLoad) {
        loader(options);
      }

      setIsInitialLoad(false);
    }
  }, [apiRef, datagridIdentifier, user]);

  if (!datagridIdentifier) {
    console.error('datagridIdentifier is required but was not provided.');
    return null; // Prevent the component from rendering if the identifier is not provided
  }
  if (!paginationAndfilterMode) {
    console.error('paginationAndfilterMode is required but was not provided.');
    return null; // Prevent the component from rendering if the paginationAndFilterMode is not provided
  }

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  const onFilterModelChange = (filterModel) => {
    if (!isInitialLoad) {
      const options = { ...queryOptions, filter: { filterModel } };
      setQueryOptions(options);
      if (paginationAndfilterMode === 'server') {
        loader(options);
      }
    }
  };

  const onPaginationModelChange = (paginationModel) => {
    if (!isInitialLoad) {
      const options = { ...queryOptions, pagination: { paginationModel } };
      setQueryOptions(options);
      if (paginationAndfilterMode === 'server') {
        loader(options);
      }
    }
  };

  const onSortModelChange = (sortModel) => {
    if (!isInitialLoad) {
      const options = { ...queryOptions, sorting: { sortModel } };
      setQueryOptions(options);
      if (paginationAndfilterMode === 'server') {
        loader(options);
      }
    }
  };

  return (
    <DataGridPremium
      pagination
      apiRef={apiRef}
      initialState={datagridState ?? datagridInitialState}
      rowHeight={45}
      sx={getDataGridStyles({ height: height })}
      slots={{ toolbar: CustomToolbar }}
      slotProps={{
        columnsPanel: {
          sx: {
            '& .MuiDataGrid-panelFooter button:first-child': {
              display: 'none',
            },
          },
        },
        filterPanel: {
          logicOperators: [GridLogicOperator.And],
        },
      }}
      localeText={{ ...huHU.components.MuiDataGrid.defaultProps.localeText, ...datagridLangHunCustomized }}
      disableSelectionOnClick
      headerFilters
      onFilterModelChange={onFilterModelChange}
      onPaginationModelChange={onPaginationModelChange}
      onSortModelChange={onSortModelChange}
      paginationMode={paginationAndfilterMode}
      sortingMode={paginationAndfilterMode}
      filterMode={paginationAndfilterMode}
      loading={isLoading}
      {...props}
    />
  );
};

DataGridPremiumUI.propTypes = {
  datagridIdentifier: PropTypes.oneOf(Object.values(DataGridIdentifiers)).isRequired,
  paginationAndfilterMode: PropTypes.oneOf(['server', 'client']).isRequired,
};

export default React.memo(DataGridPremiumUI);
