import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'calendar';

const calendarService = {
  getCalendarDays: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/calendarDays`,
      data,
    });
  },
};
export default calendarService;
