import React from 'react';
import { useCrmTask } from '../../hooks/useCrmTask';
import { CrmTaskPage } from './CrmTaskPage';
import CrmTaskReports from './CrmTaskReports';

export const CrmTaskMain = () => {
  const crmTaskState = useCrmTask();

  return (
    <div className="grid grid-cols-1 xl:grid-cols-6 grid-rows-[auto_1fr] gap-y-4 xl:gap-4 bg-gray-100 p-4">
      <CrmTaskPage crmTaskState={crmTaskState} />
      <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white shadow sm:rounded-lg  ">
        <CrmTaskReports crmTaskState={crmTaskState} />
      </div>
    </div>
  );
};
