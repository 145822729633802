import React, { useEffect, useState } from 'react';
import useParam from '../../context/ParamContext';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';

const TooltipContent = ({ eventInfo }) => {
  const { params } = useParam();
  const [tooltipObj, setTooltipObj] = useState(null);

  useEffect(() => {
    let paramKey = 'EMPLOYEE_RESOURCE_PLANNER_HINT_CONTENT';

    if (params[paramKey]) {
      const parsedObj = JSON.parse(params[paramKey]);
      setTooltipObj(parsedObj);
    }
  }, [params]);

  const task = eventInfo.event._def.extendedProps.eventData;

  const getFieldValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => {
      if (Array.isArray(acc)) {
        return acc.map((item) => item[part]);
      } else {
        return acc ? acc[part] : null;
      }
    }, obj);
  };

  const formatDate = (dateString) => {
    if (!dateString) return null;
    return dayjs(dateString).format('YYYY-MM-DD');
  };

  const setTooltipContent = () => {
    if (!tooltipObj || !task) {
      return null;
    }

    return tooltipObj.map((item, index) => {
      const { label, fieldName, type } = item;
      let taskValue = getFieldValue(task, fieldName);

      if (type === 'date') {
        taskValue = Array.isArray(taskValue) ? taskValue.map(formatDate) : formatDate(taskValue);
      }

      if (Array.isArray(taskValue)) {
        return taskValue.map((value, subIndex) => (
          <Typography key={`${index}-${subIndex}`}>
            {label}: {value}
          </Typography>
        ));
      } else {
        return (
          <Typography key={index}>
            {label}: {taskValue}
          </Typography>
        );
      }
    });
  };

  const tooltipContent = setTooltipContent();

  return <div>{tooltipContent}</div>;
};

export default TooltipContent;
