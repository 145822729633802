import React from 'react';
import ButtonUI from './ButtonUI';

const FormButtonPanelUI = ({ onCancelClick, disabled }) => {
  return (
    <div className="flex gap-3 justify-center">
      <ButtonUI text="Rögzítés" className="bg-success flex gap-2 items-center" type={'submit'} disabled={disabled}>
        Rögzítés
      </ButtonUI>
      {onCancelClick && (
        <ButtonUI
          text="Mégse"
          className="bg-danger flex items-center gap-2"
          type="button"
          onClick={onCancelClick}
          disabled={disabled}
        >
          Mégse
        </ButtonUI>
      )}
    </div>
  );
};

export default FormButtonPanelUI;
