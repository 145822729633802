import React from 'react';

import ProjectReports from './ProjectReports';
import { ProjectForm } from './ProjectForm';
import { useProject } from '../../hooks/useProject';

export const ProjectMain = () => {
  const projectState = useProject();

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-6 h-screen gap-2 p-4 bg-gray-100 mt-[-4rem] pt-[5rem]">
      <ProjectForm projectState={projectState} />
      <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white shadow sm:rounded-lg  ">
        <ProjectReports projectState={projectState} />
      </div>
    </div>
  );
};
