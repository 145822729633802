import React, { createContext, useContext, useReducer } from 'react';
import { menuReducer } from '../reducer/menuReducer';

const MenuContext = createContext({});

export const MenuProvider = ({ children }) => {
  const [state, dispatch] = useReducer(menuReducer, {});

  const user = JSON.parse(localStorage.getItem('user'));

  const setMenus = (menus) => {
    return dispatch({ type: 'SET', payload: menus });
  };

  const getMenus = () => {
    return dispatch({ type: 'GET' });
  };

  const getAllSubmenus = () => {
    const menus = Object.values(state);
    if (!menus || !Array.isArray(menus)) {
      return [];
    }

    return menus.reduce((acc, menu) => {
      if (menu.submenus) {
        return [...acc, ...menu.submenus];
      }
      return acc;
    }, []);
  };

  const getMenuSubMenuId = (url) => {
    const allSubmenus = getAllSubmenus();
    const subMenu = allSubmenus.find((submenu) => submenu.url === url);
    return subMenu?.subMenuId ?? null;
  };

  const getCanEdit = (submenuId) => {
    const submenus = getAllSubmenus();
    const subMenu = submenus.find((sm) => sm.subMenuId === submenuId);

    const permission = subMenu?.roleSubmenuPermission.find((permission) => permission.roleId === user.roleId);

    return permission ? permission.canEdit : false;
  };

  const getSubMenuNamesByUrls = (urls) => {
    const allSubmenus = getAllSubmenus();
    return urls.map((url) => {
      const subMenu = allSubmenus.find((submenu) => submenu.url === url);
      return subMenu?.subMenuName ?? null;
    });
  };

  const value = {
    menus: state,
    setMenus,
    getMenus,
    getMenuSubMenuId,
    getSubMenuNamesByUrls,
    getCanEdit,
  };

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

const useMenus = () => {
  return useContext(MenuContext);
};

export default useMenus;
