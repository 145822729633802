import { Fragment, useEffect, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Outlet, useNavigate } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import NavigationMenu from './NavigationMenu.js';
import usePages from '../../context/PageContext.js';
import useUsers from '../../context/UsersContext.js';
import useParam from '../../context/ParamContext.js';
import appService from '../../service/app.service.js';
import MessagesDialog from './MessagesDialog.jsx';

export default function Layout() {
  const { getPages, pages } = usePages();
  const { getParam, params } = useParam();
  const { getUser, user } = useUsers();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messagesdialogOpen, setMessagesdialogOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => setSidebarOpen(false), []);

  useEffect(() => {
    getUser();
    getPages();
    getParam();
  }, []);

  const showMessageIcon = params.KANBAN_SHOW_MESSAGE_DIALOG ? JSON.parse(params.KANBAN_SHOW_MESSAGE_DIALOG) : [];

  useEffect(() => {
    if (user?.userId && params.KANBAN_SHOW_MESSAGE_DIALOG && showMessageIcon.find((pr) => pr.kanbanType.length > 0)) {
      getKanbanMessages();
      const intervalId = setInterval(getKanbanMessages, 300000);

      return () => clearInterval(intervalId);
    }
  }, [user?.userId]);

  const getInitials = (name) => {
    if (!name) return '';
    const splitName = name.split(' ');

    const firstInitial = splitName[0] ? splitName[0][0] : '';
    const secondInitial = splitName[1] ? splitName[1][0] : '';

    return (firstInitial + secondInitial).toUpperCase();
  };

  const getKanbanMessages = () => {
    appService.getKanbanMessages(user.userId).then((data) => {
      setMessages(data);
    });
  };

  const logout = () => {
    navigate('/');
  };

  return (
    <div className="h-screen">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>
          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Menü bezárása</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#375570] px-6 pb-2 ring-1 ring-white/10">
                  <nav>
                    <div className="relative my-3 text-white cursor-pointer ml-3">
                      <NavigationMenu setSidebarOpen={setSidebarOpen} />
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div
        className={`sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 px-4 shadow-sm ${params.APP_IS_TEST_VERSION === 'Y' ? 'bg-red-300' : 'bg-white'}`}
      >
        <button type="button" className="-m-2.5 p-2.5 text-gray-700" onClick={() => setSidebarOpen(true)}>
          <span className="sr-only">Menü megnyitása</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="h-6 w-px bg-gray-900/10" aria-hidden="true" />
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <div className="text-labelColor text-xl">{pages.subMenuName}</div>
        </Breadcrumbs>
        {params.APP_IS_TEST_VERSION === 'Y' && <div className="text-center w-full text-5xl">Teszt verzió</div>}
        <div className="absolute inset-0 left-16 right-16 flex items-center justify-center gap-x-4 overflow-auto"></div>
        <div className="absolute right-4 flex items-center gap-10">
          {showMessageIcon && showMessageIcon.find((pr) => pr.kanbanType.length > 0) && (
            <Badge badgeContent={messages.totalMessages} color="primary">
              <MailIcon color="action" className="cursor-pointer" onClick={() => setMessagesdialogOpen(true)} />
            </Badge>
          )}

          {/* @TODO kivenni */}
          <Menu as="div" className="relative">
            <Menu.Button className="-m-1.5 flex items-center p-1.5">
              <span className="sr-only">Profil menü megnyitása</span>
              <Avatar sx={{ bgcolor: '#fde047' }}>{getInitials(user?.nickName)}</Avatar>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <Menu.Item>
                  <button onClick={logout} className="block px-3 py-1 text-sm leading-6 text-gray-900">
                    Kijelentkezés
                  </button>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      <Outlet />
      <MessagesDialog
        open={messagesdialogOpen}
        handleClose={() => {
          setMessagesdialogOpen(false);
        }}
        messages={messages}
      />
    </div>
  );
}
