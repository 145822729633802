import React from 'react';
import { AutoCompleteSelectUI, FormLabelUI } from '../Interface';
import StaticDatePickerUI from '../Interface/StaticDatePickerUI';
import dayjs from 'dayjs';

const CalendarFilter = ({ filter, dates }) => {
  const { filterVal, setFilterVal } = filter;
  const { date, setDate } = dates;
  return (
    <div>
      <div className="w-full">
        <StaticDatePickerUI
          sx={{
            height: 300,
            '& .MuiDatePickerToolbar-root': {
              display: 'none',
            },
            ' & .MuiDateCalendar-root': {
              background: '#f3f4f6',
            },
          }}
          value={dayjs(date)}
          onChange={(newDate) => {
            setDate((prevState) => ({
              ...prevState,
              startDate: newDate ? newDate.startOf('day') : null,
              endDate: newDate ? newDate.endOf('day') : null,
            }));
          }}
        />
      </div>
      <div className="px-2">
        <div className="mt-5">
          <p className="text-xl text-labelColor">Személyek</p>
          <AutoCompleteSelectUI
            selectedValue={filterVal.createdBy}
            label={<FormLabelUI text="Személyek keresése" />}
            onChange={(_event, newVal, _reason) => {
              setFilterVal((prev) => ({
                ...prev,
                createdBy: newVal,
              }));
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            multiple
            freeSolo
            table={'WebUser'}
            listType={{ id: 'userId', name: 'userName' }}
            conditions={[`WebUser.archive = 'N'`]}
          />
        </div>
      </div>
    </div>
  );
};

export default CalendarFilter;
