import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Typography, Chip } from '@material-tailwind/react';

const TodoDialog = (props) => {
  const { open, handleClose, task, colorItem, colorCardBackground } = props;

  return (
    <Dialog open={open} handler={handleClose}>
      <DialogHeader className="bg-labelColor">
        <h2 className="bg-labelColor text-white py-1 px-3 rounded">{task.simpleTaskName}</h2>
      </DialogHeader>
      <DialogBody className="h-96 overflow-y-auto md:h-auto">
        <div className="block grid-cols-4 gap-x-4 gap-y-2 mx-4 md:grid">
          <Typography className="mb-3 ">
            <h4 className="font-semibold">Projekt</h4>
            <p className="text-gray-700">{task.project?.projectName}.</p>
          </Typography>
          <Typography className="mb-3">
            <h4 className="font-semibold">Feladat típusa</h4>
            <p className="text-gray-700">{task.taskType?.itemName}.</p>
          </Typography>
          <Typography className="mb-3">
            <h4 className="font-semibold">Kezdő dátum</h4>
            <p className="text-gray-800">{task.startDate?.split('T')[0]}</p>
          </Typography>
          <Typography className="mb-3">
            <h4 className="font-semibold">Befejező dátum</h4>
            <p className="text-gray-800">{task.endDate?.split('T')[0]}</p>
          </Typography>
          <Typography className="mb-3">
            <h4 className="font-semibold">Státusz</h4>
            <div className="flex">
              <Chip
                variant="ghost"
                value={task.status?.itemName}
                className="rounded-full py-2"
                //color={task.color}
                style={{ background: colorCardBackground?.colorBgCode }}
                size="sm"
              />
            </div>
          </Typography>
          <Typography className="mb-3">
            <h4 className="font-semibold">Priorítás</h4>
            <div className="flex">
              <Chip
                variant="ghost"
                value={task.priority?.itemName}
                className="rounded-full py-2"
                //color={task.color}
                style={{ background: colorItem?.colorCode }}
                size="sm"
              />
            </div>
          </Typography>
          <Typography className="mb-3">
            <h4 className="font-semibold">Készültség</h4>
            <p className="text-gray-800">{task.project?.projectPrepare}%</p>
          </Typography>
          <Typography className="mb-3">
            <h4 className="font-semibold">Felelős</h4>
            <div className="flex">
              <Chip variant="ghost" value={task.responsiblePerson?.nickName} className="rounded-full py-2" size="sm" />
            </div>
          </Typography>
          <Typography className="mb-3 col-span-4">
            <h4 className="font-semibold">Leírás</h4>
            <p className="text-gray-700">{task.simpleTaskDescription}.</p>
          </Typography>

          <Typography className="mb-3 col-span-4">
            <h4 className="font-semibold mb-2">További felelősök</h4>
            <table className="border w-full">
              <thead className="bg-labelColor text-white">
                <tr>
                  <th className="border p-3">További felelősök</th>
                  <th className="border p-3">Szerep</th>
                </tr>
              </thead>
              <tbody>
                {task.simpleTaskRespPersonlist?.map((resp, index) => (
                  <tr key={resp.respPersonListId} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                    <td className="border p-2">{resp.user.userName}</td>
                    <td className="border p-2">{resp.roleName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Typography>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button variant="gradient" color="blue-gray" onClick={handleClose}>
          <span>Bezárás</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default TodoDialog;
