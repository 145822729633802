import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import _ from 'lodash';
import dayjs from 'dayjs';
import Yup from '../../../utils/yup';
import utc from 'dayjs/plugin/utc';

import addNotification from '../../../utils/addNotification';
import { NotificationType } from '../../../config';
import useMenus from '../../../context/MenuContext';
import masterDataService from '../../../service/masterData.service';
import useUsers from '../../../context/UsersContext';
import resourceThreeService from '../../../service/resources/resourceThree.service';

import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI } from '../../Interface';
import ResourceThreeReports from './ResourceThreeReports';
import { ResourceThreeFields } from './ResourceThreeFields';

export const ResourceThreePage = () => {
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getUser, user } = useUsers();
  const [refetchTable, setRefetchTable] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [canEdit, setCanEdit] = useState(true);

  const [defaultValues, setDefaultValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [initValues, setInitValues] = useState({});
  const [autocompleteData, setAutocompleteData] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [submenu, setSubmenu] = useState(null);

  useEffect(() => {
    getMenus();
    getUser();

    const def = {
      name: '',
      groupId: null,
      active: 1,
      statusId: null,
      typeId: null,
    };

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          statusId: autocompleteData?.status?.defaultValue ?? null,
          typeId: autocompleteData?.type?.defaultValue ?? null,
          groupId: autocompleteData?.resourceGroup?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          status: autocompleteData?.status?.defItem,
          type: autocompleteData?.type?.defItem,
          resourceGroup: autocompleteData?.resourceGroup?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('resourceThree');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (submenu) initDefaultValues();
  }, [submenu]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .typeToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .statusToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .groupToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          type: resp[0],
          status: resp[1],
          resourceGroup: resp[2],
        };
        setAutocompleteData(aData);
      })
      .finally(() => {
        //hideLoader();
      });
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    groupId: Yup.string().nullable(),
    active: Yup.number().nullable(),
    statusId: Yup.string().nullable(),
    typeId: Yup.string().nullable(),
  });

  const valuesToForm = () => {
    const { name, groupId, active, statusId, typeId } = selectedValues;

    setInitValues({
      name,
      groupId,
      active,
      statusId,
      typeId,
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values, resetForm);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    resourceThreeService.updateResource(updData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = async (values, resetForm) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
    };

    resourceThreeService
      .createResource(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        resetForm();
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    resourceThreeService.deleteResource(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultSelectedValues);
    });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-6 h-screen gap-2 p-4 bg-gray-100 mt-[-4rem] pt-[5rem]">
      <div className="col-span-1 lg:col-span-1 xl:col-span-2 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="h-full overflow-auto custom-scrollbar">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues(defaultSelectedValues);
              setViewField(false);
              setAddField(false);
            }}
            addDisabled={!viewField || !canEdit}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled || !canEdit}
            copyClick={() => {
              setViewField(false);
              setAddField(false);
            }}
            copyDisabled={!viewField || operatorsDisabled || !canEdit}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
            //validateOnMount={false}
          >
            {({ values, setFieldValue, errors, validateField, resetForm }) => (
              <Form>
                <ResourceThreeFields
                  viewField={viewField}
                  submenuId={submenu}
                  selectedValues={selectedValues}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  validateField={validateField}
                />

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);

                      resetForm();
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="col-span-1 lg:col-span-1 xl:col-span-4 overflow-hidden bg-white shadow sm:rounded-lg  ">
        <div className="h-full overflow-auto custom-scrollbar">
          <ResourceThreeReports
            refetch={{ refetchTable, setRefetchTable }}
            values={{ selectedValues, setSelectedValues }}
            viewField={viewField}
            operatorsDisabled={operatorsDisabled}
            submenuId={submenu}
          />
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </div>
  );
};
