import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';

import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';

import useMenus from '../../context/MenuContext';
import { FormButtonPanelUI, FormHeaderUI } from '../Interface';
import useUsers from '../../context/UsersContext';
import userService from '../../service/user.service';
import { UserProfileFields } from './UserProfileFields';

export const UserProfilePage = () => {
  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmedNewPassword: '',
  };
  const { getUser, user } = useUsers();
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [initValues, setInitValues] = useState({});

  useEffect(() => {
    setInitValues(defaultValues);
    getUser();
  }, []);

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(),
    newPassword: Yup.string().required(),
    confirmedNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Az új jelszavak nem egyeznek!')
      .required(),
  });

  const updateObject = async (values, { resetForm }) => {
    const updData = {
      ...values,
    };

    userService.updatePassword(updData, user.userId).then((_user) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setEditButtonvisible(true);
      resetForm();
      setViewField(true);
    });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-6 h-screen gap-2 p-4 bg-gray-100 mt-[-4rem] pt-[5rem]">
      <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="h-full overflow-auto custom-scrollbar">
          <FormHeaderUI
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={updateObject}
            enableReinitialize
          >
            {({ values, errors, resetForm }) => (
              <Form>
                <UserProfileFields viewField={viewField} values={values} errors={errors} />

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      resetForm();
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
