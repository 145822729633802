import { useState } from 'react';
import FormTable from './FormTable';
import TabsUI from '../Interface/TabsUI';

export default function FormTabs(props) {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const tabs = [
    {
      id: 0,
      name: 'Form elemek',
      value: 'formelemek',
      content: (
        <FormTable
          itemId={props.itemId}
          projectGroupStatusId={props.projectGroupStatusId}
          disabled={props.disabled}
          projectGroupList={props.projectGroupList}
          setprojectGroupList={props.setprojectGroupList}
          setRefetchTable={props.setRefetchTable}
          viewField={props.viewField}
          selectedValues={props.selectedValues}
        />
      ),
    },
  ];

  return (
    <div className="my-4 mt-6">
      <div className="px-3">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {tabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </div>
    </div>
  );
}
