export const months = [
  { value: 1, label: 'Január' },
  { value: 2, label: 'Február' },
  { value: 3, label: 'Március' },
  { value: 4, label: 'Április' },
  { value: 5, label: 'Május' },
  { value: 6, label: 'Június' },
  { value: 7, label: 'Július' },
  { value: 8, label: 'Augusztus' },
  { value: 9, label: 'Szeptember' },
  { value: 10, label: 'Október' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];
