import React, { createContext, useContext, useReducer } from 'react';
import { paramReducer } from '../reducer/paramReducer';

const ParamContext = createContext({});

export const ParamProvider = ({ children }) => {
  const [state, dispatch] = useReducer(paramReducer, {});

  const setParam = (params) => {
    return dispatch({ type: 'SET', payload: params });
  };

  const getParam = () => {
    return dispatch({ type: 'GET' });
  };

  const value = {
    params: state,
    setParam,
    getParam,
  };

  return <ParamContext.Provider value={value}>{children}</ParamContext.Provider>;
};

const useParam = () => {
  return useContext(ParamContext);
};

export default useParam;
