import React, { useMemo, useEffect, useCallback } from 'react';
import { Field, useFormikContext } from 'formik';

import { getDynVal } from '../../utils/DynHelper';
import { InputUI } from '../Interface';

const DynTextInput = (props) => {
  const { name, disabled, selectedValues } = props;
  const { setFieldValue } = useFormikContext();
  const dynVal = useMemo(
    () => getDynVal(selectedValues.dynamicProperties, name),
    [selectedValues.dynamicProperties, name]
  );

  useEffect(() => {
    const setterVal = dynVal?.textVal || dynVal?.numberVal || dynVal?.longTextVal || '';

    setFieldValue(name, setterVal);
  }, [dynVal, name, setFieldValue]);

  const handleChange = useCallback(
    (e) => {
      setFieldValue(name, e.target.value);
    },
    [name, setFieldValue]
  );
  return (
    <Field
      name={name}
      as={InputUI}
      fullWidth
      variant="standard"
      disabled={disabled}
      onChange={handleChange}
      {...props}
    />
  );
};

export default DynTextInput;
