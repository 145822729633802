import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';

import Yup from '../../../utils/yup';
import addNotification from '../../../utils/addNotification';
import { NotificationType } from '../../../config';
import { DialogUI, ButtonUI, FormLabelUI, FormErrorUI, InputUI, CheckboxUI } from '../../Interface';
import biFeedbackService from '../../../service/biFeedback.service';

const FeedbackDialog = ({ show, onHide, selectedValues, biReportId, setRefresh }) => {
  const [formData, setFormData] = useState({});

  const defaultValues = {
    cardCode: '',
    cardName: '',
    itemCode: '',
    itemName: '',
    comment: '',
    show: false,
  };

  useEffect(() => {
    setFormData(defaultValues);
  }, []);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!selectedValues.hasOwnProperty('feedbackId')) {
        onHide();
        addNotification({
          content: 'Hiányzó oszlop a riportból! (feedbackId)',
          type: NotificationType.WARNING,
        });
      } else if (!selectedValues.hasOwnProperty('Utolso_szamla_datum')) {
        onHide();
        addNotification({
          content: 'Hiányzó oszlop a riportból! (Utolso_szamla_datum)',
          type: NotificationType.WARNING,
        });
      } else {
        const fetchFeedback = async () => {
          const feedback = selectedValues.feedbackId
            ? await biFeedbackService.getBiFeedback(selectedValues.feedbackId)
            : {};

          setFormData({
            cardCode: selectedValues.cardcode,
            cardName: selectedValues.AliasName,
            itemCode: selectedValues.ItemCode,
            itemName: selectedValues.ItemName,
            comment: feedback.comment ?? '',
            show: feedback.show ?? false,
          });
        };

        fetchFeedback();
      }
    }
  }, [selectedValues]);

  const validationSchema = Yup.object().shape({
    cardCode: Yup.string().required(),
    cardName: Yup.string().required(),
    itemCode: Yup.string().required(),
    itemName: Yup.string().required(),
    comment: Yup.string().required(),
    show: Yup.boolean(),
  });

  const handleSave = (values) => {
    const insVal = {
      ...values,
      id: selectedValues.feedbackId ?? undefined,
      biReportId,
      docDate: selectedValues.Utolso_szamla_datum,
    };

    biFeedbackService.saveBiFeedback(insVal).then((data) => {
      //@TODO refresh table
      setRefresh(true);
      onHide();
      addNotification({
        content: 'Sikeres mentés!',
        type: NotificationType.SUCCESS,
      });
    });
  };

  const handleDelete = () => {
    biFeedbackService.deleteBiFeedBack(selectedValues.feedbackId).then(() => {
      setRefresh(true);
      onHide();
    });
  };

  return (
    <DialogUI open={show} headerContent="Feedback" fullWidth={true}>
      <div className=" text-center">
        <div className="flex mt-2 gap-10 items-center justify-center z-50">
          <Formik
            initialValues={formData}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleSave(values);
            }}
          >
            {({ errors, values, setFieldValue, validateField }) => (
              <Form>
                <div className="grid grid-cols-3 gap-x-4 mx-4 mb-5">
                  <div className="col-span-1 ">
                    <Field
                      type="text"
                      name="cardCode"
                      as={InputUI}
                      fullWidth
                      label={<FormLabelUI text="Üp. kód" />}
                      variant="standard"
                      helperText={<FormErrorUI message={errors.cardCode} />}
                      InputLabelProps={{
                        shrink: values.cardCode !== '',
                      }}
                      disabled
                    />
                  </div>
                  <div className="col-span-2 ">
                    <Field
                      type="text"
                      name="cardName"
                      as={InputUI}
                      fullWidth
                      label={<FormLabelUI text="Üp. név" />}
                      variant="standard"
                      helperText={<FormErrorUI message={errors.cardName} />}
                      InputLabelProps={{
                        shrink: values.cardName !== '',
                      }}
                      disabled
                    />
                  </div>
                  <div className="col-span-1 ">
                    <Field
                      type="text"
                      name="itemCode"
                      as={InputUI}
                      fullWidth
                      label={<FormLabelUI text="Cikkszám" />}
                      variant="standard"
                      helperText={<FormErrorUI message={errors.itemCode} />}
                      InputLabelProps={{
                        shrink: values.itemCode !== '',
                      }}
                      disabled
                    />
                  </div>
                  <div className="col-span-2 ">
                    <Field
                      type="text"
                      name="itemName"
                      as={InputUI}
                      fullWidth
                      label={<FormLabelUI text="Cikknév" />}
                      variant="standard"
                      helperText={<FormErrorUI message={errors.itemCode} />}
                      InputLabelProps={{
                        shrink: values.itemCode !== '',
                      }}
                      disabled
                    />
                  </div>

                  <div className="col-span-2 ">
                    <Field
                      type="text"
                      name="comment"
                      as={InputUI}
                      fullWidth
                      label={<FormLabelUI text="Megjegyzés" />}
                      variant="standard"
                      helperText={<FormErrorUI message={errors.comment} />}
                      InputLabelProps={{
                        shrink: values.comment !== '',
                      }}
                      multiline
                      rows={3}
                    />
                  </div>
                  <div className="col-span-1 pt-2">
                    <Field
                      name="show"
                      type="checkbox"
                      as={CheckboxUI}
                      fullWidth
                      label={<FormLabelUI text="Megjelenik" />}
                      checked={values.show}
                      onChange={async (e) => {
                        setFieldValue(`show`, e.target.checked).then(() => {
                          validateField(`show`);
                        });
                      }}
                    />
                    {selectedValues.feedbackId && (
                      <ButtonUI
                        text="Törlés"
                        className="bg-danger"
                        type="Button"
                        onClick={() => handleDelete()}
                      ></ButtonUI>
                    )}
                  </div>
                </div>

                <div className="px-4 mt-8 flex justify-center gap-3">
                  <ButtonUI
                    text={selectedValues.feedbackId ? 'Módosítás' : 'Hozzáadás'}
                    className="bg-success"
                    type="submit"
                  ></ButtonUI>
                  <ButtonUI
                    text="Mégse"
                    className=""
                    color="blue-gray"
                    type="button"
                    variant="gradient"
                    onClick={onHide}
                  ></ButtonUI>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </DialogUI>
  );
};

export default FeedbackDialog;
