import React, { useEffect, useState } from 'react';
import productionService from '../../service/production.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import { ButtonUI, FormLabelUI, InputUI } from '../Interface';

export const ProductionSearch = ({ setProductionList, refetch, filter }) => {
  const { refetchTable, setRefetchTable } = refetch;
  const { filterFormState, setFilterFormState } = filter;

  useEffect(() => {
    if (refetchTable && filterFormState.searchQuery) {
      handleSearch();
    }
  }, [refetchTable]);

  const handleSearch = () => {
    if (!filterFormState.searchQuery) {
      addNotification({
        content: 'A keresőmező kitöltése kötelező!',
        type: NotificationType.WARNING,
      });
      return;
    }

    productionService.getProductionInfo({ searchQuery: filterFormState.searchQuery }).then((data) => {
      if (Array.isArray(data) && data.length === 0) {
        setProductionList([]);
        addNotification({
          content: 'Nincs találat!',
          type: NotificationType.WARNING,
        });
      } else if (Array.isArray(data) && data.length <= 1) {
        setProductionList(data[0]);
      } else {
        setProductionList(data);
      }
      setRefetchTable(false);
    });
  };

  return (
    <div>
      <h2 className="text-2xl text-labelColor">Gyártás kereső</h2>
      <div className="flex gap-5 w-1/4 mb-10 items-center">
        <InputUI
          type="text"
          variant="standard"
          label={<FormLabelUI text="Munkaszám és / vagy művelet azon." />}
          value={filterFormState.searchQuery}
          onChange={(e) => setFilterFormState({ ...filterFormState, searchQuery: e.target.value })}
        />
        <div>
          <ButtonUI className="bg-success" text="Betöltés" onClick={handleSearch} />
        </div>
      </div>
    </div>
  );
};
