import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { set } from 'lodash';

import dashboardService from '../../../service/dashboard.service';
import { DashboardTypeList } from '../../../config/dashboard';
import { AutoCompleteSelectUI, FormLabelUI, SelectUI, DatePickerUI } from '../../Interface';
import BiReport from '../BiReport/BiReport';
import useUsers from '../../../context/UsersContext';
import useParam from '../../../context/ParamContext';
import { generateYears } from '../../../utils/dateHelper';
import { months } from '../../../config/months';

const Dashboard = () => {
  const { dashboardId } = useParams();
  const { getUser, user } = useUsers();
  const { getParam, params } = useParam();

  const [selectedDashboard, setSelectedDashboard] = useState({});
  const [dashboardType, setDashboardType] = useState({});
  const [yearVals, setYearVals] = useState([]);
  const [businessPartners, setBusinessPartners] = useState([]);
  const [responsibles, setResponsibles] = useState([]);
  const [autocompleteData, setAutocompleteData] = useState({
    businessPartners: [],
    responsibles: [],
  });

  const [filter, setFilter] = useState({});
  const [gridCount, setGridCount] = useState(1);

  const getDashboard = () => {
    dashboardService.getDashboard(dashboardId).then((data) => {
      setSelectedDashboard(data);
    });
  };

  const getBusinessPartners = () => {
    dashboardService.getBusinessPartners(user?.userSalesPerson?.slpCode).then((data) => {
      setBusinessPartners(data);
    });
  };

  const getResponsibles = () => {
    //@TODO responsible type if necessary
    dashboardService.getResponsibles('SALES').then((data) => {
      setResponsibles(data);
    });
  };

  const changeFilter = (key, value) => {
    setFilter((prev) => {
      const newFilter = { ...prev };
      set(newFilter, key, value ?? '');
      return newFilter;
    });
  };

  const defaultFilter = {
    businessPartner: null,
    responsible: null,
    year: '',
    quarter: '',
    month: '',
    interval: {
      from: null,
      to: null,
    },
  };

  useEffect(() => {
    getUser();
    getParam();
    setFilter(defaultFilter);
  }, []);

  useEffect(() => {
    setYearVals(generateYears(params.DASHBOARFILTER_MIN_YEAR ?? 2021));
  }, [params]);

  useEffect(() => {
    if (Object.keys(user).length > 0) getBusinessPartners();
  }, [user]);

  useEffect(() => {
    if (businessPartners.length > 0) {
      const bpAutocompleteData = businessPartners.map((bp) => ({
        label: `${bp.companyId} - ${bp.companyName}`,
        value: bp.companyName,
      }));

      setAutocompleteData((prev) => {
        return { ...prev, businessPartners: bpAutocompleteData };
      });
    } else {
      setAutocompleteData((prev) => {
        return { ...prev, businessPartners: [] };
      });
    }
  }, [businessPartners]);

  useEffect(() => {
    if (responsibles.length > 0) {
      const responsiblesData = responsibles.map((responsible) => ({
        label: `${responsible.responsibleCode} - ${responsible.responsibleName}`,
        value: responsible.responsibleCode,
      }));

      setAutocompleteData((prev) => {
        return { ...prev, responsibles: responsiblesData };
      });
    } else {
      setAutocompleteData((prev) => {
        return { ...prev, responsibles: [] };
      });
    }
  }, [responsibles]);

  useEffect(() => {
    getDashboard();
    setFilter(defaultFilter);
  }, [dashboardId]);

  useEffect(() => {
    if (Object.keys(selectedDashboard).length > 0) {
      const findedDashboardType = DashboardTypeList.find((dt) => dt.value === selectedDashboard.type);
      setDashboardType(findedDashboardType);
      const { bpFilter, yearFilter, quarterFilter, monthFilter, intervalFilter, responsibleFilter } = selectedDashboard;

      let filterCount = [bpFilter, yearFilter, quarterFilter, monthFilter, intervalFilter, responsibleFilter].filter(
        Boolean
      ).length;
      if (intervalFilter) filterCount++;
      setGridCount(filterCount);

      if (selectedDashboard.responsibleFilter) getResponsibles();
    }
  }, [selectedDashboard]);

  return (
    <>
      <div className="bg-gray-100 w-full px-3 md:fixed z-40">
        <div
          className={`absolute -top-14 right-[100px] grid gap-x-4 mx-4 mb-5 w-[1100px] justify-end`}
          style={{ gridTemplateColumns: `repeat(${gridCount}, minmax(0, 250px))` }}
        >
          {selectedDashboard?.bpFilter && (
            <div className="col-span-1 max-w-[300px]">
              <AutoCompleteSelectUI
                label={<FormLabelUI text="Üp." />}
                variant="standard"
                onChange={(_e, newVal) => {
                  changeFilter('businessPartner', newVal);
                }}
                value={filter?.businessPartner ?? null}
                dataset={autocompleteData.businessPartners}
                autoFocus
                limitTags={1}
              />
            </div>
          )}

          {selectedDashboard?.responsibleFilter && (
            <div className="col-span-1">
              <AutoCompleteSelectUI
                label={<FormLabelUI text="Felelős" />}
                variant="standard"
                onChange={(_e, newVal) => {
                  changeFilter('responsible', newVal ?? null);
                }}
                value={filter?.responsible}
                dataset={autocompleteData.responsibles}
                autoFocus
                limitTags={1}
              />
            </div>
          )}

          {selectedDashboard?.yearFilter && (
            <div className="col-span-1">
              <SelectUI
                label={<FormLabelUI text="Év" />}
                option={yearVals}
                value={filter.year}
                onChange={(e) => {
                  const value = e.target.value;
                  changeFilter('year', value);
                }}
                onClear={() => {
                  changeFilter('year', '');
                }}
              />
            </div>
          )}
          {selectedDashboard?.quarterFilter && (
            <div className="col-span-1">
              <SelectUI
                label={<FormLabelUI text="Negyedév" />}
                option={[1, 2, 3, 4]}
                value={filter.quarter}
                onChange={(e) => {
                  const value = e.target.value;
                  changeFilter('quarter', value);
                }}
                onClear={() => {
                  changeFilter('quarter', '');
                }}
              />
            </div>
          )}
          {selectedDashboard?.monthFilter && (
            <div className="col-span-1">
              <SelectUI
                label={<FormLabelUI text="Hónap" />}
                option={months}
                value={filter.month}
                onChange={(e) => {
                  const value = e.target.value;
                  changeFilter('month', value);
                }}
                onClear={() => {
                  changeFilter('month', '');
                }}
              />
            </div>
          )}
          {selectedDashboard?.intervalFilter && (
            <>
              <div className="col-span-1">
                <DatePickerUI
                  label={<FormLabelUI text="Kezdő dátum" />}
                  value={filter.interval.from}
                  onChange={(newDate) => {
                    changeFilter('interval.from', newDate ? newDate.startOf('day') : null);
                  }}
                />
              </div>
              <div className="col-span-1">
                <DatePickerUI
                  label={<FormLabelUI text="Vége dátum" />}
                  value={filter.interval.to}
                  onChange={(newDate) => {
                    changeFilter('interval.to', newDate ? newDate.startOf('day') : null);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className={`grid grid-cols-${dashboardType?.column} overflow-auto gap-1 px-4 bg-gray-100 mt-[-3rem] pt-[3rem] h-full custom-scrollbar`}
      >
        {selectedDashboard?.dashboardReport?.map((report) => {
          return (
            <div
              className="h-[450px] col-span-1 overflow-hidden bg-white shadow sm:rounded-lg  "
              style={{ height: `${800 / dashboardType?.row + 50}px` }}
              key={uuidv4()}
            >
              <BiReport
                reportId={report.biReportId}
                height={800 / dashboardType?.row}
                filter={filter}
                dashboardData={selectedDashboard}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Dashboard;
