import React from 'react';
import { Field } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI } from '../Interface';

const FormFields = ({ viewField, values, errors }) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
      <div className="col-span-4 ">
        <Field
          type="text"
          name="name"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Telephely neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.name !== '' }}
        />
      </div>
    </div>
  );
};

export default FormFields;
