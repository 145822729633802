import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'operation';

const operationService = {
  createOperation: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  getOperations: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/operations`,
    });
  },

  getOperationsReport: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/operations`,
      data,
    });
  },

  updateOperation: (data, operationId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${operationId}`,
      data,
    });
  },

  deleteOperation: (operationId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${operationId}`,
    });
  },
};

export default operationService;
