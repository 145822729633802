export const menuReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET':
      localStorage.setItem('menus', JSON.stringify(payload));
      return payload;
    case 'GET':
      return JSON.parse(localStorage.getItem('menus'));
    default:
      return state;
  }
};
