import { Button, Dialog, DialogHeader, DialogBody, DialogFooter, Typography, Chip } from '@material-tailwind/react';

/*

{
    "prodOperationId": 26,
    "productionId": 8,
    "operationId": 12,
    "timeSpent": "1",
    "startDate": "2024-06-07T08:52:00.000Z",
    "endDate": "2024-06-07T11:52:00.000Z",
    "machineId": 27,
    "label": "GYU00005-4",
    "description": null,
    "archive": "N",
    "operation": {
        "operationId": 12,
        "operationName": "Utómunka",
        "archive": "N"
    },
    "machine": {
        "machineId": 27,
        "machineName": "EEN 500/1000 CNC ESZTERGA",
        "archive": "N",
        "machineColor": null,
        "machineIcon": null,
        "machineTypeId": 1,
        "active": 1
    },
    "productionReport": [],
    "production": {
        "productionId": 8,
        "customerOrdNumber": "VRE0005",
        "projectId": null,
        "articleNumber": "A5-245",
        "articleName": "A5-245",
        "productionQuantity": null,
        "me": 1,
        "archive": "N",
        "statusId": 15,
        "userId": 1,
        "companyId": 2,
        "endDate": "2024-06-07T07:19:53.000Z",
        "startDate": "2024-06-07T06:19:53.000Z",
        "workingNumber": "GYU00005",
        "description": "",
        "project": null,
        "status": {
            "itemId": 15,
            "itemName": "Engedélyezett",
            "statusId": 3,
            "archive": "N",
            "color": "blue",
            "visOrder": null,
            "onKanban": false
        },
        "company": {
            "companyId": 2,
            "companyName": "Lajosmizsei Folplast kft.",
            "archive": "N",
            "billPostcode": "",
            "billCity": "",
            "billAddress": "",
            "postCode": "",
            "city": "",
            "street": "",
            "companyDescription": ""
        },
        "quantityUnit": {
            "quantityUnitId": 1,
            "quantityUnit": "db",
            "quantityUnitName": "darab",
            "archive": "Y"
        },
        "productionReports": [],
        "productionOperation": [
            {
                "prodOperationId": 23,
                "productionId": 8,
                "operationId": 10,
                "timeSpent": "1",
                "startDate": "2024-06-07T14:22:00.000Z",
                "endDate": "2024-06-07T18:22:00.000Z",
                "machineId": 29,
                "label": "GYU00005-1",
                "description": null,
                "archive": "N"
            },
            {
                "prodOperationId": 24,
                "productionId": 8,
                "operationId": 14,
                "timeSpent": "1",
                "startDate": "2024-06-07T10:52:00.000Z",
                "endDate": "2024-06-07T13:22:00.000Z",
                "machineId": 28,
                "label": "GYU00005-2",
                "description": null,
                "archive": "N"
            },
            {
                "prodOperationId": 25,
                "productionId": 8,
                "operationId": 12,
                "timeSpent": "1",
                "startDate": "2024-06-07T10:22:00.000Z",
                "endDate": null,
                "machineId": 46,
                "label": "GYU00005-3",
                "description": null,
                "archive": "N"
            },
            {
                "prodOperationId": 26,
                "productionId": 8,
                "operationId": 12,
                "timeSpent": "1",
                "startDate": "2024-06-07T08:52:00.000Z",
                "endDate": "2024-06-07T11:52:00.000Z",
                "machineId": 27,
                "label": "GYU00005-4",
                "description": null,
                "archive": "N"
            },
            {
                "prodOperationId": 27,
                "productionId": 8,
                "operationId": 10,
                "timeSpent": "1",
                "startDate": "2024-06-07T08:52:00.000Z",
                "endDate": "2024-06-07T12:22:00.000Z",
                "machineId": 29,
                "label": "GYU00005-5",
                "description": null,
                "archive": "N"
            },
            {
                "prodOperationId": 28,
                "productionId": 8,
                "operationId": 7,
                "timeSpent": "1",
                "startDate": "2024-06-07T08:53:00.000Z",
                "endDate": "2024-06-07T12:23:00.000Z",
                "machineId": 37,
                "label": "GYU00005-6",
                "description": null,
                "archive": "N"
            }
        ],
        "productionAttachment": [],
        "user": {
            "userId": 1,
            "userName": "TestUser",
            "roleId": 1,
            "archive": "N",
            "nickName": "Tesztelő",
            "roles": {
                "roleId": 1,
                "roleName": "Admin"
            }
        }
    }
}

*/

const ProductDialog = (props) => {
  const { open, handleClose, production } = props;

  return (
    <Dialog open={open} handler={handleClose}>
      <DialogHeader className="bg-labelColor">
        <h2 className="bg-labelColor text-white py-1 px-3 rounded">{production?.production?.workingNumber}</h2>
      </DialogHeader>
      <DialogBody className="h-96 overflow-y-auto md:h-auto">
        <div className="block grid-cols-4 gap-x-4 gap-y-2 mx-4 md:grid">
          {/*<Typography className="mb-3 ">
            <h4 className="font-semibold">Projekt</h4>
            <p className="text-gray-700">{production?.production?.project?.projectName}.</p>
  </Typography>*/}
          <Typography className="mb-3">
            <h4 className="font-semibold">Művelet</h4>
            <p className="text-gray-700">{production?.operation?.operationName}.</p>
          </Typography>
          <Typography className="mb-3">
            <h4 className="font-semibold">Kezdő dátum</h4>
            <p className="text-gray-800">{production?.production?.startDate?.split('T')[0]}</p>
          </Typography>
          <Typography className="mb-3">
            <h4 className="font-semibold">Befejező dátum</h4>
            <p className="text-gray-800">{production?.production?.endDate?.split('T')[0]}</p>
          </Typography>
          <Typography className="mb-3">
            <h4 className="font-semibold">Státusz</h4>
            <div className="flex">
              <Chip
                variant="ghost"
                value={production?.production?.status?.itemName}
                className="rounded-full py-2"
                //color={production.color}
                style={{}}
                size="sm"
              />
            </div>
          </Typography>

          <Typography className="mb-3 col-span-4">
            <h4 className="font-semibold">Gyártás leírás</h4>
            <p className="text-gray-700">{production?.production?.description}</p>
          </Typography>

          <Typography className="mb-3 col-span-4">
            <h4 className="font-semibold">Művelet leírás</h4>
            <p className="text-gray-700">{production?.description}</p>
          </Typography>

          {/*<Typography className="mb-3 col-span-4">
            <h4 className="font-semibold mb-2">További felelősök</h4>
            <table className="border w-full">
              <thead className="bg-labelColor text-white">
                <tr>
                  <th className="border p-3">További felelősök</th>
                  <th className="border p-3">Szerep</th>
                </tr>
              </thead>
              <tbody>
                {production.productionOperation?.map((resp, index) => (
                  <tr key={resp.prodOperationId} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                    <td className="border p-2">{resp.user.userName}</td>
                    <td className="border p-2">{resp.roleName}</td>
                  </tr>
                ))}
              </tbody>
            </table>
              </Typography> */}
        </div>
      </DialogBody>
      <DialogFooter>
        <Button variant="gradient" color="blue-gray" onClick={handleClose}>
          <span>Bezárás</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default ProductDialog;
