import { useState, useEffect } from 'react';

import { generateFilter, dataGridColumns } from '../../utils/helper';
import { DatePickerUI, DataGridUI, DataGridIdentifiers, FormLabelUI, DebouncedInputUI } from '../Interface';
import productionService from '../../service/production.service';

const ProductionReports = ({ refetch, values, viewField, operatorsDisabled }) => {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [{ field: 'startDate', sort: 'desc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });
  const [filterFormState, setFilterFormState] = useState({
    articleName: '',
    startDate: null,
    endDate: null,
    statusId: null,
  });

  const conditions = {
    like: ['articleName'],
    gte: ['startDate'],
    lte: ['endDate'],
  };

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'archive',
        value: 'N',
      },
    ];

    const newFilter = generateFilter(filterFormState, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
  }, [filterFormState]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getList();
    }
  }, [query]);

  useEffect(() => {
    if (refetchTable && query.filter?.length > 0) {
      getList();
    }
  }, [refetchTable]);

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const onPaginitonChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  const getList = () => {
    productionService.getProductions(query).then((data) => {
      setReports(data);
      setRefetchTable(false);
    });
  };

  const columns = dataGridColumns([
    {
      field: 'workingNumber',
      headerName: 'Munkaszám',
      flex: 1,
    },
    {
      field: 'customerOrdNumber',
      headerName: 'Vevői rendelés szám',
      flex: 1,
    },
    {
      field: 'articleNumber',
      headerName: 'Cikkszám',
      flex: 1,
    },
    {
      field: 'articleName',
      headerName: 'Cikknév',
      flex: 1,
    },
  ]);

  return (
    <div className="col-span-2 flex flex-col overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="col h-full flex-col overflow-hidden bg-white p-2 shadow sm:rounded-lg">
        <div className="flex h-full overflow-hidden border-t border-gray-200">
          <div className="w-full overflow-x-auto xxl:overflow-x-hidden">
            <div className="sticky top-0 bg-white">
              <div className="pt-2 ">
                <div className="grid grid-cols-4 gap-x-4 mx-4">
                  <div className="col-span-2 md:col-span-1">
                    <DatePickerUI
                      label={<span className="text-labelColor">Gyártás tervezett kezdete</span>}
                      value={filterFormState.startDate}
                      onChange={(newDate) => {
                        setFilterFormState((prevState) => ({
                          ...prevState,
                          startDate: newDate ? newDate.startOf('day') : null,
                        }));
                      }}
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <DatePickerUI
                      label={<span className="text-labelColor">Gyártás tervezett vége</span>}
                      value={filterFormState.endDate}
                      onChange={(newDate) => {
                        setFilterFormState((prevState) => ({
                          ...prevState,
                          endDate: newDate ? newDate.endOf('day') : null,
                        }));
                      }}
                    />
                  </div>

                  <div className="col-span-2 md:col-span-1">
                    <DebouncedInputUI
                      label={<FormLabelUI text="Cikknév" />}
                      debounceMs={800}
                      setQuickFilterSearchValue={(newValue) => {
                        setFilterFormState((prevState) => ({
                          ...prevState,
                          articleName: newValue,
                        }));
                      }}
                      quickFilterSearchValue={filterFormState.articleName}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-block min-w-full border-b border-gray-200 align-middle mt-4 overflow-y-auto overflow-x-auto ">
              <div className="z-40 min-w-full h-[100%]">
                <DataGridUI
                  datagridIdentifier={DataGridIdentifiers.ProductionReports}
                  initialState={query}
                  rows={reports.rows}
                  rowCount={reports.rowCount}
                  columns={columns}
                  onRowClick={(rowData) => {
                    if (!viewField) return;
                    setSelectedValues(rowData.row);
                  }}
                  paginationMode="server"
                  onPaginationModelChange={onPaginitonChange}
                  sortingMode="server"
                  onSortModelChange={handleSortModelChange}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                  rowSelectionModel={rowSelectionModel}
                  disableRowSelectionOnClick={!viewField}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductionReports;
