import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'bi/dashboard';

const dashboardService = {
  getDashboard(id) {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/${id}`,
    });
  },

  upsertDashboard(data) {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  getDashboardList(data) {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/list`,
      data,
    });
  },

  allDashboards: () => {
    return request({
      resource: `${baseURL}/allDashboards`,
    });
  },

  getBusinessPartners: (slpCode) => {
    return request({
      resource: `${baseURL}/businessPartners/${slpCode ?? ''}`,
    });
  },

  deleteDashboard: (id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
    });
  },

  getResponsibles: (type) => {
    return request({
      resource: `${baseURL}/responsibles/${type}`,
    });
  },
};

export default dashboardService;
