import { useEffect, useState } from 'react';

import appService from '../../service/app.service';
import RoleModify from './RoleModify';
import { ButtonUI, DebouncedInputUI } from '../Interface';

export default function RoleInformation({ roleState }) {
  const { roleId } = roleState;
  const [roleSubMenuPermissions, setRoleSubMenuPermissions] = useState([]);
  const [allSubMenus, setAllSubMenus] = useState([]);
  const [roleModalShow, setRoleModalShow] = useState(false);
  const [roleHasPermission, setRoleHasPermission] = useState(false);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [refreshPage, setRefreshPage] = useState(false);
  const [filter, setFilter] = useState({
    subMenuName: '',
  });

  useEffect(() => {
    fetchAllSubMenus();
  }, []);

  const fetchData = async () => {
    if (!roleId) {
      return;
    }
    appService
      .getRoleInformation(roleId)
      .then((data) => {
        fetchRoleSubMenuPermissions(data[0]?.roleId);
      })
      .finally(() => {});
  };

  const fetchRoleSubMenuPermissions = async () => {
    appService
      .getUserSubMenuPermissions(roleId)
      .then((data) => {
        setRoleSubMenuPermissions(data);
      })
      .finally(() => {});
  };

  const fetchAllSubMenus = async () => {
    appService
      .getAllSubMenus()
      .then((data) => {
        setAllSubMenus(data);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchData();
  }, [roleId]);

  useEffect(() => {
    fetchData();
  }, [refreshPage]);

  return (
    <>
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 max-h-[calc(100vh-10rem)] overflow-y-auto -mt-[2rem] custom-scrollbar">
        <div>
          <div className="w-[250px] mb-3">
            <DebouncedInputUI
              label="Keresés"
              debounceMs={800}
              quickFilterSearchValue={filter?.subMenuName}
              setQuickFilterSearchValue={(newValue) => {
                setFilter((prevState) => ({
                  ...prevState,
                  subMenuName: newValue,
                }));
              }}
            />
          </div>
          <table className="mt-2 min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Menüpont
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Jogosult
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Szerkesztheti
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                ></th>
              </tr>
            </thead>
            <tbody>
              {allSubMenus
                .filter((subMenu) => subMenu.subMenuName.toLowerCase().includes(filter.subMenuName.toLowerCase()))
                .map((subMenu, index) => (
                  <tr key={index} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                    <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                      {subMenu.subMenuName}
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                      {roleSubMenuPermissions.find((permission) =>
                        permission.roleSubmenuPermission.some(
                          (rolePerm) => rolePerm.roleId === roleId && permission.subMenuName === subMenu.subMenuName
                        )
                      )
                        ? 'Jogosult'
                        : 'Nem jogosult'}
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                      {roleSubMenuPermissions.find((permission) =>
                        permission.roleSubmenuPermission.some(
                          (rolePerm) =>
                            rolePerm.roleId === roleId &&
                            permission.subMenuName === subMenu.subMenuName &&
                            rolePerm.canEdit
                        )
                      )
                        ? 'Jogosult'
                        : 'Nem jogosult'}
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                      <ButtonUI
                        onClick={() => {
                          setRoleModalShow(true);
                          setRoleHasPermission(
                            roleSubMenuPermissions.find((permission) =>
                              permission.roleSubmenuPermission.some(
                                (rolePerm) =>
                                  rolePerm.roleId === roleId && permission.subMenuName === subMenu.subMenuName
                              )
                            )
                              ? true
                              : false
                          );
                          setSelectedSubMenu(subMenu.subMenuId);
                        }}
                        variant="gradient"
                        color="blue-gray"
                        size="sm"
                        disabled={!roleId}
                        text="Szerkesztés"
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <RoleModify
        show={roleModalShow}
        onHide={() => setRoleModalShow(false)}
        hasPermission={roleHasPermission}
        roleId={roleId}
        selectedSubMenu={selectedSubMenu}
        roleSubMenuPermissions={roleSubMenuPermissions}
        refreshPage={refreshPage}
        setRefreshPage={setRefreshPage}
        setRoleSubMenuPermissions={setRoleSubMenuPermissions}
      />
    </>
  );
}
