import { useEffect, useState } from 'react';

import appService from '../../service/app.service';
import DashboardModify from './DashboardModify';
import { ButtonUI, DebouncedInputUI } from '../Interface';
import dashboardService from '../../service/dashboard.service';

export default function DashboardIformation({ roleState }) {
  const { roleId } = roleState;
  const [dashboarRolePermissions, setDashboardRolePermissions] = useState([]);
  const [dashboard, setDashboards] = useState([]);
  const [roleModalShow, setRoleModalShow] = useState(false);
  const [roleHasPermission, setRoleHasPermission] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState(null);
  const [refreshPage, setRefreshPage] = useState(false);
  const [dashboardRoles, setDashboardRoles] = useState([]);
  const [filter, setFilter] = useState({
    name: '',
  });

  useEffect(() => {
    fetchAllDashboard();
  }, []);

  const fetchData = async () => {
    if (!roleId) {
      return;
    }
    appService
      .getRoleInformation(roleId)
      .then((data) => {
        fetchDashboardRolePermissions();
      })
      .finally(() => {});
  };

  const fetchDashboardRolePermissions = async () => {
    appService
      .getDashboardPermissions(roleId)
      .then((data) => {
        setDashboardRolePermissions(data);
      })
      .finally(() => {});
  };

  const fetchAllDashboard = async () => {
    dashboardService
      .allDashboards()
      .then((data) => {
        setDashboards(data);
      })
      .finally(() => {});
  };

  useEffect(() => {
    const mergedRoles = dashboard.map((dashboard) => {
      const permission = dashboarRolePermissions.find(
        (permission) => permission.dashboardId === dashboard.id && permission.roleId === roleId
      );

      return {
        ...dashboard,
        hasPermission: !!permission,
      };
    });

    setDashboardRoles(mergedRoles);
  }, [dashboard, dashboarRolePermissions, roleId]);

  useEffect(() => {
    fetchData();
  }, [roleId]);

  useEffect(() => {
    fetchData();
  }, [refreshPage]);

  return (
    <>
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 max-h-[calc(100vh-10rem)] overflow-y-auto -mt-[2rem] custom-scrollbar">
        <div>
          <div className="w-[250px] mb-3">
            <DebouncedInputUI
              label="Keresés"
              debounceMs={800}
              quickFilterSearchValue={filter?.name}
              setQuickFilterSearchValue={(newValue) => {
                setFilter((prevState) => ({
                  ...prevState,
                  name: newValue,
                }));
              }}
            />
          </div>
          <table className="mt-2 min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Menüpont
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Jogosult
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                ></th>
              </tr>
            </thead>
            <tbody>
              {dashboardRoles
                .filter((dashboardRole) => dashboardRole.name.toLowerCase().includes(filter.name.toLowerCase()))
                .map((dashboardRole, index) => (
                  <tr key={index} className="bg-white">
                    <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                      {dashboardRole.name}
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                      {dashboardRole.hasPermission ? 'Jogosult' : 'Nem jogosult'}
                    </td>
                    <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                      <ButtonUI
                        onClick={() => {
                          setRoleModalShow(true);
                          setRoleHasPermission(dashboardRole.hasPermission);
                          setSelectedDashboard(dashboardRole.id);
                        }}
                        variant="gradient"
                        color="blue-gray"
                        size="sm"
                        disabled={!roleId}
                        text="Szerkesztés"
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <DashboardModify
        show={roleModalShow}
        onHide={() => setRoleModalShow(false)}
        hasPermission={roleHasPermission}
        roleId={roleId}
        selectedDashboard={selectedDashboard}
        refreshPage={refreshPage}
        setRefreshPage={setRefreshPage}
        dashboarRoleState={{ dashboarRolePermissions, setDashboardRolePermissions }}
      />
    </>
  );
}
