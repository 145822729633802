import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'quantityunit';

const quantityUnitService = {
  createQuantityUnit: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  getQuantityUnitReport: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/quantities`,
      data,
    });
  },

  updateQuantityUnit: (data, quantityUnitId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${quantityUnitId}`,
      data,
    });
  },

  deleteQuantityUnit: (quantityUnitId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${quantityUnitId}`,
    });
  },
};

export default quantityUnitService;
