import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';

import Yup from '../../utils/yup';
import productionService from '../../service/production.service';
import { useLoader } from '../../provider/LoaderProvider';

import { DialogUI, InputUI, ButtonUI, AutoCompleteSelectUI, FormLabelUI, FormErrorUI } from '../Interface';

const AddDialog = ({ show, onHide, productionId, setProductionPersonList }) => {
  const { showLoader, hideLoader } = useLoader();
  const [newMemberData, setNewMemberData] = useState({});

  useEffect(() => {
    setNewMemberData(defaultValues);
  }, []);

  const defaultValues = {
    userId: null,
    roleName: '',
  };

  const validationSchema = Yup.object().shape({
    userId: Yup.string().required(),
    roleName: Yup.string().required(),
  });

  const handleAddPerson = (values) => {
    showLoader();
    productionService
      .addPersonToList(values, productionId)
      .then((data) => {
        setProductionPersonList((prev) => {
          return [...prev, data];
        });
        setNewMemberData(defaultValues);
        onHide();
      })
      .finally(() => {
        hideLoader();
      });
  };

  return (
    <DialogUI open={show} headerContent="Dolgozó(k) hozzáadása" fullWidth={true}>
      <div className=" text-center">
        <div className="flex mt-2 gap-10 items-center justify-center z-50">
          <Formik
            initialValues={newMemberData}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleAddPerson(values);
              setSubmitting(false);
            }}
          >
            {({ errors, setFieldValue, validateField, values }) => (
              <Form>
                <div className="flex gap-10">
                  <Field
                    type="autocomplete"
                    name="userId"
                    component={AutoCompleteSelectUI}
                    fullWidth
                    label={<FormLabelUI text="Dolgozó" />}
                    variant="standard"
                    helperText={<FormErrorUI message={errors.userId} />}
                    onChange={(_e, newVal) => {
                      setFieldValue('userId', newVal?.value ?? null).then(() => {
                        validateField('userId');
                      });
                    }}
                    InputLabelProps={{ shrink: values.userId !== '' }}
                    selectedValue={values.userId}
                    table={'WebUser'}
                    listType={{ id: 'userId', name: 'userName' }}
                    conditions={[`WebUser.archive = 'N'`]}
                  />
                  <div className="relative">
                    <div className="relative">
                      <Field
                        type="text"
                        as={InputUI}
                        variant="standard"
                        name="roleName"
                        label={<FormLabelUI text="Szerep" />}
                        helperText={<FormErrorUI message={errors.roleName} />}
                      />
                    </div>
                  </div>
                </div>

                <div className="px-4 mt-8 flex justify-center gap-3">
                  <ButtonUI text="Hozzáadás" className="bg-success" type="submit"></ButtonUI>
                  <ButtonUI
                    text="Mégse"
                    className=""
                    color="blue-gray"
                    type="button"
                    variant="gradient"
                    onClick={onHide}
                  ></ButtonUI>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </DialogUI>
  );
};

export default AddDialog;
