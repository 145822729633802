import kanbanService from '../../service/kanban.service';
import { AutoCompleteSelectUI, ButtonUI, DialogUI, FormLabelUI } from '../Interface';

const MessageDialog = ({ open, handleClose, type, message, user, setRefetch }) => {
  const { messageData, setMessageData } = message;

  const handleSubmit = () => {
    const existingMessage = messageData.eventData.messages.find((msg) => msg.lineId === messageData.eventData.id);

    if (existingMessage) {
      updateMessage(existingMessage.id);
    } else {
      createMessage();
    }
  };

  const createMessage = () => {
    const data = {
      lineId: messageData.eventData.id,
      addressedTo: messageData.addressedTo,
      createdBy: user.userId,
    };
    kanbanService.createMessage(data, type).then(() => {
      handleClose();
      setRefetch(true);
    });
  };

  const updateMessage = (messageId) => {
    const data = {
      lineId: messageData.eventData.id,
      addressedTo: messageData.addressedTo,
      updatedBy: user.userId,
    };
    kanbanService.updateMessage(data, type, messageId).then(() => {
      handleClose();
      setRefetch(true);
    });
  };

  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent="Üzenetkezelés"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '600px',
          },
        },
      }}
    >
      <div className="mt-5 flex flex-col items-center">
        <div className="w-[350px]">
          <AutoCompleteSelectUI
            variant="standard"
            onChange={(_e, newVal, reason) => {
              if (reason === 'clear') {
                setMessageData((prev) => ({
                  ...prev,
                  addressedTo: '',
                }));
              } else {
                setMessageData((prev) => ({
                  ...prev,
                  addressedTo: newVal.value,
                }));
              }
            }}
            selectedValue={messageData.addressedTo}
            label={<FormLabelUI text="Címzett" />}
            table={'WebUser'}
            listType={{ id: 'userId', name: 'userName' }}
            conditions={[`WebUser.archive = 'N'`]}
          />
        </div>
        <div className="mt-5 flex justify-center gap-2">
          <ButtonUI text="Mentés" className="bg-success" onClick={handleSubmit} disabled={!messageData.addressedTo} />
          <ButtonUI text="Bezárás" onClick={handleClose} color="blue-gray" variant="gradient" />
        </div>
      </div>
    </DialogUI>
  );
};

export default MessageDialog;
