import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'employeeResourcePlanner';

const employeeResourcePlannerService = {
  getEmployeeByType: (type) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/employee/${type}`,
    });
  },

  getTasks: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/tasks`,
      data,
    });
  },
};
export default employeeResourcePlannerService;
