import { AutoCompleteSelectUI, ButtonUI, DialogUI, FormLabelUI } from '../Interface';

export const TypeDialog = ({ open, handleClose, submenu, filter, saveView }) => {
  const { filterVal, setFilterVal } = filter;
  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent="Típus választó"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <div className="mt-5">
        <div className="text-labelColor">
          <div className="w-[750px]">
            <AutoCompleteSelectUI
              label={<FormLabelUI text="Típus" />}
              onChange={(_event, newVal, _reason) => {
                setFilterVal((prev) => ({
                  ...prev,
                  simpleTaskTypeId: newVal,
                }));
                saveView('ganttFilter', { employeeType: newVal });
              }}
              selectedValue={filterVal.simpleTaskTypeId}
              disabled={false}
              id={'selectedStatus'}
              table={['typeitems', 'type']}
              listType={{ id: 'itemId', name: 'itemName' }}
              joins={'typeitems.typeId = type.typeId'}
              conditions={[`type.subMenuId = ${submenu}`, `typeitems.archive='N'`]}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              multiple
            />
          </div>
          <div className="flex justify-center mt-5">
            <ButtonUI text="Bezárás" color="blue-gray" variant="gradient" onClick={handleClose} />
          </div>
        </div>
      </div>
    </DialogUI>
  );
};
