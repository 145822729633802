import { useNavigate } from 'react-router-dom';
import { DialogUI } from '../Interface';

const MessagesDialog = ({ open, handleClose, messages }) => {
  const navigate = useNavigate();

  const handleNavigateToKanban = (kanbanType) => {
    navigate(`/app/taskkanban`, { state: { kanbanType: kanbanType } });
    handleClose();
  };

  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent="Üzenetek"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '600px',
          },
        },
      }}
    >
      <div className="mt-5">
        <table className="border w-full">
          <thead className="bg-labelColor text-white">
            <tr className="grid grid-cols-4">
              <th className="border p-3 col-span-3">Nézet</th>
              <th className="border p-3 col-span-1">Összesen</th>
            </tr>
          </thead>
          <tbody>
            {messages?.groupedMessages?.length > 0 ? (
              messages.groupedMessages.map((message, index) => (
                <tr
                  key={message.formId}
                  className={`${index % 2 === 0 ? 'bg-gray-100' : ''} hover:bg-[#B3C8D3] cursor-pointer grid grid-cols-4`}
                  onClick={() => handleNavigateToKanban(message.kanbanType)}
                >
                  <td className="border p-2 col-span-3">{message.formName}</td>
                  <td className="border p-2 col-span-1">{message.count}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="border p-2 text-center col-span-1">Nincsenek üzenetek.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </DialogUI>
  );
};

export default MessagesDialog;
