import React from 'react';
import { useParams } from 'react-router-dom';
import BiReport from './BiReport';

const BIPage = () => {
  const { reportId } = useParams();
  return (
    <div className="grid grid-cols-1 h-screen gap-2 p-4 bg-gray-100 mt-[-4rem] pt-[5rem]">
      <div className="col-span-1 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="h-full overflow-hidden  custom-scrollbar">
          <BiReport reportId={reportId} height={780} />
        </div>
      </div>
    </div>
  );
};

export default BIPage;
