import { useEffect } from 'react';

const useDataGridDynamicStyles = (ColorPicker) => {
  useEffect(() => {
    const sheet = new CSSStyleSheet();
    ColorPicker.forEach((color) => {
      const className = `color-${color.colorName}`;
      const selectedClassName = `color-selected-${color.colorName}`;
      sheet.insertRule(
        `.${className} {
              background: ${color.datagridBgColor} !important;
            }`,
        sheet.cssRules.length
      );
      sheet.insertRule(
        `.${selectedClassName} {
              background: #B3C8D3 !important;
            }`,
        sheet.cssRules.length
      );
      sheet.insertRule(
        `.${className}:hover, .${selectedClassName}:hover {
          background: #EDF4FB !important; 
        }`,
        sheet.cssRules.length
      );
    });
    document.adoptedStyleSheets = [...document.adoptedStyleSheets, sheet];
  }, [ColorPicker]);
};

export default useDataGridDynamicStyles;
