import { useEffect, useState } from 'react';
import { GridRowModes, GridToolbarContainer, GridRowEditStopReasons } from '@mui/x-data-grid';
import { Field, Form, Formik } from 'formik';
import dayjs from 'dayjs';

import { NotificationType } from '../../../config';
import addNotification from '../../../utils/addNotification';
import { dataGridColumns } from '../../../utils/helper';
import Yup from '../../../utils/yup';
import resourceOneService from '../../../service/resources/resourceOne.service';

import {
  DataGridUI,
  DataGridIdentifiers,
  ButtonUI,
  FormErrorUI,
  AutoCompleteSelectUI,
  ActionButtonUI,
  CheckboxUI,
  DatePickerUI,
} from '../../Interface';
import appService from '../../../service/app.service';

function EditToolbar(props) {
  const { setRows, setRowModesModel, rows, rowModesModel, setCurrentRow, selectedValues, disabled } = props;

  const handleClick = () => {
    const newRow = {
      listId: Date.now(),
      resourceId: null,
      leader: false,
      educationDate: null,
      isNew: true,
    };

    setRows((oldRows) => [newRow, ...oldRows]);
    setCurrentRow(newRow);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newRow.listId]: { mode: GridRowModes.Edit, fieldToFocus: 'id' },
    }));
  };

  const isNewRow = rows.some(
    (row) => row.isNew || Object.values(rowModesModel).some((row) => row.mode === GridRowModes.Edit)
  );

  return (
    <GridToolbarContainer>
      <ButtonUI
        className="bg-success mb-1"
        text="Új sor hozzáadása"
        disabled={isNewRow || !selectedValues.projectId || disabled}
        onClick={handleClick}
      />
    </GridToolbarContainer>
  );
}

const ResourceOneTable = ({
  resourceList,
  setResourceList,
  selectedValues,
  setRefetchTable,
  disabled,
  user,
  canEdit,
  params,
}) => {
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [currentRow, setCurrentRow] = useState({});
  const [autoCompleteData, setAutoCompleteData] = useState([]);

  useEffect(() => {
    setRows(resourceList.resourceOne);
  }, [resourceList.resourceOne]);

  useEffect(() => {
    getAllEmployee();
  }, []);

  const validationSchema = Yup.object().shape({
    resourceId: Yup.string().required(),
    leader: Yup.string().nullable(),
    educationDate: Yup.string().nullable(),
  });

  const getAllEmployee = () => {
    appService.getEmployees().then((data) => {
      const formattedData = data.map((item) => ({
        label: item.generatedName ?? item.name,
        value: item.id,
      }));
      setAutoCompleteData(formattedData);
    });
  };

  const handleAddRow = (updatedRow) => {
    const generatedName = autoCompleteData.find((item) => item.value === updatedRow.resourceId)?.label || '';

    const insertObject = {
      ...updatedRow,
      createdBy: user?.userId,
    };

    resourceOneService.addResourceToList(insertObject, selectedValues.projectId).then((data) => {
      addNotification({
        content: 'Sikeres hozzáadás!',
        type: NotificationType.SUCCESS,
      });

      const updatedData = {
        ...data,
        resourceOne: {
          ...data.resourceOne,
          generatedName,
        },
      };

      setRefetchTable(true);
      setResourceList((prev) => ({
        ...prev,
        resourceOne: [...prev.resourceOne, updatedData],
      }));
    });
  };

  const handleUpdateRow = (updatedRow, listId) => {
    const updData = {
      ...updatedRow,
      updatedBy: user?.userId,
    };
    resourceOneService.updateProjectResource(updData, listId).then(() => {
      addNotification({
        content: 'Sikeres mentés!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
    });
  };

  const handleDeleteRow = (listId) => {
    const deleteData = {
      deletedBy: user.userId,
    };
    resourceOneService.deleteResourceFromList(deleteData, listId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setResourceList((prev) => ({
        ...prev,
        resourceOne: prev.resourceOne.filter((option) => option.listId !== listId),
      }));
    });
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    const rowToEdit = rows.find((row) => row.listId === id);
    setCurrentRow(rowToEdit);
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (listId) => () => {
    setRows(rows.filter((row) => row.listId !== listId));
    handleDeleteRow(listId);
  };

  const handleCancelClick = (id) => () => {
    const editedRow = rows.find((row) => row.listId === id);

    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.listId !== id));
    } else {
      setRows((prevRows) => prevRows.map((row) => (row.listId === id ? { ...editedRow, isNew: false } : row)));
    }
    setCurrentRow(null);
  };

  const processRowUpdate = async (newRow) => {
    await validationSchema.validate(newRow, { abortEarly: false });
    const updatedRow = {
      ...newRow,
      isNew: newRow.isNew ?? false,
      educationDate: dayjs(newRow.educationDate).isValid()
        ? dayjs(newRow.educationDate).startOf('hour').add(dayjs(newRow.educationDate).utcOffset(), 'minute')
        : newRow.educationDate,
    };
    const updatedRows = rows.map((row) => (row.listId === newRow.listId ? updatedRow : row));

    updatedRows.sort((a, b) => a.listId - b.listId);

    setRows(updatedRows);

    if (updatedRow.isNew) {
      handleAddRow(updatedRow);
    } else {
      handleUpdateRow(updatedRow, newRow.listId);
    }
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = (setFieldValue, validateField, errors) => {
    return dataGridColumns([
      {
        field: 'listId',
        headerName: 'Azon.',
        flex: 0,
        valueGetter: (params) => (params?.row.isNew ? '' : params.row.listId),
      },
      {
        field: 'resourceId',
        headerName: 'Név',
        flex: 1.5,
        editable: !canEdit ? false : true,
        renderCell: (params) => {
          return params.row.resourceOne?.generatedName ?? params.row.resourceOne?.name;
        },
        renderEditCell: (params) => (
          <Field
            name="resourceId"
            component={AutoCompleteSelectUI}
            helperText={<FormErrorUI message={errors.resourceId} />}
            selectedValue={params.row?.resourceId}
            selectedLabelValue={params.row.resourceOne?.generatedName ?? params.row.resourceOne?.name}
            onChange={(_event, newValue) => {
              setFieldValue('resourceId', newValue?.value ?? null).then(() => {
                validateField('resourceId');
              });
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: newValue ? newValue.value : null,
              });
              params.api.updateRows([
                {
                  ...params.row,
                  resourceOne: { name: newValue ? newValue.label : '' },
                },
              ]);
            }}
            dataset={autoCompleteData}
          />
        ),
      },
      {
        field: 'leader',
        headerName: 'Vezető',
        flex: 0,
        editable: !canEdit ? false : true,
        renderCell: (params) => <CheckboxUI checked={params.row?.leader === true} disabled />,
        renderEditCell: (params) => (
          <Field
            name="leader"
            component={CheckboxUI}
            helperText={<FormErrorUI message={errors.leader} />}
            checked={params.row?.leader === true}
            variant="standard"
            onChange={(e) => {
              setFieldValue('leader', e.target.checked).then(() => {
                validateField('leader');
              });
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.checked,
              });
            }}
          />
        ),
      },
      ...(params.PROJECT_RES_ONE_EDUCATION_DATE_FIELD === 'Y'
        ? [
            {
              field: 'educationDate',
              headerName: 'Okt. dátuma',
              flex: 0.5,
              editable: !canEdit ? false : true,
              sortable: false,
              renderCell: (params) => {
                return params.row.educationDate ? dayjs(params.row.educationDate).format('YYYY.MM.DD.') : null;
              },
              renderEditCell: (params) => (
                <Field
                  name="educationDate"
                  component={DatePickerUI}
                  helperText={<FormErrorUI message={errors.educationDate} />}
                  variant="standard"
                  value={dayjs(params.row.educationDate)}
                  onChange={(date) => {
                    setFieldValue('educationDate', date ?? '').then(() => {
                      validateField('educationDate');
                    });
                    params.api.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: date,
                    });
                  }}
                />
              ),
            },
          ]
        : []),
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        flex: 0.5,
        cellClassName: 'actions',
        getActions: ({ id }) => [
          <ActionButtonUI
            id={id}
            rowModesModel={rowModesModel}
            handleSaveClick={handleSaveClick}
            handleCancelClick={handleCancelClick}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            disabled={disabled}
          />,
        ],
      },
    ]);
  };

  return (
    <div className="overflow-x-auto">
      <div className="align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <Formik
            initialValues={currentRow}
            validationSchema={validationSchema}
            onSubmit={processRowUpdate}
            validateOnMount
            enableReinitialize
          >
            {({ errors, setFieldValue, validateField }) => (
              <Form className="w-full">
                <DataGridUI
                  datagridIdentifier={DataGridIdentifiers.ResourceOneTable}
                  sx={{ height: 400 }}
                  columns={columns(setFieldValue, validateField, errors)}
                  rows={rows}
                  getRowId={(row) => row.listId}
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={processRowUpdate}
                  slots={{
                    toolbar: EditToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      setRows,
                      setRowModesModel,
                      rows,
                      rowModesModel,
                      user,
                      setCurrentRow,
                      selectedValues,
                      disabled,
                    },
                  }}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ResourceOneTable;
