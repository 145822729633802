import React from 'react';
import dayjs from 'dayjs';
import { Typography } from '@material-tailwind/react';

const Details = ({ task }) => {
    const {
        ItemCode,
        PlannedQty,
        DueDate,
        U_QNT_RAJZSZAM,
        ProdName,
        FinishPlannedQty,
        COMBINED_START,
        COMBINED_END,
        U_QNT_PLANSTATUS,
        Project,
    } = task;

    return (
        <div className="block grid-cols-4 gap-x-4 gap-y-2 mx-4 md:grid">
            <Typography className="mb-3">
                <h4 className="font-semibold">Művelet</h4>
                <p className="text-gray-700">{ItemCode || 'N/A'}.</p>
            </Typography>
            <Typography className="mb-3">
                <h4 className="font-semibold">Tervezett idő</h4>
                <p className="text-gray-700">{PlannedQty || 'N/A'}.</p>
            </Typography>
            <Typography className="mb-3">
                <h4 className="font-semibold">Esedékesség</h4>
                <p className="text-gray-700">{dayjs(DueDate).format('YYYY.MM.DD.') || 'N/A'}.</p>
            </Typography>
            <Typography className="mb-3">
                <h4 className="font-semibold">Késztermék</h4>
                <p className="text-gray-800">{U_QNT_RAJZSZAM || 'N/A'}</p>
            </Typography>
            <Typography className="mb-3">
                <h4 className="font-semibold">Megnevezés</h4>
                <p className="text-gray-800">{ProdName || 'N/A'}</p>
            </Typography>
            <Typography className="mb-3">
                <h4 className="font-semibold">Késztermék mennyisége</h4>
                <p className="text-gray-800">{FinishPlannedQty || 'N/A'}</p>
            </Typography>
            <Typography className="mb-3">
                <h4 className="font-semibold">Kezdő dátum, időpont</h4>
                <p className="text-gray-700">{dayjs(COMBINED_START).format('YYYY.MM.DD. HH:mm') || 'N/A'}.</p>
            </Typography>
            <Typography className="mb-3">
                <h4 className="font-semibold">Záró dátum, időpont</h4>
                <p className="text-gray-700">{dayjs(COMBINED_END).format('YYYY.MM.DD. HH:mm') || 'N/A'}.</p>
            </Typography>
            <Typography className="mb-3">
                <h4 className="font-semibold">Tervezés státusza</h4>
                <p className="text-gray-800">{U_QNT_PLANSTATUS || 'N/A'}</p>
            </Typography>
            <Typography className="mb-3">
                <h4 className="font-semibold">Projekt</h4>
                <p className="text-gray-800">{Project || 'N/A'}</p>
            </Typography>
        </div>
    );
};

export default Details;
