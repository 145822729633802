export function getDataGridStyles(props) {
  return {
    height: props.height ?? 650,
    width: '100%',
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: '#B3C8D3',
    },
    '& .MuiDataGrid-row.Mui-selected:hover': {
      backgroundColor: '#B3C8D3',
    },
    '& .MuiDataGrid-row:nth-of-type(even)': {
      backgroundColor: '#EBEAEA',
    },
    '& .MuiDataGrid-row:nth-of-type(even):hover': {
      backgroundColor: '#EDF4FB',
    },
    '& .MuiDataGrid-row:nth-of-type(odd):hover': {
      backgroundColor: '#EDF4FB',
    },
    '& .MuiDataGrid-row.Mui-selected:nth-of-type(even)': {
      backgroundColor: '#B3C8D3',
    },
    '& .MuiDataGrid-row:nth-of-type(odd)': {
      backgroundColor: '#F7F7F7',
    },
    '& .MuiDataGrid-row.Mui-selected:nth-of-type(odd)': {
      backgroundColor: '#B3C8D3',
    },
    '& .MuiDataGrid-row.Mui-selected:nth-of-type(odd):hover': {
      backgroundColor: '#A7B9C2',
    },
    '& .MuiDataGrid-row.Mui-selected:nth-of-type(even):hover': {
      backgroundColor: '#A7B9C2',
    },
    '& .MuiDataGrid-aggregationColumnHeaderLabel': {
      position: 'relative',
      marginTop: '4px',
    },
  };
}
