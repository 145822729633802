import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { Box, Chip, Stack } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { alpha } from '@mui/material/styles';

import DialogUI from './DialogUI';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import ButtonUI from './ButtonUI';

const sx_config = {
  root: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px dashed ${alpha(theme.palette.primary.main, 0.2)}`,
    transition: theme.transitions.create(['border', 'background-color'], {
      duration: theme.transitions.duration.complex,
    }),
    height: theme.spacing(16),
    width: '100%',
    cursor: 'pointer',
    textAlign: 'center',
    '&:focus': {
      outline: 'none',
    },
    '&.active.accept': {
      backgroundColor: 'red',
      '& .drop': {
        display: 'block',
      },
    },
    '&.active.reject': {
      borderColor: theme.palette.error.main,
      '& .reject': {
        display: 'block',
      },
    },
    '@keyframes float': {
      '0%': {
        transform: 'translateY(0px)',
      },
      '50%': {
        transform: 'translateY(-4px)',
      },
      '100%': {
        transform: 'translateY(0px)',
      },
    },
  }),
  disabled: {
    cursor: 'default',
  },
  enabled: {
    '&:hover': {
      borderColor: (theme) => theme.palette.primary.main,

      '& .MuiSvgIcon-root': {
        animationName: 'float',
        animationDuration: '1s',
        animationTimingFunction: 'ease-out',
        animationFillMode: 'forwards',
        animationIterationCount: 'infinite',
      },
    },
  },
  label: {
    marginBottom: (theme) => theme.spacing(1),
  },
};

const FileUploadDialogUI = ({ open, handleClose, handleUpload, accept, ...props }) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles([]);
  }, []);

  const onRejected = (files, event) => {
    addNotification({
      content: 'Sikertelen hozzáadás!',
      type: NotificationType.ERROR,
    });
  };

  const onAccepted = (files, event) => {
    setFiles(files);
    addNotification({
      content: 'Fájl hozzáadása sikeres!',
      type: NotificationType.SUCCESS,
    });
  };

  const handleDelete = (index) => {
    setFiles((prev) => prev.splice(index, 1));
  };

  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent={'Fájl feltöltése'}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <Dropzone multiple={false} onDropRejected={onRejected} onDropAccepted={onAccepted} accept={accept} {...props}>
        {({ getRootProps, getInputProps }) => {
          return (
            <Box display={'block'} sx={{ m: 2 }}>
              <Box {...getRootProps()} sx={[sx_config.root, props.disabled ? sx_config.disabled : sx_config.enabled]}>
                <div>
                  <input {...getInputProps()} />

                  <CloudUploadIcon fontSize="large" color="primary" />
                  <Box sx={{ fontWeight: 'bold', color: 'text.secondary' }}>Húzd ide a fájlt, vagy kattints!</Box>
                </div>
              </Box>
            </Box>
          );
        }}
      </Dropzone>

      <Box display="flex" gap={3} justifyContent="center" sx={{ m: 2 }}>
        <Stack direction="row" spacing={1}>
          {files.map((file, index) => (
            <Chip
              label={file.name}
              onDelete={() => {
                handleDelete(index);
              }}
            />
          ))}
        </Stack>
      </Box>

      <Box display="flex" gap={3} justifyContent="center">
        <ButtonUI
          text="Feltöltés"
          className="bg-success flex gap-2 items-center"
          type="button"
          disabled={files.length === 0}
          onClick={() => handleUpload(files)}
        />

        <ButtonUI
          text="Mégse"
          className="bg-danger flex items-center gap-2"
          type="button"
          onClick={() => handleClose()}
        >
          Mégse
        </ButtonUI>
      </Box>
    </DialogUI>
  );
};

export default FileUploadDialogUI;
