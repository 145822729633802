import React from 'react';
import { Field } from 'formik';

import { getFieldOptions } from '../../utils/ResourceFieldOption';
import { InputUI, DatePickerUI, AutoCompleteSelectUI, TimePickerUi, FormErrorUI, FormLabelUI } from '../Interface';

const FormFields = ({
  viewField,
  submenuId,
  selectedValues,
  values,
  validateField,
  setFieldValue,
  errors,
  params,
  submenuNames,
}) => {
  const isAnyFieldFilled = () => {
    return Object.keys(values).some((key) => key.startsWith('resource') && values[key]);
  };

  const projectSelectFirst = params.SIMPLETASK_PROJECT_FIRST;
  const disabledField = !values.projectId && projectSelectFirst;

  const fieldOptionsResourceOne = getFieldOptions('resourceOne', projectSelectFirst, disabledField, values.projectId);
  const fieldOptionsResourceTwo = getFieldOptions('resourceTwo', projectSelectFirst, disabledField, values.projectId);
  const fieldOptionsResourceThree = getFieldOptions(
    'resourceThree',
    projectSelectFirst,
    disabledField,
    values.projectId
  );

  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-2 mx-4">
      <div className="col-span-4 ">
        <Field
          type="text"
          name="simpleTaskName"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Feladat rövid neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.simpleTaskName} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.simpleTaskName !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2 ">
        <Field
          type="date"
          name="startDate"
          component={DatePickerUI}
          value={values.startDate}
          fullWidth
          label={<FormLabelUI text="Kezdő dátum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.startDate} />}
          onChange={(date) => {
            setFieldValue('startDate', date).then(() => {
              validateField('startDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.startDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="time"
          name="startDate"
          component={TimePickerUi}
          value={values.startDate}
          fullWidth
          label={<FormLabelUI text="Kezdő időpont" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.startDate} />}
          onChange={(date) => {
            setFieldValue('startDate', date).then(() => {
              validateField('startDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.startDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="date"
          name="endDate"
          component={DatePickerUI}
          value={values.endDate}
          fullWidth
          label={<FormLabelUI text="Befejező dátum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.endDate} />}
          onChange={(date) => {
            setFieldValue('endDate', date).then(() => {
              validateField('endDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.endDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="duration"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Időintervallum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.duration} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.duration !== '' }}
          onChange={(e) => {
            const newendDate = values.startDate.add(e.target.value, 'hour');
            setFieldValue('duration', e.target.value ?? '');
            setFieldValue('endDate', newendDate).then(() => {
              validateField('endDate');
            });
          }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="preparedness"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Készültség" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.preparedness} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.preparedness !== '' }}
        />
      </div>
      {params.SIMPLETASK_RESOURCE_FIELD === 'Y' && (
        <div className="col-span-4 md:col-span-2">
          <Field
            type="autocomplete"
            name="machineId"
            component={AutoCompleteSelectUI}
            fullWidth
            label={<FormLabelUI text="Erőforrás" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.machineId} />}
            onChange={(_e, newVal) => {
              setFieldValue('machineId', newVal?.value ?? null).then(() => {
                validateField('machineId');
              });
            }}
            disabled={viewField}
            InputLabelProps={{ shrink: values.machineId !== '' }}
            selectedValue={values.machineId}
            selectedLabelValue={selectedValues?.machine?.machineName}
            table={'machines'}
            listType={{ id: 'machineId', name: 'machineName' }}
            conditions={[`machines.archive = 'N'`]}
          />
        </div>
      )}
      {params.SIMPLETASK_PROJECT_FIELD === 'Y' && (
        <div className="col-span-4 md:col-span-2">
          <Field
            type="autocomplete"
            name="projectId"
            component={AutoCompleteSelectUI}
            fullWidth
            label={<FormLabelUI text="Projekt" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.projectId} />}
            onChange={(_e, newVal) => {
              setFieldValue('projectId', newVal?.value ?? null).then(() => {
                validateField('projectId');
              });
            }}
            disabled={viewField}
            InputLabelProps={{ shrink: values.projectId !== '' }}
            selectedValue={values.projectId}
            selectedLabelValue={selectedValues?.project?.projectName}
            table={'project'}
            listType={{ id: 'projectId', name: 'projectName' }}
            conditions={[`project.archive = 'N'`]}
          />
        </div>
      )}
      {params.SIMPLETASK_RESOURCE_ONE_FIELD === 'Y' && (
        <div className="col-span-4 md:col-span-2">
          <Field
            type="autocomplete"
            name="resourceOneId"
            component={AutoCompleteSelectUI}
            fullWidth
            label={<FormLabelUI text={submenuNames[0]} />}
            variant="standard"
            helperText={<FormErrorUI message={errors.resourceOneId} />}
            onChange={(_e, newVal) => {
              setFieldValue('resourceOneId', newVal?.value ?? null).then(() => {
                validateField('resourceOneId');
              });
            }}
            disabled={viewField || (isAnyFieldFilled() && !values.resourceOneId) || disabledField}
            InputLabelProps={{ shrink: values.resourceOneId !== '' }}
            selectedValue={values.resourceOneId}
            selectedLabelValue={selectedValues?.resourceOne?.name}
            {...fieldOptionsResourceOne}
          />
        </div>
      )}
      {params.SIMPLETASK_RESOURCE_TWO_FIELD === 'Y' && (
        <div className="col-span-4 md:col-span-2">
          <Field
            type="autocomplete"
            name="resourceTwoId"
            component={AutoCompleteSelectUI}
            fullWidth
            label={<FormLabelUI text={submenuNames[1]} />}
            variant="standard"
            helperText={<FormErrorUI message={errors.resourceTwoId} />}
            onChange={(_e, newVal) => {
              setFieldValue('resourceTwoId', newVal?.value ?? null).then(() => {
                validateField('resourceTwoId');
              });
            }}
            disabled={viewField || (isAnyFieldFilled() && !values.resourceTwoId) || disabledField}
            InputLabelProps={{ shrink: values.resourceTwoId !== '' }}
            selectedValue={values.resourceTwoId}
            selectedLabelValue={selectedValues?.resourceTwo?.name}
            {...fieldOptionsResourceTwo}
          />
        </div>
      )}
      {params.SIMPLETASK_RESOURCE_THREE_FIELD === 'Y' && (
        <div className="col-span-4 md:col-span-2">
          <Field
            type="autocomplete"
            name="resourceThreeId"
            component={AutoCompleteSelectUI}
            fullWidth
            label={<FormLabelUI text={submenuNames[2]} />}
            variant="standard"
            helperText={<FormErrorUI message={errors.resourceThreeId} />}
            onChange={(_e, newVal) => {
              setFieldValue('resourceThreeId', newVal?.value ?? null).then(() => {
                validateField('resourceThreeId');
              });
            }}
            disabled={viewField || (isAnyFieldFilled() && !values.resourceThreeId) || disabledField}
            InputLabelProps={{ shrink: values.resourceThreeId !== '' }}
            selectedValue={values.resourceThreeId}
            selectedLabelValue={selectedValues?.resourceThree?.name}
            {...fieldOptionsResourceThree}
          />
        </div>
      )}
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="simpleTaskTypeId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Feladat típusa" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.simpleTaskTypeId} />}
          onChange={(_e, newVal) => {
            setFieldValue('simpleTaskTypeId', newVal?.value ?? null).then(() => {
              validateField('simpleTaskTypeId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.simpleTaskTypeId !== '' }}
          selectedValue={values.simpleTaskTypeId}
          selectedLabelValue={selectedValues?.taskType?.itemName}
          table={['typeitems', 'type']}
          listType={{ id: 'itemId', name: 'itemName' }}
          joins={'typeitems.typeId = type.typeId'}
          conditions={[`typeitems.archive = 'N'`, `type.subMenuId = ${submenuId}`]}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="userId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Tulajdonos" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.userId} />}
          onChange={(_e, newVal) => {
            setFieldValue('userId', newVal?.value ?? null).then(() => {
              validateField('userId');
            });
          }}
          disabled
          InputLabelProps={{ shrink: values.userId !== '' }}
          selectedValue={values.userId}
          selectedLabelValue={selectedValues?.user?.userName}
          table={'WebUser'}
          listType={{ id: 'userId', name: 'userName' }}
          conditions={[`WebUser.archive = 'N'`]}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="resposiblePersonId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Felelős" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.resposiblePersonId} />}
          onChange={(_e, newVal) => {
            setFieldValue('resposiblePersonId', newVal?.value ?? null).then(() => {
              validateField('resposiblePersonId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.resposiblePersonId !== '' }}
          selectedValue={values.resposiblePersonId}
          selectedLabelValue={selectedValues?.responsiblePerson?.userName}
          table={'WebUser'}
          listType={{ id: 'userId', name: 'userName' }}
          conditions={[`WebUser.archive = 'N'`]}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="statusId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Státusz" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.statusId} />}
          onChange={(_e, newVal) => {
            setFieldValue('statusId', newVal?.value ?? null).then(() => {
              validateField('statusId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.statusId !== '' }}
          selectedValue={values.statusId}
          selectedLabelValue={selectedValues?.status?.itemName}
          table={['statusitems', 'status']}
          listType={{ id: 'itemId', name: 'itemName' }}
          joins={'statusitems.statusId = status.statusId'}
          conditions={[`statusitems.archive = 'N'`, `status.subMenuId = ${submenuId}`]}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="priorId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Prioritás" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.priorId} />}
          onChange={(_e, newVal) => {
            setFieldValue('priorId', newVal?.value ?? null).then(() => {
              validateField('priorId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.priorId !== '' }}
          selectedValue={values.priorId}
          selectedLabelValue={selectedValues?.priority?.itemName}
          table={['prioritylistitems ', 'priority']}
          listType={{ id: 'itemId', name: 'itemName' }}
          joins={'prioritylistitems.priorityId = priority.priorityId'}
          conditions={[`priority.archive = 'N'`, `priority.subMenuId = ${submenuId}`]}
        />
      </div>
      {params.SIMPLETASK_COMPANY_LOCATION_FIELD === 'Y' && (
        <div className="col-span-4 md:col-span-2">
          <Field
            type="autocomplete"
            name="companyLocationId"
            component={AutoCompleteSelectUI}
            fullWidth
            label={<FormLabelUI text="Telephely" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.companyLocationId} />}
            onChange={(_e, newVal) => {
              setFieldValue('companyLocationId', newVal?.value ?? null).then(() => {
                validateField('companyLocationId');
              });
            }}
            disabled={viewField}
            InputLabelProps={{ shrink: values.companyLocationId !== '' }}
            selectedValue={values.companyLocationId}
            selectedLabelValue={selectedValues?.companyLocation?.name}
            table={['companyLocation']}
            listType={{ id: 'id', name: 'name' }}
            conditions={[`companyLocation.archive = 'N'`]}
          />
        </div>
      )}
    </div>
  );
};

export default FormFields;
