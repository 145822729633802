import React from 'react';

const AbsenceEmptyDaysTable = ({ formData, filter }) => {
  return (
    <div className="mb-5 max-h-[700px]  overflow-y-scroll overflow-x-hidden custom-scrollbar">
      <table className="border w-full">
        <thead className="bg-tableHeaderColor">
          <tr className="grid grid-cols-4">
            <th className="border p-3 col-span-2">Dolgozó</th>
            <th className="border p-3 col-span-2">Hiányzó napok</th>
          </tr>
        </thead>
        <tbody>
          {formData
            .filter((row) => row.name.toLowerCase().includes(filter.employee.toLowerCase()))
            .map((row, index) => (
              <tr key={row.id} className={index % 2 === 0 ? 'bg-gray-100 grid grid-cols-4' : 'grid grid-cols-4'}>
                <td className="border py-2 px-3 align-top text-left col-span-2 text-[1.1rem] font-semibold text-gray-600">
                  {row.name}
                </td>
                <td className="border pt-3 text-center col-span-2 font-semibold text-gray-600">
                  <div>
                    {row.missingDays.map((day, index) => (
                      <div key={index} className="mb-2">
                        {day}
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default AbsenceEmptyDaysTable;
