export const usersReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET':
      localStorage.setItem('user', JSON.stringify(payload));
      return payload;
    case 'GET':
      return JSON.parse(localStorage.getItem('user'));
    case 'UPDATE_VIEWS':
      const updatedUser = { ...state, views: payload };
      localStorage.setItem('user', JSON.stringify(updatedUser));
      return updatedUser;
    default:
      //throw new Error('Nincs ilyen eset a usersReducerben!');
      return state;
  }
};
