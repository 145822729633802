import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'bi/feedback';

const biFeedbackService = {
  getBiFeedback: (id) => {
    return request({
      resource: `${baseURL}/${id}`,
    });
  },

  saveBiFeedback: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  deleteBiFeedBack: (id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
    });
  },
};

export default biFeedbackService;
