import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'vehiclePlan';

const vehiclePlanService = {
  createVehiclePlan: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  getVehiclePlans: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/vehiclePlans`,
      data,
    });
  },

  updateVehiclePlan: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  deleteVehiclePlan: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },
};

export default vehiclePlanService;
