export const GanttType = {
  RESOURCE_ONE: 'RESOURCE_ONE',
  RESOURCE_TWO: 'RESOURCE_TWO',
  RESOURCE_THREE: 'RESOURCE_THREE',
  RESOURCE_FOUR: 'RESOURCE_FOUR',
  RESOURCE_FIVE: 'RESOURCE_FIVE',
};

export const EmployeeGanttFilterType = {
  ALL: 'ALL',
  LEADER: 'LEADER',
};

export const EmployeeGanttOptionType = [
  { label: 'Összes dolgozó', value: EmployeeGanttFilterType.ALL },
  { label: 'Vezetők', value: EmployeeGanttFilterType.LEADER },
];
