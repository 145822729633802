import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import absenceService from '../../service/absence.service';

import { ButtonUI, DatePickerUI, DebouncedInputUI, DialogUI } from '../Interface';
import AbsenceEmptyDaysTable from './AbsenceEmptyDaysTable';

const AbsenceDialog = ({ open, handleClose, user, setRefetchTable }) => {
  const [formData, setFormData] = useState([]);
  const [refetch, setRefetch] = useState(true);
  const [filter, setFilter] = useState({
    month: null,
    employee: '',
  });

  const handleChange = (newValue) => {
    if (newValue) {
      const selectedMonth = new Date(newValue);
      const selectedMonthName = dayjs(selectedMonth).format('YYYY-MM');
      setFilter((prev) => ({
        ...prev,
        month: selectedMonthName,
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        month: null,
      }));
      setFormData([]);
    }
  };

  useEffect(() => {
    if (refetch && filter.month) {
      allEmptyDays();
    }
  }, [refetch]);

  const allEmptyDays = () => {
    const data = {
      month: filter.month,
    };
    absenceService.getEmptyDays(data).then((data) => {
      if (data.length === 0) {
        addNotification({
          content: 'Az adott hónapban nincsenek hiányzó napok!',
          type: NotificationType.WARNING,
        });
      }
      setFormData(data);
      setRefetch(false);
    });
  };

  const showEmptyDays = () => {
    if (filter.month) {
      allEmptyDays();
    } else {
      addNotification({
        content: 'Nincs kiválasztott hónap!',
        type: NotificationType.WARNING,
      });
    }
  };

  const saveAllAbsences = () => {
    if (formData.length === 0) {
      addNotification({
        content: 'Nincsenek hiányzó napok rögzítésre!',
        type: NotificationType.WARNING,
      });
      return;
    }

    const absenceList = formData.flatMap((employee) =>
      employee.missingDays.map((day) => ({
        userId: employee.id,
        mainCategory: 1,
        subCategory: 1,
        createdBy: user.userId,
        startDate: dayjs(day).startOf('hour').add(dayjs(day).utcOffset(), 'minute').toISOString(),
        endDate: dayjs(day).startOf('hour').add(dayjs(day).utcOffset(), 'minute').toISOString(),
        generated: true,
      }))
    );

    absenceService.createMultipleAbsences(absenceList).then(() => {
      addNotification({
        content: 'Minden szabadság sikeresen rögzítve!',
        type: NotificationType.SUCCESS,
      });
      setRefetch(true);
      setRefetchTable(true);
    });
  };

  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent={
        <div className="flex gap-5 items-center -mt-2">
          <div className="flex-0.5">
            <DatePickerUI
              sx={{
                '& .MuiInput-root': {
                  '&:after': {
                    borderColor: '#fff',
                  },
                },
                '& .MuiInputLabel-standard': {
                  color: '#fff',
                  '&.Mui-focused': {
                    color: '#fff',
                  },
                },
                '& .MuiInputBase-input': {
                  color: '#fff',
                },
              }}
              views={['year', 'month']}
              openTo="month"
              value={filter.date}
              onChange={handleChange}
              label="Hónap kiválasztása"
              format="MMMM"
            />
          </div>
          <div className="flex-0.5">
            <DebouncedInputUI
              label="Dolgozó"
              debounceMs={800}
              sx={{
                '& .MuiInputBase-input': {
                  color: '#fff',
                },
              }}
              InputLabelProps={{
                style: { color: '#ffffff' },
              }}
              quickFilterSearchValue={filter?.employee}
              setQuickFilterSearchValue={(newValue) => {
                setFilter((prevState) => ({
                  ...prevState,
                  employee: newValue,
                }));
              }}
            />
          </div>
          <ButtonUI
            text="Generálási lista előkészítése"
            size="sm"
            className="bg-success mt-3"
            onClick={showEmptyDays}
          />
        </div>
      }
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '900px',
          },
        },
      }}
    >
      <div className="mt-5">
        <div className="text-center">
          <AbsenceEmptyDaysTable formData={formData} filter={filter} />
          <ButtonUI text="Bezárás" color="blue-gray" variant="gradient" onClick={handleClose} />
          <ButtonUI text="Szabadságok rögzítése" className="bg-success ml-5" onClick={saveAllAbsences} />
        </div>
      </div>
    </DialogUI>
  );
};

export default AbsenceDialog;
