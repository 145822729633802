import { Tooltip } from '@mui/material';
import TooltipContent from './TooltipContent';
import ChipContent from './ChipContent';

const EventChip = ({ eventInfo }) => {
  const setBackgroundOpacity = (rgba, opacity) => {
    return rgba.replace(/[\d\.]+\)$/g, `${opacity})`);
  };

  const handleMouseEnter = (e) => {
    const eventElement = e.currentTarget.closest('.fc-h-event');
    eventElement.style.border = `2px solid ${eventInfo.textColor}`;
    eventElement.style.backgroundColor = setBackgroundOpacity(eventInfo.backgroundColor, 0.9);
  };

  const handleMouseLeave = (e) => {
    const eventElement = e.currentTarget.closest('.fc-h-event');
    eventElement.style.backgroundColor = setBackgroundOpacity(eventInfo.backgroundColor, 0.6);
    eventElement.style.border = `2px solid transparent`;
  };

  return (
    <div className="font-bold truncate my-auto px-1" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <ChipContent eventInfo={eventInfo} />
    </div>
  );
};

export default EventChip;
