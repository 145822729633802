import { useState } from 'react';

import AttendanceUsers from './AttendanceUsers';
import { TabsUI } from '../Interface';

const AttendanceTabs = ({ attendance, setRefetchTable, user, disabled, selectedValues }) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      id: 0,
      name: 'Tétel',
      content: (
        <AttendanceUsers
          attendance={attendance}
          setRefetchTable={setRefetchTable}
          user={user}
          disabled={disabled}
          selectedValues={selectedValues}
        />
      ),
    },
  ];

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="my-4">
      <div className="px-3">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {tabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </div>
    </div>
  );
};

export default AttendanceTabs;
