import React, { useEffect, useState } from 'react';
import useParam from '../../context/ParamContext';

const ChipContent = ({ eventInfo }) => {
  //@TODO lehetne egy komponens az összes tervezőben? mondjuk ha a paramkey propból jönne? Úgy is tovább lesz másolva...
  const { params } = useParam();
  const [chipObj, setChipObj] = useState(null);

  useEffect(() => {
    let paramKey = 'PROJECT_RESOURCE_PLANNER_CHIP_CONTENT';

    if (params[paramKey]) {
      const parsedObj = JSON.parse(params[paramKey]);
      setChipObj(parsedObj);
    }
  }, [params]);

  const task = eventInfo.event._def.extendedProps.eventData;

  const setChipContent = () => {
    if (!chipObj || !task) {
      return null;
    }

    return (
      // @TODO style-t basszuk ki sos munka vót
      <span className="truncate block" style={{ marginTop: '2px', marginBottom: '2px' }}>
        {chipObj.map((item, index) => {
          const { label } = item;
          let taskValue;

          if (label.includes('.')) {
            const [parentField, childField] = label.split('.');
            if (Array.isArray(task[parentField])) {
              taskValue = task[parentField].map((elem) => elem[childField]).join(', ');
            } else {
              taskValue = task[parentField] ? task[parentField][childField] : null;
            }
          } else {
            taskValue = task[label];
          }

          return (
            <span key={index}>
              <span className="text-amber-500 absolute text-3xl top-0">
                {index === 0 && task?.simpleTaskDescription && '* '}
              </span>
              {index > 0 && ' - '}
              <span className={`${task?.simpleTaskDescription && index === 0 && 'ml-4 '}`}>{taskValue}</span>
            </span>
          );
        })}
      </span>
    );
  };

  return <div>{setChipContent()}</div>;
};

export default ChipContent;
