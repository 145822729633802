import React, { useState, useEffect } from 'react';

import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';
import dayjs from 'dayjs';

import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import { useLoader } from '../../provider/LoaderProvider';
import absenceService from '../../service/absence.service';
import useUsers from '../../context/UsersContext';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI } from '../Interface';
import AbsenceFields from './AbsenceFields';
import AbsenceReports from './AbsenceReports';
import useMenus from '../../context/MenuContext';

export const AbsencePage = () => {
  const { getUser, user } = useUsers();
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const defaultValues = {
    startDate: dayjs(new Date()),
    endDate: dayjs(new Date()),
    userId: null,
    mainCategory: null,
    subCategory: null,
  };
  const { showLoader, hideLoader } = useLoader();
  const [initValues, setInitValues] = useState({});
  const [refetchTable, setRefetchTable] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    setInitValues(defaultValues);
    getUser();
    getMenus();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('absence');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  const validationSchema = Yup.object().shape({
    startDate: Yup.string().required(),
    endDate: Yup.string().required(),
    userId: Yup.string().required(),
    mainCategory: Yup.string().required(),
    subCategory: Yup.string().required(),
  });

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (selectedValues) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  const valuesToForm = () => {
    const { startDate, endDate, userId, mainCategory, subCategory } = selectedValues;

    setInitValues({
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      userId,
      mainCategory,
      subCategory,
    });
  };

  const handleInsertObject = (values, resetForm) => {
    showLoader();
    const insertObject = {
      ...values,
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
      createdBy: user.userId,
    };

    absenceService
      .createAbsence(insertObject)
      .then(() => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        resetForm();
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
        setInitValues({});
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values, resetForm);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
      updatedBy: user.userId,
    };

    absenceService.updateAbsence(updData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    absenceService.deleteAbsence(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultValues);
    });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-6 h-screen gap-2 p-4 bg-gray-100 mt-[-4rem] pt-[5rem]">
      <div className="col-span-1 lg:col-span-1 xl:col-span-2 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="h-full overflow-auto custom-scrollbar">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues({});
              setInitValues(defaultValues);
              setViewField(false);
              setAddField(false);
            }}
            addDisabled={!viewField || !canEdit}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled || !canEdit}
            copyClick={() => {
              setViewField(false);
              setAddField(false);
            }}
            copyDisabled={!viewField || operatorsDisabled || !canEdit}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, resetForm, setFieldValue, validateField }) => (
              <Form>
                <AbsenceFields
                  values={values}
                  errors={errors}
                  disabled={viewField}
                  setFieldValue={setFieldValue}
                  validateField={validateField}
                  selectedValues={selectedValues}
                />

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      resetForm();
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="col-span-1 lg:col-span-1 xl:col-span-4 overflow-hidden bg-white shadow sm:rounded-lg  ">
        <div className="h-full overflow-auto custom-scrollbar">
          <AbsenceReports
            refetch={{ refetchTable, setRefetchTable }}
            values={{ selectedValues, setSelectedValues }}
            viewField={viewField}
            operatorsDisabled={operatorsDisabled}
            user={user}
          />
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </div>
  );
};
