import { PropertyType } from '../config/property';

export const getDynVal = (dinVals, fieldName) => {
  if (!dinVals) return;
  const fieldId = parseInt(fieldName.slice('dynamicFields.din_'.length));
  return dinVals.find((v) => v.propertyId === fieldId);
};

export const getIndex = (fieldName) => {
  return fieldName.slice('dynamicFields.'.length);
};

export const getMultiFieldHeight = (characterLength) => {
  if (characterLength > 1 && characterLength <= 47) {
    return 1;
  } else if (characterLength > 47 && characterLength <= 94) {
    return 2;
  } else if (characterLength > 94 && characterLength <= 141) {
    return 3;
  } else if (characterLength > 141 && characterLength <= 188) {
    return 4;
  } else if (characterLength > 188 && characterLength <= 235) {
    return 5;
  } else if (characterLength > 235 && characterLength <= 282) {
    return 6;
  } else if (characterLength > 282 && characterLength <= 329) {
    return 7;
  } else {
    return 1;
  }
};

export const getDynEmptyVal = (type) => {
  return type === PropertyType.DATE ? null : '';
};

export const getDynVals = (dynFields, dynVals = []) => {
  return dynFields.reduce((acc, row) => {
    const findedProp = dynVals.find((val) => val.property.id === row.property.id);

    const findedVal =
      findedProp?.textVal ||
      findedProp?.numberVal ||
      findedProp?.longTextVal ||
      findedProp?.dateVal ||
      findedProp?.comboBoxVal;

    acc[`din_${row.property.id}`] = findedVal || row.property.defaultValue || getDynEmptyVal(row.property.type);
    return acc;
  }, {});
};
