import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'propertyGroup';

const propertyGroupService = {
  createPropertyGroup: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  getPropertyGroups: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/propertyGroups`,
      data,
    });
  },

  updatePropertyGroup: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  deletePropertyGroup: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },
};

export default propertyGroupService;
