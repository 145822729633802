import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';

import ButtonUI from './ButtonUI';
import DataGridUI from './Datagrid/DataGridUI';
import { DataGridIdentifiers } from './Datagrid/DataGridIdentifiers';
import FileUploadDialogUI from './FileUploadDialogUI';
import { dataGridColumns } from '../../utils/helper';

const FileTabUI = ({ handleUpload, disabled, savedFiles, handleDelete, ...props }) => {
  const [uploadDialog, setUploadDialog] = useState(false);

  const handleClick = () => {
    setUploadDialog(true);
  };
  const handleClose = () => {
    setUploadDialog(false);
  };

  const handleDownload = (data) => {
    const url = `${process.env.REACT_APP_API_URL}/${data.path}`;
    window.open(url, '_blank');
  };

  const columns = dataGridColumns([
    {
      field: 'name',
      headerName: 'Melléklet neve',
      flex: 1,
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Műveletek',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => handleDownload(params.row)}>
            {params.row.extension === '.pdf' ? <VisibilityIcon /> : <DownloadIcon />}
          </IconButton>
          <IconButton disabled={disabled} onClick={() => handleDelete(params.row)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ]);

  return (
    <>
      <ButtonUI className="bg-success mb-1" text="Új fájl" disabled={disabled} onClick={handleClick} />

      <div className="overflow-x-auto">
        <div className="align-middle">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <DataGridUI
              datagridIdentifier={DataGridIdentifiers.uploadFile}
              sx={{ height: 400 }}
              columns={columns}
              rows={savedFiles}
            />
          </div>
        </div>
      </div>

      <FileUploadDialogUI
        open={uploadDialog}
        handleClose={handleClose}
        handleUpload={(files) => {
          handleUpload(files);
          handleClose();
        }}
        {...props}
      />
    </>
  );
};

export default FileTabUI;
