import { useEffect, useState } from 'react';
import appService from '../../service/app.service';

import { ButtonUI } from '../Interface';
import { AddRole } from './AddRole';

export default function RoleList({ roleState }) {
  const { roleId, setRoleId } = roleState;
  const [role, setRole] = useState([]);

  const [showAddDialog, setShowAddDialog] = useState(false);

  const fetchData = async () => {
    appService
      .getRole()
      .then((data) => {
        setRole(data);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchData();
  }, [setRole]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="mx-3 mb-2">
        <ButtonUI
          type="button"
          text="Hozzáadás"
          onClick={() => {
            setShowAddDialog(true);
          }}
          className="bg-success"
        >
          Hozzáadás
        </ButtonUI>
      </div>
      <div className="max-h-[calc(100vh-10rem)] overflow-y-auto px-3 pt-3 custom-scrollbar">
        {role &&
          role.map((p) => (
            <div
              key={p.roleId}
              onClick={() => setRoleId(p.roleId)}
              className={`flex flex-col items-center h-16 justify-center text-center border border-gray-200 rounded-md py-3 cursor-pointer p-4 ${
                roleId === p.roleId ? 'bg-gray-300' : 'bg-white hover:bg-gray-200'
              }`}
            >
              <p className="text-d font-semibold text-gray-800 text-sm">{p.roleName}</p>
            </div>
          ))}
      </div>

      <AddRole
        show={showAddDialog}
        onHide={() => setShowAddDialog(false)}
        fetchData={fetchData}
        role={role}
        setRole={setRole}
      />
    </div>
  );
}
