import React, { useEffect, useMemo, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import huLocale from '@fullcalendar/core/locales/hu';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import useParam from '../../context/ParamContext';
import { useLoader } from '../../provider/LoaderProvider';
import useView from '../../hooks/useView';
import GanttFilter from './GanttFilter';
import { GanttType } from '../../config/gantt';
import projectResourcePlannerService from '../../service/projectResourcePlanner.service';
import useMenus from '../../context/MenuContext';
import { ColorPicker } from '../../config/ColorPicker';
import addNotification from '../../utils/addNotification';
import { generateFilter } from '../../utils/helper';
import { NotificationType } from '../../config';
import simpletaskService from '../../service/simpletask.service';
import useSaveView from '../../hooks/useSaveView';
import SimpleTaskDialog from './SimpleTaskDialog';
import { StatusDialog } from './StatusDialog';
import EventChip from './EventChip';
import { DynamicColDialog } from './DynamicColDialog';
import { CheckboxUI, TooltipUI } from '../Interface';
import { Typography } from '@mui/material';
import useUsers from '../../context/UsersContext';
import { SubCategoryOption } from '../../config/absence';
import { isEqual } from 'lodash';
import { ActiveTimeDialog } from './ActiveTimeDialog';
import { ResourceColumnDynamicColsObj } from '../../config/projectResPlanner';

export const ProjectResourcePlanner = () => {
  const { getUser, user } = useUsers();
  const { getParam, params } = useParam();
  const { menus, getMenus, getSubMenuNamesByUrls, getMenuSubMenuId, getCanEdit } = useMenus();
  const ganttFilter = useView('ganttFilter', user);
  const saveView = useSaveView(user);
  const { showLoader, hideLoader } = useLoader();
  const [tasks, setTasks] = useState({ resources: [], taskEvents: [], backgroundEvents: [] });
  const [type, setType] = useState('');
  const [dateRange, setDateRange] = useState({});
  const [yearViewDateRange, setYearViewDateRange] = useState({});
  const filtersRef = useRef();
  const requestCounter = useRef(0);
  const calendarRef = useRef(null);
  const [task, setTask] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [taskChanged, setTaskChanged] = useState(false);
  const [submenu, setSubmenu] = useState(null);
  const [simpleTaskDialogOpen, setSimpleTaskDialogOpen] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [activeTimeDialogOpen, setActiveTimeDialogOpen] = useState(false);
  const [dynamicColDialogOpen, setDynamicColDialogOpen] = useState(false);
  const [expandedResources, setExpandedResources] = useState(true);
  const [order, setOrder] = useState(true);
  const [filterVal, setFilterVal] = useState({
    statusId: [],
    active: '',
    resourceId: '',
    projectName: '',
  });
  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [{ field: 'visOrder', sort: 'asc' }],
    },
  });
  const [canEdit, setCanEdit] = useState(true);
  const [dynamicColsVisibility, setDynamicColsVisibility] = useState({
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
  });

  const [timeInterval, setTimeInterval] = useState({});
  const [intervalButtons, setIntervalButtons] = useState({});
  const [calendarViews, setCalendarViews] = useState({});
  const [selectedView, setSelectedView] = useState('');
  const [copiedEvent, setCopiedEvent] = useState(null);
  const [showWorkhour, setShowWorkHour] = useState(true);
  const [disappearedWorkHour, setDisappearedWorkHour] = useState(true);
  const [activeTime, setActiveTime] = useState(false);
  const [yearViewActiveTime, setYearViewActiveTime] = useState(false);
  const [showActiveTimeCheckbox, setShowActiveTimeCheckbox] = useState(true);

  const slotIntervals = {
    halfHour: { hours: 0.5 },
    hour: { hours: 1 },
    day: { days: 1 },
    week: { weeks: 1 },
    month: { months: 1 },
  };

  const calendarHourViews = {
    resourceTimelineDay: {
      slotLabelFormat: [{ hour: 'numeric', minute: '2-digit' }],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [
        { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' },
        { hour: 'numeric', minute: '2-digit' },
      ],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [
        { day: 'numeric', weekday: 'long' },
        { hour: 'numeric', minute: '2-digit' },
      ],
    },
  };

  const calendarDayViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [{ day: 'numeric', weekday: 'long' }],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [{ week: 'numeric' }, { day: 'numeric', weekday: 'short' }],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ week: 'numeric' }, { month: 'short' }, { day: 'numeric', weekday: 'short' }],
    },
  };

  const calendarWeekViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
  };

  const calendarMonthViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ month: 'long' }],
    },
  };

  const conditions = {
    like: ['projectName'],
  };

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'archive',
        value: 'N',
      },
    ];
    const newStatus = filterVal.statusId.map((sIds) => sIds.value);
    const newFilterVal = { ...filterVal, statusId: newStatus };
    const newFilter = generateFilter(newFilterVal, conditions);

    if (JSON.stringify(query.filter) !== JSON.stringify([...defaultFilter, ...newFilter])) {
      setQuery((prev) => ({
        ...prev,
        filter: [...defaultFilter, ...newFilter],
      }));
    }
  }, [filterVal]);

  useEffect(() => {
    if (selectedView === 'resourceTimelineYear') {
      setShowActiveTimeCheckbox(false);
    } else {
      setShowActiveTimeCheckbox(true);
    }
  }, [selectedView]);

  useEffect(() => {
    const projectSubmenuId = getMenuSubMenuId('Project');
    setSubmenu(projectSubmenuId);

    const resourcePlannerSubmenuId = getMenuSubMenuId('projectResourcePlanner');
    setCanEdit(getCanEdit(resourcePlannerSubmenuId));
  }, [menus]);

  const submenuNames = useMemo(() => {
    return getSubMenuNamesByUrls([
      'resourceOne',
      'resourceTwo',
      'resourceThree',
      'resourceFour',
      'machine',
      'employeeResourcePlanner',
    ]);
  }, [menus]);

  useEffect(() => {
    if (ganttFilter && ganttFilter.selectedResource) {
      setType(ganttFilter.selectedResource);
    } else {
      setType(GanttType.RESOURCE_ONE);
    }

    if (ganttFilter?.sortModel) {
      setQuery((prev) => {
        if (JSON.stringify(prev.sorting?.sortModel) !== JSON.stringify(ganttFilter.sortModel)) {
          return {
            ...prev,
            sorting: {
              ...prev.sorting,
              sortModel: ganttFilter.sortModel,
            },
          };
        }
        return prev;
      });

      const savedSortDirection = ganttFilter.sortModel[0]?.sort === 'asc';
      setOrder((prevOrder) => {
        if (prevOrder !== savedSortDirection) {
          return savedSortDirection;
        }
        return prevOrder;
      });
    }

    if (ganttFilter?.selectedView && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(ganttFilter.selectedView);
    }

    if (ganttFilter?.dynamicCols) {
      setDynamicColsVisibility((prevVisibility) => ({
        ...prevVisibility,
        ...ganttFilter.dynamicCols,
      }));
    }

    if (Object.keys(ganttFilter).length > 0) {
      setFilterVal((prev) => ({
        ...prev,
        statusId: ganttFilter.status ?? [],
        active: ganttFilter.activeProjects ?? '',
      }));
    }

    if (ganttFilter && ganttFilter.activeTime) {
      setActiveTime(ganttFilter.activeTime);
    }

    if (ganttFilter && ganttFilter.yearViewActiveTime) {
      setYearViewActiveTime(ganttFilter.yearViewActiveTime);
    }
  }, [ganttFilter]);

  useEffect(() => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    setTimeInterval(slotIntervals.halfHour);
    setCalendarViews(calendarHourViews);

    // @TODO promiseArray
    getParam();
    getMenus();
    getUser();

    const toolbarElement = document.querySelector('.fc-toolbar-chunk');
    if (toolbarElement && filtersRef.current) {
      toolbarElement.appendChild(filtersRef.current);
    }
  }, []);

  useEffect(() => {
    if (taskChanged) {
      getTasks();
      setTaskChanged(false);
    }
  }, [taskChanged]);

  useEffect(() => {
    setIntervalButtons(generateButtons(selectedView));
  }, [selectedView]);

  useEffect(() => {
    if (ganttFilter?.selectedView && timeInterval) {
      setSelectedView(ganttFilter.selectedView);

      const getSlotLabelFormat = () => {
        const view = ganttFilter.selectedView;

        if (timeInterval.hours === 0.5) {
          return calendarHourViews[view]?.slotLabelFormat || [];
        } else if (timeInterval.hours === 1) {
          return calendarHourViews[view]?.slotLabelFormat || [];
        } else if (timeInterval.days === 1) {
          return calendarDayViews[view]?.slotLabelFormat || [];
        } else if (timeInterval.weeks === 1) {
          return calendarWeekViews[view]?.slotLabelFormat || [];
        } else if (timeInterval.months === 1) {
          return calendarMonthViews[view]?.slotLabelFormat || [];
        } else {
          return [];
        }
      };

      if (timeInterval.days === 1 && selectedView !== 'resourceTimelineYear') {
        setDisappearedWorkHour(false);
      } else {
        setDisappearedWorkHour(true);
        setShowWorkHour(false);
      }

      setCalendarViews((prevViews) => {
        const newViewConfig = {
          slotLabelFormat: getSlotLabelFormat(),
        };

        return {
          ...prevViews,
          [ganttFilter.selectedView]: {
            ...prevViews[ganttFilter.selectedView],
            ...newViewConfig,
          },
        };
      });
    }
  }, [ganttFilter?.selectedView, timeInterval]);

  useEffect(() => {
    if (ganttFilter.timeInterval) {
      setTimeInterval(ganttFilter.timeInterval);
    }
  }, [ganttFilter.timeInterval, selectedView]);

  useEffect(() => {
    if (
      Object.keys(ganttFilter).length > 0 &&
      ganttFilter?.activeTimeDate?.startDate &&
      ganttFilter?.activeTimeDate?.endDate &&
      selectedView === 'resourceTimelineYear'
    ) {
      setYearViewDateRange({
        startDate: dayjs(ganttFilter.activeTimeDate.startDate),
        endDate: dayjs(ganttFilter.activeTimeDate.endDate),
      });
    }
  }, [ganttFilter, selectedView]);

  useEffect(() => {
    if (
      Object.keys(user).length > 0 &&
      type !== '' &&
      type !== undefined &&
      dateRange.startDate !== null &&
      dateRange.endDate !== null &&
      yearViewDateRange.endDate !== null &&
      yearViewDateRange.endDate !== null &&
      query.filter?.length > 0
    ) {
      if (type !== GanttType.RESOURCE_ONE) {
        setFilterVal((prevState) => ({
          ...prevState,
          resourceId: '',
        }));
      }

      if (
        type === GanttType.RESOURCE_ONE ||
        (type !== GanttType.RESOURCE_ONE &&
          filterVal.resourceId === '' &&
          dateRange.startDate &&
          dateRange.endDate &&
          yearViewDateRange.startDate &&
          yearViewDateRange.endDate)
      ) {
        getProjectResourcesByType();
      }

      if (type === GanttType.RESOURCE_ONE && selectedView !== 'resourceTimelineYear') {
        setDisappearedWorkHour(false);
      } else {
        setDisappearedWorkHour(true);
        setShowWorkHour(false);
      }
    }
  }, [user, type, activeTime, yearViewActiveTime, query.filter]);

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate && type !== '' && tasks.resources.length > 0) {
      getTasks();
      if (showWorkhour) {
        getEstimatedTimes();
      } else {
        setTasks((prev) => ({ ...prev, backgroundEvents: [] }));
      }
    }
  }, [dateRange, type, tasks.resources, showWorkhour]);

  useEffect(() => {
    if (selectedView && timeInterval && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      if (calendarApi) {
        setTimeout(() => {
          scrollToCurrentTimeUnit();
        }, 200);
      }
    }
  }, [selectedView, calendarRef, timeInterval]);

  const colorParam = params.PROJECT_RESOURCE_PLANNER_CHIP_COLOR_THEME;
  const parsedParam = colorParam ? JSON.parse(colorParam) : [];

  const getTasks = async () => {
    const normalTasks = await projectResourcePlannerService.getTasksByType(type, dateRange);

    const taskEvents = [];

    normalTasks.forEach((task) => {
      const { companyHolidays, provinceHoliday, resourceId, eventData } = task;

      const colorStyles = getColorStyles(eventData, parsedParam[0], 'PROJECT');

      const taskEvent = {
        ...task,
        ...colorStyles,
      };
      taskEvents.push(taskEvent);

      if (companyHolidays) {
        companyHolidays.forEach((holiday) => {
          taskEvents.push({
            start: dayjs(holiday.fromDate).startOf('day').toISOString(),
            end: dayjs(holiday.toDate).endOf('day').toISOString(),
            resourceId: resourceId,
            display: 'background',
            rendering: 'background',
            backgroundColor: '#fde68a',
          });
        });
      }

      if (provinceHoliday) {
        provinceHoliday.forEach((holiday) => {
          if (holiday.startDate && holiday.endDate) {
            taskEvents.push({
              start: dayjs(holiday.startDate).startOf('day').toISOString(),
              end: dayjs(holiday.endDate).endOf('day').toISOString(),
              resourceId: resourceId,
              display: 'background',
              rendering: 'background',
              backgroundColor: '#fde047',
            });
          }
        });
      }
    });

    setTasks((prevState) => ({ ...prevState, taskEvents }));
  };

  const getEstimatedTimes = async () => {
    const estimatedTimes = await projectResourcePlannerService.getWorkHours(dateRange);
    generateWorkHourEvents(estimatedTimes);
  };

  const getProjectResourcesByType = () => {
    showLoader();

    const { startDate, endDate } = yearViewDateRange;

    const reqData = {
      query,
      ...(selectedView === 'resourceTimelineYear' &&
        yearViewActiveTime && {
          dateRange: {
            startDate: dayjs(startDate),
            endDate: dayjs(endDate).subtract(1, 'day'),
          },
        }),
    };

    const currentRequestId = ++requestCounter.current;

    projectResourcePlannerService
      .getProjectResourcesByType(type, reqData)
      .then((data) => {
        if (currentRequestId === requestCounter.current) {
          if (data) {
            const projects = data.map((project) => ({
              ...project,
              title: project?.generatedName ?? project.title,
              children: project.children.map((child) => ({
                ...child,
                title: child?.more?.generatedName ?? child?.title,
              })),
            }));

            setTasks((prevState) => ({ ...prevState, resources: projects }));
          }
        }
      })
      .finally(() => {
        if (currentRequestId === requestCounter.current) {
          hideLoader();
        }
      });
  };

  const generateWorkHourEvents = (estimatedTimes) => {
    const datesArray = [];

    let currentDate = dateRange.startDate;
    while (currentDate.isBefore(dateRange.endDate) || currentDate.isSame(dateRange.endDate)) {
      datesArray.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'day');
    }

    const workHourEvents = [];
    let unknownProject = false;

    const groupedTimes = estimatedTimes.reduce((acc, et) => {
      const date = dayjs(et.date).format('YYYY-MM-DD');
      if (!acc[date]) {
        acc[date] = {};
      }
      acc[date][et.projectResourceId] = et;
      return acc;
    }, {});

    for (const estimatedTime of estimatedTimes) {
      const resourceId = estimatedTime.projectResourceId;
      unknownProject = !!!estimatedTime.projectResourceId;

      if (estimatedTime.absence) {
        for (const absence of estimatedTime.absence) {
          for (const date of datesArray) {
            const findedTime = groupedTimes[date]?.[resourceId];

            if (dayjs(date).isBetween(dayjs(absence.startDate), dayjs(absence.endDate), 'day', '[]')) {
              const subCategoryOption = SubCategoryOption[absence.mainCategory]?.find(
                (sub) => sub.value === absence.subCategory
              );

              workHourEvents.push({
                title: `${subCategoryOption?.code ?? ''} ${findedTime?.timeSpent ?? 0}`,
                start: dayjs(date).startOf('day').toISOString(),
                end: dayjs(date).endOf('day').toISOString(),
                resourceId: `M${resourceId}`,
                backgroundColor: subCategoryOption?.color ?? 'transparent',
                display: 'background',
                eventData: {
                  elapsedHour: true,
                },
              });
            }
          }
        }
      }

      for (const date of datesArray) {
        const hasAbsenceOnDate = estimatedTime.absence?.find((absence) =>
          dayjs(date).isBetween(dayjs(absence.startDate), dayjs(absence.endDate), 'day', '[]')
        );

        if (!hasAbsenceOnDate && dayjs(estimatedTime.date).isSame(date, 'day')) {
          const findedTime = groupedTimes[date]?.[resourceId];

          workHourEvents.push({
            title: findedTime?.timeSpent ?? 0,
            start: dayjs(date).startOf('day').toISOString(),
            end: dayjs(date).endOf('day').toISOString(),
            resourceId: `M${resourceId}`,
            backgroundColor: 'transparent',
            display: 'background',
            eventData: {
              elapsedHour: true,
            },
          });
        }
      }
    }

    setTasks((prev) => ({ ...prev, backgroundEvents: workHourEvents }));

    if (unknownProject) {
      addNotification({
        content: 'Nem minden időadat jeleníthető meg! A dolgozó nincs hozzáadva a projekthez!',
        type: NotificationType.ERROR,
      });
    }
  };

  const getTaskById = (simpleTaskId) => {
    showLoader();
    simpletaskService
      .getTaskById(simpleTaskId)
      .then((data) => {
        setTask(data);
        setDialogOpen(true);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getColorStyles = (event, colorProperties, eventType) => {
    const defaultStyles = { backgroundColor: '#fff', textColor: '#000' };

    const colorProperty = colorProperties?.[eventType];
    if (!colorProperty) return defaultStyles;

    const colorName = colorProperty.split('.').reduce((obj, key) => obj?.[key], event);
    if (!colorName) return defaultStyles;

    const colorObj = ColorPicker.find((color) => color.colorName === colorName);
    return {
      backgroundColor: colorObj?.colorBgCode || defaultStyles.backgroundColor,
      textColor: colorObj?.textColor || defaultStyles.textColor,
    };
  };

  const updateEvent = (info) => {
    const { event, revert } = info;

    const resource = event.getResources()[0].id;
    const resourceId = parseInt(resource.substring(1));

    const isResource = resource.startsWith('M');

    if (!isResource) {
      addNotification({
        content: 'Az esemény nem helyezhető csoportra!',
        type: NotificationType.ERROR,
      });
      revert();
      return;
    }

    const projectResources = event._def.extendedProps.eventData.project;

    const durationInMinutes = dayjs(event.end).diff(dayjs(event.start), 'minute');
    const duration = durationInMinutes / 60;
    const startDate = dayjs(event.start).toISOString();
    const endDate = dayjs(event.end).toISOString();

    const updatedEventData = {
      startDate,
      endDate,
      duration,
    };

    let resourceUpdated = false;

    Object.entries(projectResources).forEach(([key, resourceArray]) => {
      if (key.startsWith('projectResource')) {
        const matchedResource = resourceArray.find((res) => res.listId === resourceId);
        if (matchedResource) {
          const newKey = key.replace('projectResource', 'resource') + 'Id';
          updatedEventData[newKey === 'resourceFiveId' ? 'machineId' : newKey] = matchedResource.resourceId;
          resourceUpdated = true;
        }
      }
    });

    if (!resourceUpdated) {
      addNotification({
        content: 'A feladatok csak a saját projektjükön belül mozgathatóak!',
        type: NotificationType.WARNING,
      });
      revert();
      return;
    }

    const simpleTaskId = event.id;

    projectResourcePlannerService
      .updateTask(updatedEventData, simpleTaskId)
      .then(() => {
        addNotification({
          content: 'Sikeres módosítás!',
          type: NotificationType.SUCCESS,
        });
        getTasks();
      })
      .catch(() => {
        revert();
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleDatesSet = (dateInfo) => {
    const startDate = dayjs(dateInfo.start).startOf('day');
    const endDate = dayjs(dateInfo.end).endOf('day');

    if (
      !dateRange.startDate ||
      !dateRange.endDate ||
      !startDate.isSame(dateRange.startDate) ||
      !endDate.isSame(dateRange.endDate)
    ) {
      setDateRange({ startDate, endDate });
    }

    if (selectedView !== dateInfo.view.type) {
      setSelectedView(dateInfo.view.type);

      if (Object.keys(ganttFilter).length > 0) {
        //@TODO ez itt az összes ganttnak a selectedviewját felülveri
        saveView('ganttFilter', { selectedView: dateInfo.view.type });
      } else {
        return;
      }
    }
  };

  const timeIntervalButtons = {
    halfHour: {
      text: 'Fél óra',
      click: () => {
        setTimeInterval(slotIntervals.halfHour);
        setCalendarViews(calendarHourViews);
        saveView('ganttFilter', { timeInterval: slotIntervals.halfHour });
      },
    },
    hour: {
      text: 'Óra',
      click: () => {
        setTimeInterval(slotIntervals.hour);
        setCalendarViews(calendarHourViews);
        saveView('ganttFilter', { timeInterval: slotIntervals.hour });
      },
    },
    day: {
      text: 'Nap',
      click: () => {
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);
        saveView('ganttFilter', { timeInterval: slotIntervals.day });
      },
    },
    week: {
      text: 'Hét',
      click: () => {
        setTimeInterval(slotIntervals.week);
        setCalendarViews(calendarWeekViews);
        saveView('ganttFilter', { timeInterval: slotIntervals.week });
      },
    },
    month: {
      text: 'Hónap',
      click: () => {
        setTimeInterval(slotIntervals.month);
        setCalendarViews(calendarMonthViews);
        saveView('ganttFilter', { timeInterval: slotIntervals.month });
      },
    },
  };

  const generateButtons = (viewName) => {
    let retVal = {};
    switch (viewName) {
      case 'resourceTimelineDay':
        retVal = 'halfHour,hour,day';
        setTimeInterval(slotIntervals.halfHour);
        setCalendarViews(calendarHourViews);

        break;
      case 'resourceTimelineWeek':
        retVal = 'halfHour,hour,day,week';
        setTimeInterval(slotIntervals.halfHour);
        setCalendarViews(calendarHourViews);

        break;
      case 'resourceTimelineMonth':
        retVal = 'hour,day,week,month';
        setTimeInterval(slotIntervals.hour);
        setCalendarViews(calendarHourViews);

        break;
      case 'resourceTimelineYear':
        retVal = 'day,week,month';
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);

        break;
    }
    return retVal;
  };

  const indexToWordMap = {
    0: 'One',
    1: 'Two',
    2: 'Three',
    3: 'Four',
    4: 'Five',
  };

  const handleDateClick = (info) => {
    if (!canEdit) {
      return;
    }
    if (copiedEvent) {
      const resource = info.resource.id;
      const projectResources = copiedEvent.extendedProps.eventData.project;
      const resourceId = parseInt(resource.substring(1));

      let matchedResources = [];
      Object.entries(projectResources).forEach(([key, resourceArray]) => {
        if (key.startsWith('projectResource')) {
          const matchedResource = resourceArray.find((res) => res.listId === resourceId);
          if (matchedResource) {
            matchedResources.push({ resourceId: matchedResource.resourceId, key });
          }
        }
      });

      if (matchedResources.length === 0) {
        addNotification({
          content: 'Nem található megfelelő erőforrás!',
          type: NotificationType.ERROR,
        });
        return;
      }

      let newEvent = {
        ...copiedEvent.extendedProps.eventData,
        simpleTaskName: `${copiedEvent.title}`,
        startDate: dayjs(info.date).toISOString(),
        endDate: dayjs(info.date).add(dayjs(copiedEvent.end).diff(copiedEvent.start, 'minute'), 'minute').toISOString(),
      };

      Object.keys(newEvent).forEach((key) => {
        if (key.startsWith('resource') && key.endsWith('Id')) {
          delete newEvent[key];
        }
      });

      matchedResources.forEach(({ resourceId, key }) => {
        const resourceIndex = Object.keys(indexToWordMap).find((keyIndex) => key.endsWith(indexToWordMap[keyIndex]));
        const resourceKey = `resource${indexToWordMap[resourceIndex]}Id`;
        newEvent[resourceKey] = resourceId;
      });

      showLoader();

      simpletaskService
        .createSimpleTask(newEvent)
        .then((createdTask) => {
          setTasks((prevState) => ({
            ...prevState,
            taskEvents: [
              ...prevState.taskEvents,
              {
                ...newEvent,
                id: createdTask.simpleTaskId,
                title: newEvent.simpleTaskName,
                resourceId: resourceId,
                backgroundColor: copiedEvent.backgroundColor,
                textColor: copiedEvent.textColor,
              },
            ],
          }));
          setCopiedEvent(null);
        })
        .finally(() => {
          hideLoader();
          getTasks();
        });
    }
  };

  const projectInterval = tasks.resources.flatMap((resource) => {
    const today = dayjs();

    const startDate = dayjs(resource.startDate);
    const endDate = dayjs(resource.endDate);

    const startDaysDifference = startDate.diff(today, 'day');
    const endDaysDifference = endDate.diff(today, 'day');

    let backgroundColor = '#bbf7d0';

    if (endDate.isBefore(today, 'day')) {
      backgroundColor = '#9ca3af';
    } else if (startDaysDifference <= 7 && startDaysDifference >= 0) {
      backgroundColor = '#1185f2';
    } else if (endDaysDifference <= 21 && endDaysDifference > 14) {
      backgroundColor = '#fdba74';
    } else if (endDaysDifference <= 14 && endDaysDifference >= 0) {
      backgroundColor = '#f87171';
    }

    const intervals = [];

    intervals.push({
      start: dayjs(resource.startDate).startOf('day').toISOString(),
      end: dayjs(resource.endDate).add(1, 'day').startOf('day').toISOString(),
      resourceId: resource.id,
      display: 'background',
      backgroundColor: backgroundColor,
    });

    return intervals;
  });

  const toggleResourceGroup = () => {
    setExpandedResources((prev) => !prev);
  };

  const resourceColumns = [
    {
      field: '',
      headerContent: '',
      headerClassNames: Object.values(dynamicColsVisibility).every((value) => value === false) ? 'hide' : 'col0',
    },
    {
      field: 'col1',
      headerContent: ResourceColumnDynamicColsObj[0]?.name,
      headerClassNames: !dynamicColsVisibility.col1 ? 'hide' : 'col1',
      cellContent: function (arg) {
        const isParent = !arg.resource._resource.parentId;
        if (isParent) {
          const dynamicProperties = arg.resource._resource.extendedProps.dynamicProperties;

          const foundValue = dynamicProperties?.find(
            (dp) => dp.property && dp.property.name === ResourceColumnDynamicColsObj[0]?.dynPropertyName
          );

          return foundValue?.textVal;
        } else return '';
      },
      cellClassNames: function (_arg) {
        const classNames = [!dynamicColsVisibility.col1 ? 'hide extraCol' : 'extraCol'];

        return classNames;
      },
    },
    {
      field: 'col2',
      headerContent: ResourceColumnDynamicColsObj[1]?.name,
      headerClassNames: !dynamicColsVisibility.col2 ? 'hide' : 'col2',
      cellContent: function (arg) {
        const isParent = !arg.resource._resource.parentId;
        if (isParent) {
          const dynamicProperties = arg.resource._resource.extendedProps.dynamicProperties;

          const foundValue = dynamicProperties?.find(
            (dp) => dp.property?.name === ResourceColumnDynamicColsObj[1]?.dynPropertyName
          );

          if (foundValue) {
            return foundValue.property.propertyOption?.find((option) => option.id === foundValue?.comboBoxVal)?.name;
          }
        } else return '';
      },

      cellClassNames: function (_arg) {
        const classNames = [!dynamicColsVisibility.col2 ? 'hide extraCol' : 'extraCol'];

        return classNames;
      },
    },
    {
      field: 'col3',
      headerContent: ResourceColumnDynamicColsObj[2]?.name,
      headerClassNames: !dynamicColsVisibility.col3 ? 'hide' : 'col3',
      cellContent: function (arg) {
        const isParent = !arg.resource._resource.parentId;
        if (!isParent) {
          const dynamicProperties = arg.resource._resource.extendedProps.dynamicProperties;

          const foundValue = dynamicProperties?.find(
            (dp) => dp.property?.name === ResourceColumnDynamicColsObj[2]?.dynPropertyName
          );

          if (foundValue) {
            return foundValue.property.propertyOption?.find((option) => option.id === foundValue?.comboBoxVal)?.name;
          }
        } else return '';
      },
      cellClassNames: function (_arg) {
        const classNames = [!dynamicColsVisibility.col3 ? 'hide extraCol' : 'extraCol'];

        return classNames;
      },
    },
    {
      field: 'col4',
      headerContent: ResourceColumnDynamicColsObj[3]?.name,
      headerClassNames: !dynamicColsVisibility.col4 ? 'hide' : 'col4',
      cellContent: function (arg) {
        const educationDate = arg.resource.extendedProps?.educationDate;
        const formattedDate = educationDate ? dayjs(educationDate).format('YYYY-MM-DD') : null;
        return formattedDate;
      },
      cellClassNames: function (_arg) {
        const classNames = [!dynamicColsVisibility.col4 ? 'hide extraCol' : 'extraCol biggerCol'];

        return classNames;
      },
    },
    {
      field: 'col5',
      headerContent: ResourceColumnDynamicColsObj[4]?.name,
      headerClassNames: !dynamicColsVisibility.col5 ? 'hide' : 'col5',
      cellContent: function (arg) {
        const orderNumber = arg.resource.extendedProps?.orderNumber;
        return orderNumber;
      },
      cellClassNames: function (_arg) {
        const classNames = [!dynamicColsVisibility.col5 ? 'hide extraCol' : 'extraCol biggerCol'];
        return classNames;
      },
    },

    {
      field: 'col6',
      headerContent: ResourceColumnDynamicColsObj[5]?.name,
      headerClassNames: !dynamicColsVisibility.col6 ? 'hide' : 'col6',
      cellContent: function (arg) {
        const isParent = !arg.resource._resource.parentId;
        if (isParent) {
          const dynamicProperties = arg.resource._resource.extendedProps.dynamicProperties;

          const foundValue = dynamicProperties?.find(
            (dp) => dp.property && dp.property.name === ResourceColumnDynamicColsObj[5]?.dynPropertyName
          );

          return foundValue?.dateVal && dayjs(foundValue?.dateVal).format('YYYY-MM-DD');
        } else return '';
      },
      cellClassNames: function (_arg) {
        const classNames = [!dynamicColsVisibility.col6 ? 'hide extraCol' : 'extraCol datebiggerCol'];
        return classNames;
      },
    },
  ];

  const filteredResourceColumns = resourceColumns.filter((column) => {
    return !column.headerClassNames?.includes('hide');
  });

  filteredResourceColumns.forEach((column, index) => {
    let rightPosition = 0;

    for (let i = index + 1; i < filteredResourceColumns.length; i++) {
      const columnWidth = filteredResourceColumns[i].headerClassNames.includes('col6')
        ? 120
        : filteredResourceColumns[i].headerClassNames.includes('col4') ||
            filteredResourceColumns[i].headerClassNames.includes('col5')
          ? 110
          : 35;
      rightPosition += columnWidth;
    }

    document.querySelectorAll(`.${column.headerClassNames.replace('extraCol', '').trim()}`).forEach((el) => {
      el.style.right = `${rightPosition}px`;
    });
  });

  const handleChangeDynamicCol = (index) => {
    const key = `col${index + 1}`;

    setDynamicColsVisibility((prev) => {
      const updatedVisibility = {
        ...prev,
        [key]: !prev[key],
      };

      const updatedDynamicCols = {
        ...ganttFilter?.dynamicCols,
        [key]: updatedVisibility[key],
      };

      saveView('ganttFilter', { dynamicCols: updatedDynamicCols });

      return updatedVisibility;
    });
  };

  const scrollToCurrentTimeUnit = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const today = dayjs();

      let timeUnit;
      let difference;

      switch (selectedView) {
        case 'resourceTimelineMonth':
          timeUnit = 'day';
          const startOfMonth = dayjs().startOf('month');
          difference = today.diff(startOfMonth, timeUnit);
          break;
        case 'resourceTimelineWeek':
          timeUnit = 'day';
          const startOfWeek = today.startOf('week').add(1, 'day');
          difference = today.diff(startOfWeek, timeUnit);
          break;
        case 'resourceTimelineYear':
          timeUnit = 'month';
          difference = today.diff(dayjs().startOf('year'), timeUnit);
          break;
        default:
          timeUnit = 'month';
          difference = today.diff(dayjs().startOf('year'), timeUnit);
          break;
      }

      calendarApi.scrollToTime({ [timeUnit]: difference });
    }
  };

  const quitStatus = params.PROJECT_RES_PLANNER_QUIT_STATUS
    ? parseInt(params.PROJECT_RES_PLANNER_QUIT_STATUS, 10)
    : null;

  const getResourceLabelClassNames = (resource) => {
    if (!resource._resource.parentId) {
      return ['fc-res-group'];
    }
    if (resource.extendedProps.leader === true) {
      return ['project-leader'];
    }
    if (resource.extendedProps?.status?.itemId === quitStatus) {
      return ['quit-status'];
    }
    if (resource.extendedProps.isLeaderInGroup === false) {
      return ['has-no-leader'];
    } else {
      return ['has-leader'];
    }
  };

  const handleSortModelChange = (newSortModel) => {
    setQuery((prev) => ({
      ...prev,
      sorting: {
        ...prev.sorting,
        sortModel: newSortModel,
      },
    }));
    saveView('ganttFilter', { sortModel: newSortModel });
  };

  const handleSelect = (selectInfo) => {
    const { resource } = selectInfo;
    const calendarApi = selectInfo.view.calendar;

    removePreviousBackgroundEvents(calendarApi);

    const selectedResourceId = resource.id;

    const start = dateRange.startDate.startOf('day').toDate();
    const end = dateRange.endDate.endOf('day').toDate();

    calendarApi.addEvent({
      start: start,
      end: end,
      resourceId: selectedResourceId,
      display: 'background',
      backgroundColor: 'rgba(55, 65, 81, 0.8)',
      extendedProps: {
        isSelection: true,
      },
    });
  };

  const handleResourceLabelClick = (resource, calendarApi) => {
    removePreviousBackgroundEvents(calendarApi);

    const start = dateRange.startDate.startOf('day').toDate();
    const end = dateRange.endDate.endOf('day').toDate();

    calendarApi.addEvent({
      start: start,
      end: end,
      resourceId: resource.id,
      display: 'background',
      backgroundColor: 'rgba(55, 65, 81, 0.8)',
      extendedProps: {
        isSelection: true,
      },
    });
  };

  const removePreviousBackgroundEvents = (calendarApi) => {
    calendarApi.getEvents().forEach((event) => {
      if (event.display === 'background' && event.extendedProps.isSelection) {
        event.remove();
      }
    });
  };

  return (
    <div>
      <div ref={filtersRef}>
        <GanttFilter
          type={type}
          setType={setType}
          params={params}
          saveView={saveView}
          submenuNames={submenuNames}
          submenu={submenu}
          setSimpleTaskDialogOpen={setSimpleTaskDialogOpen}
          toggleResourceGroup={toggleResourceGroup}
          setStatusDialogOpen={setStatusDialogOpen}
          showWorHourState={{ showWorkhour, setShowWorkHour }}
          disappearedWorkHour={disappearedWorkHour}
          filter={{ filterVal, setFilterVal }}
          setDynamicColDialogOpen={setDynamicColDialogOpen}
          canEdit={canEdit}
          activeTimeState={{ activeTime, setActiveTime }}
          showActiveTimeCheckbox={showActiveTimeCheckbox}
          setActiveTimeDialogOpen={setActiveTimeDialogOpen}
        />
      </div>
      <div className={`projectCalendar ${showWorkhour ? 'withWorkHour' : ''}`}>
        <FullCalendar
          ref={calendarRef}
          initialDate={new Date()}
          customButtons={timeIntervalButtons}
          plugins={[resourceTimelinePlugin, interactionPlugin]}
          initialView={ganttFilter?.selectedView ?? 'resourceTimelineDay'}
          resources={tasks.resources}
          events={[...tasks.taskEvents, ...tasks.backgroundEvents, ...projectInterval]}
          resourceOrder={order ? ['visOrder'] : ['-visOrder']}
          locales={[huLocale]}
          locale="hu"
          resourcesInitiallyExpanded={expandedResources}
          slotLabelFormat={[
            { month: 'long', year: 'numeric', weekday: 'long' },
            { hour: 'numeric', minute: '2-digit' },
          ]}
          datesSet={handleDatesSet}
          editable={canEdit}
          nowIndicator={true}
          selectable={true}
          select={handleSelect}
          eventClick={(clickInfo) => {
            if (clickInfo.event.display !== 'background') {
              const id = clickInfo.event.id;
              if (clickInfo.jsEvent.ctrlKey || clickInfo.jsEvent.shiftKey) {
                setCopiedEvent(clickInfo.event);
              } else {
                getTaskById(id);
              }
            }
          }}
          dateClick={handleDateClick}
          headerToolbar={{
            left: `prev,next,today ${intervalButtons}`,
            center: 'title',
            right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth,resourceTimelineYear',
          }}
          eventContent={(eventInfo) => {
            if (eventInfo.event.display !== 'background') {
              return <EventChip eventInfo={eventInfo} />;
            }

            if (eventInfo?.event?._def?.extendedProps?.eventData?.elapsedHour) {
              return <div className="workHourEvent">{eventInfo?.event.title}</div>;
            }
          }}
          eventResize={updateEvent}
          eventDrop={updateEvent}
          views={calendarViews}
          slotDuration={timeInterval}
          resourceAreaWidth="20%"
          resourceAreaHeaderContent={
            <div className="flex items-center">
              <p>Projekt</p>
              <div className="absolute right-0">
                <CheckboxUI
                  label={order ? 'Növekvő' : 'Csökkenő'}
                  onChange={(e) => {
                    const isChecked = e?.target?.checked ?? e;
                    setOrder(isChecked);

                    const newSortDirection = isChecked ? 'asc' : 'desc';
                    const newSortModel = query.sorting.sortModel.map((sortObj) => ({
                      ...sortObj,
                      sort: newSortDirection,
                    }));

                    handleSortModelChange(newSortModel);
                  }}
                  checked={order}
                />
              </div>
            </div>
          }
          resourceAreaColumns={filteredResourceColumns}
          scrollTimeReset={false}
          resourceLabelClassNames={(data) => getResourceLabelClassNames(data.resource)}
          resourceLabelContent={(data) => {
            const resource = data.resource;
            const classNames = getResourceLabelClassNames(resource);

            const tooltipNeeded = classNames.includes('has-no-leader');
            const tooltipText = tooltipNeeded ? resource?.extendedProps?.groupName : '';
            const calendarApi = data.view.calendar;

            return tooltipNeeded ? (
              <TooltipUI
                arrow
                placement="top"
                title={<Typography fontSize={15}>{!tooltipText ? 'Nincs vezető felvéve!' : tooltipText}</Typography>}
              >
                <span
                  className={classNames.join(' ')}
                  onClick={() => handleResourceLabelClick(resource, calendarApi)}
                  style={{ cursor: 'pointer' }}
                >
                  {resource.title}
                </span>
              </TooltipUI>
            ) : (
              <span
                className={classNames.join(' ')}
                onClick={() => handleResourceLabelClick(resource, calendarApi)}
                style={{ cursor: 'pointer' }}
              >
                {resource.title}
              </span>
            );
          }}
        />
      </div>

      <DynamicColDialog
        open={dynamicColDialogOpen}
        saveView={saveView}
        dynamicColsVisibility={dynamicColsVisibility}
        handleChangeDynamicCol={handleChangeDynamicCol}
        handleClose={() => {
          setDynamicColDialogOpen(false);
        }}
      />

      <StatusDialog
        open={statusDialogOpen}
        saveView={saveView}
        submenu={submenu}
        filter={{ filterVal, setFilterVal }}
        handleClose={() => {
          setStatusDialogOpen(false);
        }}
      />

      <ActiveTimeDialog
        open={activeTimeDialogOpen}
        handleClose={() => {
          setActiveTimeDialogOpen(false);
        }}
        date={{
          yearViewDateRange,
          setYearViewDateRange,
        }}
        active={{ yearViewActiveTime, setYearViewActiveTime }}
        saveView={saveView}
      />

      <SimpleTaskDialog
        open={dialogOpen}
        changed={{ taskChanged, setTaskChanged }}
        task={task}
        handleClose={() => {
          setDialogOpen(false);
        }}
      />

      <SimpleTaskDialog
        changed={{ taskChanged, setTaskChanged }}
        open={simpleTaskDialogOpen}
        handleClose={() => {
          setSimpleTaskDialogOpen(false);
        }}
      />
    </div>
  );
};
