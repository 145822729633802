import { useState, useEffect } from 'react';

import companyService from '../../service/company.service';
import { dataGridColumns, generateFilter } from '../../utils/helper';

import { DataGridUI, DataGridIdentifiers, FormLabelUI, DebouncedInputUI } from '../Interface';

export default function CompanyReports({ refetch, values, viewField, operatorsDisabled }) {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  const [filterFormState, setFilterFormState] = useState({
    companyName: '',
    adress: '',
  });

  const conditions = {
    like: ['companyName'],
  };

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'deletedBy',
        value: null,
      },
    ];

    const newFilter = generateFilter(filterFormState, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
  }, [filterFormState]);

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getList();
    }
  }, [query]);
  useEffect(() => {
    if (refetchTable && query.filter?.length > 0) {
      getList();
    }
  }, [refetchTable]);

  const onPaginitonChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  const getList = () => {
    companyService.getCompanies(query).then((data) => {
      setReports(data);
      setRefetchTable(false);
    });
  };

  const columns = dataGridColumns([
    {
      field: 'companyName',
      headerName: 'Cégnév',
      headerClassName: 'bg-tableHeaderColor',
      flex: 1,
    },
    {
      field: 'fulladdress',
      headerName: 'Levelezési cím',
      headerClassName: 'bg-tableHeaderColor',
      flex: 1,
      renderCell: (params) => {
        const { postCode, city, street } = params.row;
        if (postCode === null || city === null || street === null) {
          return 'Nincs adat.';
        } else {
          return `${postCode}, ${city}, ${street}`;
        }
      },
    },
    {
      field: 'fullbilladdress',
      headerName: 'Számlázási cím',
      headerClassName: 'bg-tableHeaderColor',
      flex: 1,
      renderCell: (params) => {
        const { billPostcode, billCity, billAddress } = params.row;
        if (billPostcode === null || billCity === null || billAddress === null) {
          return 'Nincs adat.';
        } else {
          return `${billPostcode}, ${billCity}, ${billAddress}`;
        }
      },
    },
  ]);

  return (
    <>
      <div className="flex  overflow-hidden m-4 ">
        <div className="h-full w-full  overflow-x-auto ">
          <div className="grid grid-cols-4 gap-x-4 mx-4">
            <div className="col-span-2 md:col-span-1">
              <DebouncedInputUI
                label={<FormLabelUI text="Cég neve" />}
                debounceMs={800}
                setQuickFilterSearchValue={(newValue) => {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    companyName: newValue,
                  }));
                }}
                quickFilterSearchValue={filterFormState.companyName}
              />
            </div>
            <div className="col-span-2 md:col-span-1">
              <DebouncedInputUI
                label={<FormLabelUI text="Levelezési cím" />}
                debounceMs={800}
                setQuickFilterSearchValue={(newValue) => {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    adress: newValue,
                  }));
                }}
                quickFilterSearchValue={filterFormState.adress}
              />
            </div>
          </div>
          <div className="inline-block w-full border-b border-gray-200 align-middle mt-4 overflow-y-auto overflow-x-auto ">
            <DataGridUI
              datagridIdentifier={DataGridIdentifiers.Company}
              initialState={query}
              rows={reports.rows}
              rowCount={reports.rowCount}
              columns={columns}
              onRowClick={(rowData) => {
                if (!viewField) return;
                setSelectedValues(rowData.row);
              }}
              paginationMode="server"
              onPaginationModelChange={onPaginitonChange}
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
              disableRowSelectionOnClick={!viewField}
              getRowId={(row) => row.companyId}
              name={'Company'}
              height={750}
            />
          </div>
        </div>
      </div>
    </>
  );
}
