import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { generateFilter, dataGridColumns } from '../../utils/helper';
import crmTaskService from '../../service/crmTask.service';

import {
  AutoCompleteSelectUI,
  DatePickerUI,
  DataGridUI,
  DataGridIdentifiers,
  DebouncedInputUI,
  FormLabelUI,
} from '../Interface';

const CrmTaskReports = ({ crmTaskState }) => {
  const {
    setRefetchTable,
    setSelectedValues,
    viewField,
    operatorsDisabled,
    refetchTable,
    rowSelectionModel,
    setRowSelectionModel,
    autoCompleteOptions,
  } = crmTaskState;
  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [{ field: 'startDate', sort: 'desc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });
  const [filterFormState, setFilterFormState] = useState({
    startDate: null,
    endDate: null,
    responsiblePersonId: '',
    typeId: '',
    name: '',
    statusId: '',
  });

  const conditions = {
    like: ['name'],
    gte: ['startDate'],
    lte: ['endDate'],
  };

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'deletedBy',
        value: null,
      },
    ];

    const newFilter = generateFilter(filterFormState, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
  }, [filterFormState]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getList();
    }
  }, [query]);

  useEffect(() => {
    if (refetchTable && query.filter?.length > 0) {
      getList();
    }
  }, [refetchTable]);

  useEffect(() => {
    // @TODO táblázat se mutassa a selected sort
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const onPaginitonChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  const getList = () => {
    crmTaskService.getTasks(query).then((data) => {
      setReports(data);
      setRefetchTable(false);
    });
  };

  const columns = dataGridColumns([
    {
      field: 'id',
      headerName: 'Azonostó',
      flex: 1,
      sortable: false,
    },
    {
      field: 'startDate',
      headerName: 'Kezdő időpont',
      flex: 1,
      renderCell: (params) => {
        return dayjs(params.row.startDate).format('YYYY.MM.DD. HH:mm');
      },
    },
    {
      field: 'endDate',
      headerName: 'Befejező időpont',
      flex: 1,
      renderCell: (params) => {
        return dayjs(params.row.endDate).format('YYYY.MM.DD. HH:mm');
      },
    },
    {
      field: 'name',
      headerName: 'Feladat',
      flex: 1.5,
    },
    {
      field: 'createdBy',
      headerName: 'Tulajdonos',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.row.user?.userName;
      },
    },
    {
      field: 'responsiblePersonId',
      headerName: 'Felelős',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.row.responsiblePerson?.userName;
      },
    },
    {
      field: 'statusId',
      headerName: 'Státusz',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.row.status?.itemName;
      },
    },
    {
      field: 'typeId',
      headerName: 'Feladat típusa',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.row.type?.itemName;
      },
    },
  ]);

  return (
    <div className="flex  overflow-hidden m-4 ">
      <div className="h-full w-full  overflow-x-auto ">
        <div className="grid grid-cols-4 gap-x-4 mx-4">
          <div className="col-span-2 md:col-span-1">
            <DatePickerUI
              label={<span className="text-labelColor">Kezdő dátum</span>}
              value={filterFormState.startDate}
              onChange={(newDate) => {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  startDate: newDate ? newDate.startOf('day') : null,
                }));
              }}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <DatePickerUI
              label={<span className="text-labelColor">Befejező dátum</span>}
              value={filterFormState.endDate}
              onChange={(newDate) => {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  endDate: newDate ? newDate.endOf('day') : null,
                }));
              }}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <DebouncedInputUI
              label="Feladat neve"
              debounceMs={800}
              quickFilterSearchValue={filterFormState?.name}
              setQuickFilterSearchValue={(newValue) => {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  name: newValue,
                }));
              }}
            />
          </div>

          <div className="col-span-2 md:col-span-1">
            <AutoCompleteSelectUI
              id="worker"
              label={<FormLabelUI text="Tulajdonos" />}
              selectedValue={filterFormState.userId}
              dataset={autoCompleteOptions.responsiblePersons}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    userId: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    userId: newVal.value,
                  }));
                }
              }}
            />
          </div>

          <div className="col-span-2 md:col-span-1">
            <AutoCompleteSelectUI
              id="resposiblePerson"
              label={<FormLabelUI text="Felelős" />}
              dataset={autoCompleteOptions.responsiblePersons}
              selectedValue={filterFormState.responsiblePersonId}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    responsiblePersonId: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    responsiblePersonId: newVal.value,
                  }));
                }
              }}
            />
          </div>

          <div className="col-span-2 md:col-span-1">
            <AutoCompleteSelectUI
              id="shift"
              label={<FormLabelUI text="Feladat típusa" />}
              selectedValue={filterFormState.typeId}
              dataset={autoCompleteOptions.type}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    typeId: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    typeId: newVal.value,
                  }));
                }
              }}
            />
          </div>

          <div className="col-span-2 md:col-span-1">
            <AutoCompleteSelectUI
              id="status"
              label={<FormLabelUI text="Státusz" />}
              selectedValue={filterFormState.statusId}
              dataset={autoCompleteOptions.status}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    statusId: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    statusId: newVal.value,
                  }));
                }
              }}
            />
          </div>
        </div>
        <div className="inline-block w-full border-b border-gray-200 align-middle mt-4 overflow-y-auto overflow-x-auto ">
          <DataGridUI
            datagridIdentifier={DataGridIdentifiers.crmTask}
            initialState={query}
            rows={reports.rows}
            rowCount={reports.rowCount}
            columns={columns}
            onRowClick={(rowData) => {
              if (!viewField) return;
              setSelectedValues(rowData.row);
            }}
            paginationMode="server"
            onPaginationModelChange={onPaginitonChange}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            disableRowSelectionOnClick={!viewField}
            height={700}
          />
        </div>
      </div>
    </div>
  );
};

export default CrmTaskReports;
