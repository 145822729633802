import { enqueueSnackbar, closeSnackbar } from 'notistack';

const addNotification = (data) => {
  const closeIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
  );

  const finalOptions = {
    variant: data.type,
    anchorOrigin: { vertical: 'top', horizontal: 'center' },
    autoHideDuration: 2500,
    ...data.options,
    action: (snackbarId) => (
      <p key="close" className="cursor-pointer pr-2" aria-label="close" onClick={() => closeSnackbar(snackbarId)}>
        {closeIcon}
      </p>
    ),
  };

  // @TODO create client log

  return enqueueSnackbar(data.content, { ...finalOptions });
};

export default addNotification;
