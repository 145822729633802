import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'activetime/machine';

const machineActiveService = {
  getMachineActive: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/machineActiveList`,
      data,
    });
  },

  createMachineActive: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/machineActive`,
      data,
    });
  },

  deleteMachineActive: (id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/machineActive/${id}`,
    });
  },

  updateMachineActive: (id, data) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/machineActive/${id}`,
      data,
    });
  },

  createMachineActiveItem: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/machineActiveItem`,
      data,
    });
  },

  updateMachineActiveItem: (id, data) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/machineActiveItem/${id}`,
      data,
    });
  },

  deleteMachineActiveItem: (id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/machineActiveItem/${id}`,
    });
  },

  getMachineActiveItemsById: (id) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/machineActiveItem/${id}`,
    });
  },

  getMachineDayOff: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/machineDayOffList`,
      data,
    });
  },

  createMachineDayOff: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/machineDayOff`,
      data,
    });
  },

  deleteMachineDayOff: (id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/machineDayOff/${id}`,
    });
  },

  updateMachineDayOff: (id, data) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/machineDayOff/${id}`,
      data,
    });
  },
};

export default machineActiveService;
