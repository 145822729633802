import React, { useEffect, useState } from 'react';
import { Delete } from '@mui/icons-material';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';

import { TimePickerUi } from '../Interface';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import { isOverlappingTime } from '../../utils/intevalOverLap';
import companyActiveService from '../../service/companyActive.service';
import machineActiveService from '../../service/machineActive.service';

const daysOfWeek = [
  { key: 'monday', label: 'Hétfő' },
  { key: 'tuesday', label: 'Kedd' },
  { key: 'wednesday', label: 'Szerda' },
  { key: 'thursday', label: 'Csütörtök' },
  { key: 'friday', label: 'Péntek' },
  { key: 'saturday', label: 'Szombat' },
  { key: 'sunday', label: 'Vasárnap' },
];

const ActiveTimeItems = ({ id, isMachine }) => {
  dayjs.extend(utc);
  //dayjs.extend(timezone);
  dayjs.extend(isBetween);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (id) getItems();
  }, [id]);

  const getItems = () => {
    if (!isMachine) {
      companyActiveService.getCompanyActiveItemsById(id).then((data) => setRows(data));
    } else {
      machineActiveService.getMachineActiveItemsById(id).then((data) => setRows(data));
    }
  };

  const updateItem = (lineId, newLine) => {
    setRows((prev) => prev.map((item) => (item.id === lineId ? { ...item, ...newLine } : item)));
  };

  const findRow = (rowId) => {
    return rows.find((r) => r.id === rowId);
  };

  const checkEmptyTime = (rowId = null) => {
    if (rowId) {
      const findedRow = findRow(rowId);
      return !Boolean(findedRow.fromTime && findedRow.toTime);
    }

    return rows.every((row) => row.fromTime && row.toTime);
  };

  const getExistingInterval = (rowId) => {
    return rows.reduce((acc, row) => {
      if (row.fromTime && row.toTime && row.id !== rowId) {
        acc.push({
          start: dayjs.utc(row.fromTime).format('HH:mm'),
          end: dayjs.utc(row.toTime).format('HH:mm'),
        });
      }
      return acc;
    }, []);
  };

  const handleTimeChange = (rowId, field, value) => {
    const findedRow = findRow(rowId);
    const existingIntervals = getExistingInterval(rowId);
    if (value) {
      const newInterval =
        field === 'fromTime'
          ? {
              start: value?.format('HH:mm'),
              end: findedRow?.toTime ? dayjs.utc(findedRow?.toTime).format('HH:mm') : null,
            }
          : {
              start: findedRow.fromTime ? dayjs.utc(findedRow.fromTime).format('HH:mm') : null,
              end: value?.format('HH:mm'),
            };

      if (isOverlappingTime(newInterval, existingIntervals)) {
        getItems();
        return addNotification({
          content: 'A megadott időpont átfedésben van!',
          type: NotificationType.WARNING,
        });
      }
    }

    const time = value ? dayjs.utc(`1970-01-01T${value.format('HH:mm')}:00`) : null;
    const updData = {
      [field]: time,
    };

    if (!isMachine) {
      companyActiveService.updateCompanyActiveItem(rowId, updData).then((data) => updateItem(rowId, data));
    } else {
      machineActiveService.updateMachineActiveItem(rowId, updData).then((data) => updateItem(rowId, data));
    }
  };

  const handleCheckboxChange = (rowId, day) => {
    const findedRow = findRow(rowId);
    const updData = {
      [day]: !findedRow[day],
    };

    if (!isMachine) {
      companyActiveService.updateCompanyActiveItem(rowId, updData).then((data) => updateItem(rowId, data));
    } else {
      machineActiveService.updateMachineActiveItem(rowId, updData).then((data) => updateItem(rowId, data));
    }
  };

  const addRow = () => {
    if (!checkEmptyTime()) {
      return addNotification({
        content: 'Nincs minden sorban az időpont kitöltve!',
        type: NotificationType.WARNING,
      });
    }

    if (!isMachine) {
      const insData = {
        companyActiveId: id,
      };

      companyActiveService.createCompanyActiveItem(insData).then((data) => {
        setRows((prev) => {
          return [...prev, data];
        });
      });
    } else {
      const insData = {
        machineActiveId: id,
      };
      machineActiveService.createMachineActiveItem(insData).then((data) => {
        setRows((prev) => {
          return [...prev, data];
        });
      });
    }
  };

  const deleteRow = (rowId) => {
    if (!isMachine) {
      companyActiveService
        .deleteCompanyActiveItem(rowId)
        .then(() => setRows((prev) => prev.filter((row) => row.id !== rowId)));
    } else {
      machineActiveService
        .deleteMachineActiveItem(rowId)
        .then(() => setRows((prev) => prev.filter((row) => row.id !== rowId)));
    }
  };

  return (
    <>
      <div className="col-span-4 flex flex-col overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="col h-full flex-col overflow-hidden bg-white p-2 shadow sm:rounded-lg">
          <div className="flex h-full overflow-hidden border-t border-gray-200">
            <div className="w-full overflow-x-auto xxl:overflow-x-hidden">
              <div className="p-3">
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-2">
                    <h6 className="text-lg font-semibold">Kezdete</h6>
                  </div>
                  <div className="col-span-2">
                    <h6 className="text-lg font-semibold">Vége</h6>
                  </div>
                  {daysOfWeek.map((day) => (
                    <div className="col-span-1 flex justify-center" key={day.key}>
                      <h6 className="text-lg font-semibold items-center">{day.label}</h6>
                    </div>
                  ))}
                  <div className="col-span-1"></div>
                </div>
                {rows.map((row) => (
                  <div className="grid grid-cols-12 gap-2 items-center mt-2" key={row.id}>
                    <div className="col-span-2">
                      <TimePickerUi
                        value={row.fromTime ? dayjs.utc(row.fromTime) : null}
                        onChange={(newValue) => handleTimeChange(row.id, 'fromTime', newValue)}
                        onAccept={(newValue) => handleTimeChange(row.id, 'fromTime', newValue)}
                        renderInput={(params) => <input {...params} className="w-full border rounded p-2" />}
                        minutesStep={30}
                      />
                    </div>
                    <div className="col-span-2">
                      <TimePickerUi
                        value={row.toTime ? dayjs.utc(row.toTime) : null}
                        onChange={(newValue) => handleTimeChange(row.id, 'toTime', newValue)}
                        onAccept={(newValue) => handleTimeChange(row.id, 'toTime', newValue)}
                        renderInput={(params) => <input {...params} className="w-full border rounded p-2" />}
                        minutesStep={30}
                      />
                    </div>
                    {daysOfWeek.map((day) => (
                      <div className="col-span-1 flex justify-center" key={day.key}>
                        <input
                          type="checkbox"
                          checked={row[day.key]}
                          onChange={() => handleCheckboxChange(row.id, day.key)}
                          className="form-checkboxflex items-center"
                        />
                      </div>
                    ))}
                    <div className="col-span-1 flex justify-center">
                      <button onClick={() => deleteRow(row.id)} className="text-red-600 items-center">
                        <Delete />
                      </button>
                    </div>
                  </div>
                ))}
                <div className="mt-4">
                  <button onClick={addRow} className="px-4 py-2 bg-blue-500 text-white rounded">
                    Sor hozzáadása
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveTimeItems;
