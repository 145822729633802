import { useEffect, useState } from 'react';
import useMenus from '../context/MenuContext';
import useParam from '../context/ParamContext';

export const useCrmTask = () => {
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getParam, params } = useParam();
  const [refetchTable, setRefetchTable] = useState(true);
  const [viewField, setViewField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [submenu, setSubmenu] = useState(null);
  const [canEdit, setCanEdit] = useState(true);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState({
    type: [],
    status: [],
    responsiblePersons: [],
  });

  useEffect(() => {
    getMenus();
    getParam();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('crmTask');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  return {
    setRefetchTable,
    viewField,
    setViewField,
    selectedValues,
    setSelectedValues,
    operatorsDisabled,
    setOperatorsDisabled,
    submenu,
    params,
    canEdit,
    rowSelectionModel,
    setRowSelectionModel,
    setCanEdit,
    refetchTable,
    setSubmenu,
    autoCompleteOptions,
    setAutoCompleteOptions,
  };
};
