import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import {
  DataGridPremium,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  GridToolbar,
  getGridDateOperators,
  isAutogeneratedRow,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid-premium';
import { huHU } from '@mui/x-data-grid/locales';
import { datagridLangHunCustomized } from '../../../config/datagridLangHunCustomized';
import {
  Select,
  MenuItem,
  IconButton,
  Modal,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormControl,
} from '@mui/material';
import { Delete, Save } from '@mui/icons-material';
import dayjs from 'dayjs';

import useParam from '../../../context/ParamContext';
import { ButtonUI, TrendIcon } from '../../Interface';
import biService from '../../../service/bi.service';
import { BiFieldType } from '../../../config/bi_report';
import { getDataGridStyles } from '../../Interface/Datagrid/dataGridStyles';
import FeedbackDialog from './FeedbackDialog';
import { formatCurrency } from '../../../utils/currencyHelper';

const TableReport = ({ report, height, setRefresh, filterValsState }) => {
  const { getParam, params } = useParam();
  const apiRef = useGridApiRef();

  const { filterVals } = filterValsState;

  const [gridData, setGridData] = useState({ rows: [], rowCount: 0 });
  const [columns, setColumns] = useState([]);
  const [savedStates, setSavedStates] = useState({});
  const [selectedState, setSelectedState] = useState('');
  const [isSelectOpen, setSelectOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [stateName, setStateName] = useState('');
  const [isOverwriteDialogOpen, setOverwriteDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [stateToDelete, setStateToDelete] = useState('');
  const [dialogData, setDialogData] = useState({});

  const query = useMemo(
    () => ({
      filter: filterVals.filter,
      sorting: {
        sortModel: [],
      },
      pagination: {
        paginationModel: {
          page: 0,
        },
      },
    }),
    [report.id, filterVals]
  );

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: [],
        aggregation: {
          model: {},
        },
      },
      density: 'compact',
    },
  });

  const getTableData = useCallback(() => {
    biService.getReportData(report.id, null, query).then((data) => setGridData(data));
  }, [report.id, query]);

  const NumberField = ({ data, field }) => {
    const val = parseFloat(data.row?.[field?.field]);
    const currencyVal = formatCurrency(val);
    const threshold = {
      min: field.thresholdMin ? parseFloat(field.thresholdMin) : null,
      max: field.thresholdMax ? parseFloat(field.thresholdMax) : null,
      arrow: field.thresholdArrow,
    };
    const biTableFeedback = JSON.parse(params?.BI_TABLE_FEEDBACK ?? '[]');
    const hasFeedback = biTableFeedback.includes(report.id);

    return ((field.thresholdMin !== null && !isNaN(field.thresholdMin)) ||
      (field.thresholdMax !== null && !isNaN(field.thresholdMax))) &&
      !isNaN(val) ? (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '-3px' }}>
        <Box>{field.type === BiFieldType.CURRENCY ? currencyVal : val}</Box>
        <TrendIcon
          rowData={data}
          field={field}
          threshold={threshold}
          hasFeedback={hasFeedback}
          setDialogData={setDialogData}
        />
      </Box>
    ) : !isNaN(val) ? (
      field.type === BiFieldType.CURRENCY ? (
        currencyVal
      ) : (
        val
      )
    ) : (
      data.row?.[field?.field]
    );
  };

  const generateColumns = () => {
    const col = [];

    for (const field of report.biField) {
      if (field.type !== BiFieldType.HIDDEN) {
        const column = {
          field: field?.field,
          headerName: field?.name,
          headerClassName: 'bg-tableHeaderColor',
          filterable: true,
          sortable: true,
          flex: 1,
        };

        switch (field.type) {
          case BiFieldType.DATE:
            column.type = 'date';
            column.valueGetter = (value) => new Date(value);
            column.renderCell = (value) => {
              return dayjs(value.row?.[field?.field]).format('YYYY.MM.DD');
            };

            break;
          case BiFieldType.DATETIME:
            column.type = 'dateTime';
            column.valueGetter = (value) => new Date(value);
            column.renderCell = (value) => {
              return dayjs(value.row?.[field?.field]).format('YYYY.MM.DD HH:mm');
            };
            break;
          case BiFieldType.TIME:
            column.valueFormatter = (data) => (data ? dayjs(data).format('HH:mm') : '');
            break;
          case BiFieldType.NUMBER:
          case BiFieldType.CURRENCY:
            column.type = 'number';
            column.valueGetter = (data) => {
              return data ? parseFloat(data) : '';
            };
            column.renderCell = (data) => {
              if (!isAutogeneratedRow(data.row)) {
                return <NumberField data={data} field={field} />;
              } else {
                return data.formattedValue;
              }
            };
            break;
          default:
            break;
        }

        col.push(column);
      }
    }

    setColumns(col);
  };

  useEffect(() => {
    if (!Object.keys(params).length) {
      getParam();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(report).length) {
      generateColumns();
    }
  }, [report, report?.biField]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getTableData();
    }
  }, [query]);

  useEffect(() => {
    biService.getReportReportGridState(report.id).then((savedStateTable) => setSavedStates(savedStateTable));
    // const savedStates = JSON.parse(localStorage.getItem(`biGridStates_${report.id}`)) || {};
    // setSavedStates(savedStates);
  }, [report.id]);

  useEffect(() => {
    if (params) {
    }
  }, [params]);

  const handleSaveState = () => {
    setModalOpen(true);
  };

  const handleSaveStateConfirm = () => {
    if (savedStates[stateName]) {
      setModalOpen(false);
      setOverwriteDialogOpen(true);
    } else {
      saveState();
    }
  };

  const saveState = async () => {
    const currentState = apiRef.current.exportState();
    const newSavedStates = { ...savedStates, [stateName]: currentState };
    setSavedStates(newSavedStates);
    // localStorage.setItem(`biGridStates_${report.id}`, JSON.stringify(newSavedStates));
    await biService.saveReportGridState(report.id, newSavedStates);
    setModalOpen(false);
    setOverwriteDialogOpen(false);
    setStateName('');
  };

  const handleConfirmOverwrite = () => {
    saveState();
  };

  const handleLoadState = useCallback(
    (stateName) => {
      const savedState = savedStates[stateName];
      if (savedState) {
        apiRef.current.restoreState(savedState);
      }
    },
    [savedStates, apiRef]
  );

  const handleDeleteState = (stateName) => {
    setStateToDelete(stateName);
    setDeleteDialogOpen(true);
  };

  const confirmDeleteState = async () => {
    const newSavedStates = { ...savedStates };
    delete newSavedStates[stateToDelete];
    setSavedStates(newSavedStates);
    // localStorage.setItem(`biGridStates_${report.id}`, JSON.stringify(newSavedStates));
    await biService.saveReportGridState(report.id, newSavedStates);
    setDeleteDialogOpen(false);
    setStateToDelete('');
    if (selectedState === stateToDelete) {
      setSelectedState('');
    }
  };

  useEffect(() => {
    if (selectedState) {
      handleLoadState(selectedState);
    }
  }, [selectedState, handleLoadState]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <FormControl variant="standard" sx={{ width: '200px' }}>
          <Select
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
            displayEmpty
            onOpen={() => setSelectOpen(true)}
            onClose={() => setSelectOpen(false)}
            onClick={() => setSelectOpen(true)}
            sx={{
              color: 'primary.main',
              '& .MuiSelect-icon': {
                color: 'primary.main',
              },
            }}
          >
            <MenuItem value="" disabled>
              Mentett állapot kiválasztása
            </MenuItem>
            {Object.keys(savedStates)
              .sort()
              .map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                  {isSelectOpen && (
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation(); // Megakadályozza, hogy a click esemény a Select-re is vonatkozzon
                        handleDeleteState(state);
                      }}
                      sx={{
                        marginLeft: 'auto',
                        color: 'primary.main',
                      }}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  )}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <IconButton
          aria-label="save"
          size="medium"
          onClick={handleSaveState}
          sx={{
            color: 'primary.main',
          }}
        >
          <Save />
        </IconButton>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  return (
    <div className="grid grid-cols-1 gap-2 p-4">
      <div className="col-span-1 flex items-center justify-center text-labelColor text-xl w-full">
        <p>{report.name}</p>
      </div>

      <div className="tableReport">
        <DataGridPremium
          initialState={initialState}
          apiRef={apiRef}
          rows={gridData.rows}
          columns={columns}
          groupingColDef={{
            hideable: false,
          }}
          pagination
          rowHeight={45}
          sx={getDataGridStyles({ height: height ? height - 50 : 550 })}
          slots={{ toolbar: CustomToolbar }}
          slotProps={{
            columnsPanel: {
              sx: {
                '& .MuiDataGrid-panelFooter button:first-child': {
                  display: 'none',
                },
              },
            },
          }}
          localeText={{ ...huHU.components.MuiDataGrid.defaultProps.localeText, ...datagridLangHunCustomized }}
          disableSelectionOnClick
          headerFilters
        />
      </div>
      {/* Save State Modal */}
      <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
        <div
          className="modal-container"
          style={{
            padding: 20,
            backgroundColor: 'white',
            margin: 'auto',
            top: '20%',
            position: 'relative',
            width: 300,
          }}
        >
          <h2>Jelenlegi állapot mentése</h2>
          <TextField label="State Name" value={stateName} onChange={(e) => setStateName(e.target.value)} fullWidth />
          <Button variant="contained" onClick={handleSaveStateConfirm} disabled={!stateName} style={{ marginTop: 10 }}>
            Mentés
          </Button>
        </div>
      </Modal>

      {/* Overwrite Confirmation Dialog */}
      <Dialog open={isOverwriteDialogOpen} onClose={() => setOverwriteDialogOpen(false)}>
        <DialogTitle>Felülírás megerősítése</DialogTitle>
        <DialogContent>
          <DialogContentText>Már létezik egy állapot "{stateName}" névvel. Szeretné felülírni?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOverwriteDialogOpen(false)}>Mégsem</Button>
          <Button onClick={handleConfirmOverwrite} color="primary" variant="contained">
            Felülír
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Törlés megerősítése</DialogTitle>
        <DialogContent>
          <DialogContentText>Biztos benne, hogy törölni szeretné a(z) "{stateToDelete}" állapotot?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <ButtonUI text={'Törlés'} className="bg-danger" type="button" onClick={confirmDeleteState} />
        </DialogActions>
      </Dialog>

      <FeedbackDialog
        show={Object.keys(dialogData).length > 0}
        onHide={() => {
          setDialogData({});
        }}
        selectedValues={dialogData}
        biReportId={report.id}
        setRefresh={setRefresh}
      />
    </div>
  );
};

export default TableReport;
