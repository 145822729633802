export const DataGridIdentifiers = Object.freeze({
  simpleTaskReports: 'simpleTaskReports',
  Projects: 'Projects',
  AbsenceReports: 'AbsenceReports',
  CompanyActive: 'CompanyActive',
  CompanyDayOff: 'CompanyDayOff',
  MachineActive: 'MachineActive',
  MachineDayOff: 'MachineDayOff',
  attendance: 'attendance',
  AttendanceUsers: 'AttendanceUsers',
  biHead: 'biHead',
  biReport: 'biReport',
  Company: 'Company',
  CompanyLocationTable: 'CompanyLocationTable',
  InnovationReports: 'InnovationReports',
  MachineTable: 'MachineTable',
  OperationReports: 'OperationReports',
  PriorityReports: 'PriorityReports',
  ProductionPersons: 'ProductionPersons',
  ProductionReports: 'ProductionReports',
  OperationReportTable: 'OperationReportTable',
  ProductionReportDialog: 'ProductionReportDialog',
  ProjectContact: 'ProjectContact',
  ProjectHoliday: 'ProjectHoliday',
  ProjectGroupReports: 'ProjectGroupReports',
  FormsDataGrid: 'FormsDataGrid',
  PropertyReports: 'PropertyReports',
  SelectListDatagrid: 'SelectListDatagrid',
  QuantityUnitReports: 'QuantityUnitReports',
  ResourceFourReports: 'ResourceFourReports',
  operations: 'operations',
  dependencies: 'dependencies',
  TypeFormTable: 'TypeFormTable',
  TypeReports: 'TypeReports',
  UserReports: 'UserReports',
  StatusReports: 'StatusReports',
  TaskReports: 'TaskReports',
  StatusFormTable: 'StatusFormTable',
  CompanyFormTable: 'CompanyFormTable',
  InnovationFormTable: 'InnovationFormTable',
  PriorityFormTable: 'PriorityFormTable',
  ProductionFormTable: 'ProductionFormTable',
  ProjectGroupListFormTable: 'ProjectGroupListFormTable',
  SimpleTaskFormTable: 'SimpleTaskFormTable',
  ResourceOneTable: 'ResourceOneTable',
  ResourceTwoTable: 'ResourceTwoTable',
  ResourceThreeTable: 'ResourceThreeTable',
  ResourceFourTable: 'ResourceFourTable',
  ResourceFiveTable: 'ResourceFiveTable',
  resourceOne: 'resourceOne',
  communication: 'communication',
  resourceTwo: 'resourceTwo',
  resourceThree: 'resourceThree',
  companyContact: 'companyContact',
  province: 'province',
  provinceDayOff: 'provinceDayOff',
  uploadFile: 'uploadedFile',
  dashboardReports: 'dashboardReports',
  complaints: 'complaints',
  classificationReports: 'classificationReports',
  classificationItems: 'classificationItems',
  crmTask: 'crmTask',
  groupsDataGrid: 'groupsDataGrid',
  vehiclePlanDatagrid: 'vehiclePlanDatagrid',
});
