import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';

import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import { useLoader } from '../../provider/LoaderProvider';
import companyLocationService from '../../service/companyLocation.service';
import useMenus from '../../context/MenuContext';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI } from '../Interface';
import FormFields from './FormFields';
import CompanyLocationTable from './CompanyLocationTable';

export const CompanyLocationForm = () => {
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const defaultValues = { name: '' };
  const { showLoader, hideLoader } = useLoader();
  const [initValues, setInitValues] = useState({});
  const [refetchTable, setRefetchTable] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    setInitValues(defaultValues);
    getMenus();
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
  });

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (selectedValues) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
      }
    }
  }, [selectedValues]);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('companyLocation');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  const valuesToForm = () => {
    const { name } = selectedValues;

    setInitValues({
      name,
    });
  };

  const handleInsertObject = (values, resetForm) => {
    showLoader();
    const insertObject = { ...values };

    companyLocationService
      .createCompanyLocation(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        resetForm();
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values, resetForm);
    } else if (!addField) {
      handleInsertObject(values, resetForm);
    }
  };

  const updateObject = async (values, resetForm) => {
    const updData = {
      ...values,
    };

    companyLocationService.updateCompanyLocation(updData, selectedValues.id).then((_operation) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setEditButtonvisible(true);
      resetForm();
      setInitValues({});
      setSelectedValues({});
      setViewField(true);
    });
  };

  const handleRemoveElement = async () => {
    companyLocationService.deleteCompanyLocation(selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues({});
    });
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-6 grid-rows-[auto_1fr] gap-y-4 xl:gap-4 bg-gray-100 p-4">
      <div className="col-span-4 md:col-span-2 w-full overflow-x-auto overflow-y-auto">
        <div className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues({});
              setInitValues(defaultValues);
              setViewField(false);
              setAddField(false);
            }}
            addDisabled={!viewField || !canEdit}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled || !canEdit}
            copyClick={() => {
              setViewField(false);
              setAddField(false);
            }}
            copyDisabled={!viewField || operatorsDisabled || !canEdit}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, resetForm }) => (
              <Form>
                <FormFields values={values} errors={errors} viewField={viewField} />

                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);

                      resetForm();
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
      <CompanyLocationTable
        refetch={{ refetchTable, setRefetchTable }}
        values={{ selectedValues, setSelectedValues }}
        viewField={viewField}
        operatorsDisabled={operatorsDisabled}
      />
    </div>
  );
};
