import React, { useState, useEffect, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';
import useSaveView from '../../hooks/useSaveView';
import AutoCompleteSelectUI from '../Interface/AutoCompleteSelectUI';
import TodoCard from './TodoCard';
import simpletaskService from '../../service/simpletask.service';
import { useLoader } from '../../provider/LoaderProvider';
import { generateFilter } from '../../utils/helper';
import useMenus from '../../context/MenuContext';
import useView from '../../hooks/useView';
import useParam from '../../context/ParamContext';
import useUsers from '../../context/UsersContext';
import { DebouncedInputUI, FormLabelUI } from '../Interface';

const TodoContent = ({ activeTab, type, tabId }) => {
  const { getUser, user } = useUsers();
  const saveView = useSaveView(user);
  const myTodoFilter = useView('myTodoFilter', user);
  const { getParam, params } = useParam();
  const { menus, getMenus, getMenuSubMenuId } = useMenus();
  const [submenu, setSubmenu] = useState(null);
  const [myTodo, setMyTodo] = useState([]);
  const [prioValue, setPrioValue] = useState('');
  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [{ field: 'startDate', sort: 'desc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 20,
        page: 0,
      },
    },
  });
  const [formVal, setFormVal] = useState({
    statusId: [],
    priorId: '',
    simpleTaskName: '',
    companyLocationId: '',
  });

  const [defaultFilterFields, setDefaultFilterFields] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  //@TODO user a contextből
  const userObj = JSON.parse(localStorage.getItem('user'));
  const announctedTaskStatus = parseInt(params.MYTODO_ANNOUNCED_TASK_STATUS);

  const { showLoader, hideLoader } = useLoader();

  const conditions = {
    like: ['simpleTaskName'],
    gte: ['startDate'],
    lte: ['endDate'],
  };

  useEffect(() => {
    getMenus();
    getParam();
    getUser();
  }, []);

  useEffect(() => {
    setSubmenu(getMenuSubMenuId('simpleTask'));
  }, [menus]);

  useEffect(() => {
    const newFields = [
      {
        field: 'archive',
        value: 'N',
      },
    ];

    if (type === 'own') {
      newFields.push({ field: 'resposiblePersonId', value: userObj.userId });
    } else if (type === 'opened') {
      newFields.push({ field: 'resposiblePersonId', value: null });
      if (announctedTaskStatus) {
        newFields.push({ field: 'statusId', value: announctedTaskStatus });
      }
      //@TODO telephely, szerep stb majd itt kell kezelni
    }

    setDefaultFilterFields(newFields);
  }, [activeTab]);

  useEffect(() => {
    if (defaultFilterFields.length > 0) {
      //@TODO átnézni
      const newStatus = formVal.statusId.map((sIds) => sIds.value);
      const newFormVal = { ...formVal, statusId: newStatus };
      const newFilter = generateFilter(newFormVal, conditions);
      if (!_.isEqual(query.filter, [...defaultFilterFields, ...newFilter])) {
        setMyTodo([]);

        setQuery((prev) => ({
          ...prev,
          filter: [...defaultFilterFields, ...newFilter],
          pagination: {
            ...prev.pagination,
            paginationModel: { ...prev.pagination.paginationModel, page: 0 },
          },
        }));
      }
    }
  }, [formVal, defaultFilterFields]);

  useEffect(() => {
    if (Object.keys(myTodoFilter).length > 0) {
      setFormVal({
        statusId: myTodoFilter.status || [],
        priorId: myTodoFilter.priority?.value || '',
      });
      setPrioValue(myTodoFilter?.priority);
    }
  }, [myTodoFilter]);

  const getList = useCallback(() => {
    showLoader();
    simpletaskService
      .getTasks(query)
      .then((data) => {
        if (query.pagination.paginationModel.page === 0) {
          setMyTodo(data.rows);
        } else {
          setMyTodo((prev) => [...prev, ...data.rows]);
        }
        setHasMore(myTodo.length + data.rows.length < data.rowCount);
      })
      .finally(() => {
        hideLoader();
      });
  }, [query]);

  useEffect(() => {
    if (Object.keys(query).length > 0 && activeTab === tabId && query.filter?.length > 0) {
      //itt kell eldönteni, hogy lapozás, vagy filter válotozott
      getList();
    }
  }, [query, activeTab, tabId, getList]);

  const fetchMoreData = () => {
    setQuery((prev) => ({
      ...prev,
      pagination: {
        paginationModel: {
          ...prev.pagination.paginationModel,
          page: prev.pagination.paginationModel.page + 1,
        },
      },
    }));
  };

  return (
    <>
      <div className="grid grid-cols-4 gap-4 mx-4 md:w-3/4 lg:1/2 mx-auto">
        <div className="col-span-4 md:col-span-1">
          <DebouncedInputUI
            label={<FormLabelUI text="Keresés" />}
            debounceMs={800}
            setQuickFilterSearchValue={(newValue) => {
              setFormVal((prevState) => ({
                ...prevState,
                simpleTaskName: newValue,
              }));
            }}
            quickFilterSearchValue={formVal.simpleTaskName}
          />
        </div>
        <div className="col-span-4 md:col-span-1">
          <AutoCompleteSelectUI
            label={<FormLabelUI text="Státusz" />}
            onChange={(_event, newVal, _reason) => {
              setFormVal((prev) => ({
                ...prev,
                statusId: newVal,
              }));
              saveView('myTodoFilter', { status: newVal });
            }}
            selectedValue={formVal.statusId}
            disabled={false}
            id={'selectedStatus'}
            table={['statusitems', 'status']}
            listType={{ id: 'itemId', name: 'itemName' }}
            joins={'statusitems.statusId = status.statusId'}
            freeSolo
            conditions={[`status.subMenuId = ${submenu}`, `statusitems.archive='N'`]}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            multiple
          />
        </div>
        <div className="col-span-4 md:col-span-1">
          <AutoCompleteSelectUI
            label={<FormLabelUI text="Prioritás" />}
            onChange={(_e, newVal, reason) => {
              if (reason === 'clear') {
                setFormVal((prev) => ({
                  ...prev,
                  priorId: '',
                }));
                saveView('myTodoFilter', { priority: '' });
              } else {
                setFormVal((prev) => ({
                  ...prev,
                  priorId: newVal.value,
                }));
                saveView('myTodoFilter', { priority: newVal });
              }
            }}
            value={prioValue}
            disabled={false}
            id={'selectedPrior'}
            table={['prioritylistitems ', 'priority']}
            listType={{ id: 'itemId', name: 'itemName' }}
            joins={'prioritylistitems.priorityId = priority.priorityId'}
            conditions={[`priority.subMenuId = ${submenu}`, `prioritylistitems.archive='N'`]}
          />
        </div>
        <div className="col-span-4 md:col-span-1">
          <AutoCompleteSelectUI
            label={<FormLabelUI text="Telephely" />}
            onChange={(_e, newVal, reason) => {
              if (reason === 'clear') {
                setFormVal((prev) => ({
                  ...prev,
                  companyLocationId: '',
                }));
              } else {
                setFormVal((prev) => ({
                  ...prev,
                  companyLocationId: newVal.value,
                }));
              }
            }}
            selectedValue={formVal.companyLocationId}
            disabled={false}
            id={'companyLocationId'}
            table={['companyLocation']}
            listType={{ id: 'id', name: 'name' }}
            conditions={[`companyLocation.archive='N'`]}
          />
        </div>
      </div>
      <InfiniteScroll
        dataLength={myTodo.length}
        next={fetchMoreData}
        hasMore={hasMore}
        endMessage={
          <div className="flex justify-center">
            <p className="text-xl">Az összes feladatot megtekintette.</p>
          </div>
        }
      >
        <div className="py-10 flex flex-wrap gap-7 justify-center">
          {myTodo.map((task, id) => {
            return <TodoCard key={id} task={task} setMyTodo={setMyTodo} submenu={submenu} user={user} />;
          })}
        </div>
      </InfiniteScroll>
    </>
  );
};

export default TodoContent;
