import React, { useMemo, useEffect, useCallback } from 'react';
import { Field, useFormikContext } from 'formik';
import dayjs from 'dayjs';

import { getDynVal, getIndex } from '../../utils/DynHelper';
import { DatePickerUI } from '../Interface';

const DynDateInput = ({ name, disabled, selectedValues }, ...props) => {
  const { values, setFieldValue } = useFormikContext();

  const dynVal = useMemo(
    () => getDynVal(selectedValues.dynamicProperties, name),
    [selectedValues.dynamicProperties, name]
  );

  useEffect(() => {
    const setterVal = dynVal?.dateVal ?? null;

    setFieldValue(name, setterVal ? dayjs(setterVal) : null);
  }, [dynVal, name, setFieldValue]);

  const handleChange = useCallback(
    (e) => {
      setFieldValue(name, dayjs(e).add(12, 'hour').toISOString());
    },
    [name, setFieldValue]
  );
  return (
    <Field
      name={name}
      as={DatePickerUI}
      fullWidth
      variant="standard"
      disabled={disabled}
      value={dayjs(values.dynamicFields?.[getIndex(name)])}
      onChange={handleChange}
      {...props}
    />
  );
};

export default DynDateInput;
