import React, { useState } from 'react';
import { Typography } from '@material-tailwind/react';

import sapResourcePlannerService from '../../../service/sapResourcePlanner.service';
import { SelectUI, ButtonUI } from '../../Interface';
import { DependenciesTypes } from '../../../config/DepencieTypes';

const DependecyListSelectedOpPanel = ({ parent, selectedOperationState, getSapDependencyList }) => {
  const { selectedOperation, setSelectedOperation } = selectedOperationState;
  const [selectedType, setSelectedType] = useState(DependenciesTypes.FS);

  const handleSave = () => {
    const insData = {
      parentDocEntry: parent.DocEntry,
      parentLineNum: parent.LineNum,
      docEntry: selectedOperation.DocEntry,
      lineNum: selectedOperation.LineNum,
      type: selectedType,
    };
    sapResourcePlannerService.createSapProductionDependency(insData).then(() => getSapDependencyList());
  };

  return (
    <>
      <div className="col-span-1 flex justify-center mt-5">
        <Typography className="mb-3">
          <h4 className="font-semibold">GYU</h4>
          <p className="text-gray-700">{selectedOperation.DocNum}.</p>
        </Typography>
      </div>
      <div className="col-span-1 flex justify-center mt-5">
        <Typography className="mb-3">
          <h4 className="font-semibold">Project</h4>
          <p className="text-gray-700">{selectedOperation.Project}.</p>
        </Typography>
      </div>
      <div className="col-span-1 flex justify-center mt-5">
        <Typography className="mb-3">
          <h4 className="font-semibold">Művelet</h4>
          <p className="text-gray-700">{selectedOperation.U_QNT_RAJZSZAM}.</p>
        </Typography>
      </div>
      <div className="col-span-1 flex justify-center mt-5">
        <Typography className="mb-3">
          <h4 className="font-semibold">Gép</h4>
          <p className="text-gray-700">{selectedOperation.U_QNT_GEP}.</p>
        </Typography>
      </div>
      <div className="col-span-2 flex justify-center ">
        <SelectUI
          label={'Típus'}
          option={Object.values(DependenciesTypes)}
          value={selectedType}
          defaultValue={DependenciesTypes.FS}
          onChange={(e) => setSelectedType(e.target.value)}
        />
      </div>
      <div className="col-span-2 flex justify-center  grid-cols-2 gap-x-4">
        <div className="col-span-1 flex justify-center ">
          <ButtonUI className="bg-success" text="Mentés" onClick={() => handleSave()} />
        </div>
        <div className="col-span-1 flex justify-center ">
          <ButtonUI className="bg-warning" text="Mégsem" onClick={() => setSelectedOperation({})} />
        </div>
      </div>
    </>
  );
};

export default DependecyListSelectedOpPanel;
