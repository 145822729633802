import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import dayjs from 'dayjs';
import _ from 'lodash';

import Yup from '../../utils/yup';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import { getDynVals } from '../../utils/DynHelper';
import useUsers from '../../context/UsersContext';
import useMenus from '../../context/MenuContext';
import { FormHeaderUI, FormButtonPanelUI, DeleteDialogUI } from '../Interface';
import ComplaintFields from './ComplaintFields';
import ComplaintReports from './ComplaintReports';
import ComplaintTabs from './ComplaintTabs';
import complaintService from '../../service/complaint.service';
import masterDataService from '../../service/masterData.service';

export const ComplaintPage = () => {
  const { getUser, user } = useUsers();
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const [initValues, setInitValues] = useState({});
  const [refetchTable, setRefetchTable] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState({});
  const [submenu, setSubmenu] = useState(null);
  const [defaultValues, setDefaultValues] = useState({});
  const [canEdit, setCanEdit] = useState(true);
  const [dynProps, setDynProps] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [autocompleteData, setAutocompleteData] = useState({
    items: [],
    businessPartners: [],
  });
  const [autocompleteOption, setAutocompleteOption] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});

  useEffect(() => {
    getUser();
    getMenus();
    getAllItem();
    getAllBusinessPartner();

    const def = {
      date: dayjs(new Date()),
      customer: null,
      item: null,
      statusId: null,
      description: '',
    };

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteOption).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          statusId: autocompleteOption?.status?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          status: autocompleteOption?.status?.defItem,
        };
      });
    }
  }, [autocompleteOption]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('complaint');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (submenu) initDefaultValues();
  }, [submenu]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    masterDataService
      .statusToForm(submenu)
      .then((data) => {
        const aData = {
          status: data,
        };
        setAutocompleteOption(aData);
      })
      .finally(() => {});
  };

  const valuesToForm = () => {
    const { date, customer, item, prefixedId, description, statusId } = selectedValues;

    setInitValues({
      date: dayjs(date),
      customer,
      item,
      statusId,
      prefixedId: prefixedId ?? '',
      description: description ?? defaultValues.description,
      dynamicFields: getDynVals(dynProps, selectedValues.dynamicProperties),
    });
  };

  const validationSchema = Yup.object().shape({
    date: Yup.string().required(),
    customer: Yup.string().required(),
    item: Yup.string().required(),
    statusId: Yup.string().required(),
    desciption: Yup.string().max(500),
  });

  const getAllItem = () => {
    complaintService.getItems().then((data) => {
      const formattedItems = data.map((item) => ({
        label: `${item.itemCode} - ${item.itemName}`,
        value: item.itemCode,
      }));

      setAutocompleteData((prevState) => ({
        ...prevState,
        items: formattedItems,
      }));
    });
  };

  const getAllBusinessPartner = () => {
    complaintService.getBusinessPartners().then((data) => {
      const formattedBusinessPartners = data.map((partner) => ({
        label: `${partner.companyId} - ${partner.companyName}`,
        value: partner.companyId,
      }));

      setAutocompleteData((prevState) => ({
        ...prevState,
        businessPartners: formattedBusinessPartners,
      }));
    });
  };

  const insertObject = (values) => {
    const insertObject = {
      ...values,
      date: dayjs(values.date).toISOString(),
      createdBy: user.userId,
    };

    complaintService
      .createComplaint(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
        setSelectedValues(data[0]);

        setRowSelectionModel(data[0].id);
      })

      .finally(() => {
        //@TODO loader
      });
  };

  const updateObject = (values) => {
    const updData = {
      ...values,
      date: dayjs(values.date).toISOString(),
      updatedBy: user.userId,
    };

    complaintService.updateComplaint(updData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      insertObject(values, resetForm);
    }
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    complaintService.deleteComplaint(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues({});
      setInitValues(defaultSelectedValues);
    });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-6 h-screen gap-2 p-4 bg-gray-100 mt-[-4rem] pt-[5rem]">
      <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="h-full overflow-auto custom-scrollbar">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues(defaultSelectedValues);
              setViewField(false);
              setAddField(false);
            }}
            addDisabled={!viewField || !canEdit}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled || !canEdit}
            copyClick={() => {
              setViewField(false);
              setAddField(false);
            }}
            copyDisabled={!viewField || operatorsDisabled || !canEdit}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, resetForm, validateField, setFieldValue }) => (
              <Form>
                <ComplaintFields
                  viewField={viewField}
                  selectedValues={selectedValues}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  validateField={validateField}
                  submenuId={submenu}
                  autocompleteData={autocompleteData}
                />

                <ComplaintTabs
                  disabled={viewField}
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  submenuId={submenu}
                  setFieldValue={setFieldValue}
                  setDefaultValues={setDefaultValues}
                  setRefetchTable={setRefetchTable}
                  dynProperties={{ dynProps, setDynProps }}
                  errors={errors}
                  values={values}
                  user={user}
                />
                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      resetForm();
                    }}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white shadow sm:rounded-lg  ">
        <div className="h-full overflow-auto custom-scrollbar">
          <ComplaintReports
            refetch={{ refetchTable, setRefetchTable }}
            values={{ selectedValues, setSelectedValues }}
            viewField={viewField}
            operatorsDisabled={operatorsDisabled}
            submenuId={submenu}
            defaultValues={defaultValues}
            editButtonVisible={editButtonVisible}
            autocompleteData={autocompleteData}
            rowSelection={{ rowSelectionModel, setRowSelectionModel }}
            submenu={submenu}
          />
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </div>
  );
};
