import React, { useState, useEffect } from 'react';

import appService from '../../service/app.service';
import { ButtonUI, DialogUI, FormLabelUI, SelectUI } from '../Interface';

const BiRoleModify = (props) => {
  const { biRoleState } = props;
  const { biRolePermissions, setBiRolePermissions } = biRoleState;
  const [permission, setPermission] = useState(false);

  useEffect(() => {
    setPermission(props.hasPermission);
  }, [props.hasPermission, props.show]);

  const handleChangeRole = async () => {
    appService
      .updateBiRolePermission(props.roleId, props.selectedBiReport, permission)
      .then(() => {
        const updatedSubMenuList = biRolePermissions.map((permission) =>
          permission.biReportId === props.selectedBiReport ? { ...permission, hasPermission: permission } : permission
        );
        setBiRolePermissions(updatedSubMenuList);
        props.setRefreshPage(!props.refreshPage);
        props.onHide();
      })
      .finally(() => {});
  };

  const options = [
    { value: true, label: 'Jogosult' },
    { value: false, label: 'Nem jogosult' },
  ];

  return (
    <DialogUI open={props.show} headerContent={'Jogosultság módosítás'} fullWidth>
      <div className="flex justify-center">
        <div className="mt-2 mx-2 w-2/5">
          <SelectUI
            value={permission ?? ''}
            onChange={(e) => {
              const value = e.target.value;
              setPermission(value);
            }}
            option={options}
            label={<FormLabelUI text="Jogosultság beállítása" />}
          />
        </div>
      </div>
      <div className="px-4 mt-8 flex justify-center gap-3">
        <ButtonUI text="Hozzáadás" type="button" className="bg-success" onClick={handleChangeRole}>
          Mentés
        </ButtonUI>

        <ButtonUI text="Mégse" type="button" onClick={props.onHide} color="blue-gray" variant="gradient"></ButtonUI>
      </div>
    </DialogUI>
  );
};

export default BiRoleModify;
