import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import DeleteDialog from './DeleteDialog';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';

import { Document, Page, pdfjs } from 'react-pdf';
import appService from '../../service/app.service';

export default function DragAndDrop(props) {
  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [removeId, setRemoveId] = useState(null);

  const listFilesForSimpleTask = async () => {
    const requestData = {
      tables: ['simpleTaskAttachment'],
      joins: [],
      conditions: [`SimpleTaskId = ${props.SimpleTaskId ?? 0}`],
      selectedColumns: ['simpleTaskAttachment.*'],
    };

    appService.getJoinedData(requestData).then((data) => {
      setFiles(data);
    });
  };

  const viewFile = (file) => {
    setCurrentFile(file);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const downloadFile = async (fileName) => {
    try {
      /*const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/download-file/${fileName}`,
        { responseType: 'blob' } // Fontos, hogy a válasz a 'blob' típusú legyen
      );

      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);*/
    } catch (error) {
      //console.error(error);
    }
  };

  const removeAttachment = async (id) => {
    const deleteConditions = [{ AttachmentId: id }];

    const logicalOperator = 'AND'; // Vagy "OR", amit használni szeretnél

    // Elkészítjük a HTTP kérés testét
    const requestBody = {
      tableName: 'simpleTaskAttachment',
      conditions: deleteConditions,
      logicalOperator: logicalOperator,
    };

    appService
      .deleteItems(requestBody)
      .then(() => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.INFO,
        });
        props.setFileList(props.fileList.filter((item) => item.AttachmentId !== id));
      })
      .finally(() => {
        setShowDeleteDialog(false);
      });
  };

  const handleFileUpload = (file) => {
    const insertObject = {
      AttachmentName: file.name,
      //SimpleTaskId: props.SimpleTaskId
    };

    const formData = new FormData();
    formData.append('file', file);

    props.setNewFiles([...props.newFiles, formData]);
    props.setFileList([...props.fileList, insertObject]);
  };

  useEffect(() => {
    listFilesForSimpleTask();
  }, [props]);

  useEffect(() => {
    listFilesForSimpleTask();
  }, []);

  return (
    <div>
      <div className="sm:grid sm:grid-cols-2 sm:items-start sm:gap-4 sm:py-6 ">
        <div className="mt-2 sm:col-span-2 sm:mt-0 ">
          <div
            className={`flex min-w-full justify-center items-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 bg-white ${props.disabled ? 'bg-stone-200 opacity-50 cursor-not-allowed' : ''}`}
          >
            <div className="text-center">
              <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
              <div className="mt-4 flex text-sm leading-6 text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                  <span className={`${props.disabled ? 'opacity-50 cursor-not-allowed' : ''}`}>Fájl feltötlés</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className={`sr-only ${props.disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onChange={(e) => handleFileUpload(e.target.files[0])}
                  />
                </label>
                <p className="pl-1">or drag & drop</p>
              </div>
              <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
            </div>
          </div>
        </div>
      </div>

      <h3 className="mb-4 text-center text-2xl font-semibold">Csatolmányok</h3>

      {props.fileList.map((p, index) => {
        return (
          <div
            key={p.AttachmentId ?? ''}
            className="bg-blue-200 m-1 disabled:bg-slate-200 disabled:cursor-not-allowed flex items-center p-2 rounded-xl border border-blue-300  px-2   text-center text-gray-500 hover:bg-blue-300 hover:cursor-grab my-2 italic"
            onClick={() => viewFile(p)}
            disabled={props.disabled}
          >
            <div className="truncate hover:cursor-grab">
              {' '}
              <button className="hover:cursor-grab">{p.AttachmentName}</button>
            </div>
            <div className="flex flex-auto flex-row-reverse">
              <div className="flex">
                <button
                  className="px-4"
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadFile(p.AttachmentName);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                </button>
                <button
                  className="disabled:cursor-not-allowed disabled:opacity-50"
                  disabled={props.disabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRemoveId(p.AttachmentId);
                    setShowDeleteDialog(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className={
                      props.disabled
                        ? 'feather feather-x h-5 w-5  rounded-full hover:text-yellow-400'
                        : ' feather feather-x h-5 w-5 cursor-pointer rounded-full hover:text-yellow-400'
                    }
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        );
      })}

      <Transition.Root show={showModal} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={() => setShowModal(false)}>
          <div className="flex items-center justify-center min-h-screen p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {/* Növeltük a dialógus méretét TEDD ÁT A H-96-OT H-FULL-RA ÉS AKKOR NAGYOBB LESZ A PDF, DE AKKOR A GOMBHOZ IS TEKERNI KELL*/}
              <div className="relative bg-white p-4 sm:p-6 lg:p-8 max-w-2xl w-full mx-auto">
                <div className="mt-2">
                  {currentFile && (
                    <>
                      {currentFile.AttachmentName.endsWith('.pdf') ? (
                        <Document
                          file={`${process.env.REACT_APP_API_URL}/download-file/${currentFile.AttachmentName}`}
                          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                        >
                          {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                          ))}
                        </Document>
                      ) : (
                        <img
                          src={`${process.env.REACT_APP_API_URL}/download-file/${currentFile.AttachmentName}`}
                          alt={currentFile.AttachmentName}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <DeleteDialog
        open={showDeleteDialog}
        setOpen={() => setShowDeleteDialog(false)}
        handleRemoveElement={() => removeAttachment(removeId)}
      />
    </div>
  );
}
