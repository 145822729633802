import { useState, useEffect } from 'react';

import appService from '../../service/app.service';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import { Field, Form, Formik } from 'formik';
import Yup from '../../utils/yup';
import { ButtonUI, DialogUI, FormErrorUI, FormLabelUI, InputUI } from '../Interface';

export function AddRole(props) {
  const [role, setRole] = useState([]);

  const validationSchema = Yup.object().shape({
    roleName: Yup.string().required(),
  });

  const getRoles = async () => {
    appService
      .getRole()
      .then((data) => {
        setRole(data);
      })
      .finally(() => {});
  };

  useEffect(() => {
    getRoles();
  }, []);

  const addRoleGroup = async (values) => {
    appService
      .addRole(values)
      .then((_data) => {
        addNotification({
          content: 'Sikeres hozzáadás!',
          type: NotificationType.SUCCESS,
        });
        props.setRole([...props.role, values]);
        props.onHide();
        props.fetchData();
      })
      .finally(() => {});
  };

  return (
    <DialogUI open={props.show} headerContent={'Jogosultság hozzáadása'} fullWidth={true}>
      <Formik
        initialValues={{ roleName: '' }}
        onSubmit={(values, { setSubmitting }) => {
          addRoleGroup(values);
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <div className="flex justify-center mt-2">
              <div className="w-2/5">
                <Field
                  type="text"
                  as={InputUI}
                  variant="standard"
                  name="roleName"
                  id="roleName"
                  label={<FormLabelUI text="Jogosultság neve" />}
                  helperText={<FormErrorUI message={errors.roleName} />}
                />
              </div>
            </div>
            <div className="px-4 mt-8 flex justify-center gap-3">
              <ButtonUI type="submit" text="Mentés" className="bg-success" disabled={isSubmitting}>
                Mentés
              </ButtonUI>

              <ButtonUI
                type="button"
                disabled={isSubmitting}
                text="Mégse"
                color="blue-gray"
                variant="gradient"
                onClick={props.onHide}
              >
                Mégse
              </ButtonUI>
            </div>
          </Form>
        )}
      </Formik>
    </DialogUI>
  );
}
