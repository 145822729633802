import React from 'react';

import { PropertyType } from '../../config/property';
import DynTextInput from './DynTextInput';
import DynComboInput from './DynComboInput';
import DynDateInput from './DynDateInput';
import { getMultiFieldHeight } from '../../utils/DynHelper';

const DynamicPropertyField = (props) => {
  const { fieldData } = props;
  const { property } = fieldData;
  const { type, characterLength } = property;

  if (type === PropertyType.TEXT) {
    return <DynTextInput {...props} type="text" />;
  } else if (type === PropertyType.NUMBER) {
    return <DynTextInput {...props} type="number" precision={1} />;
  } else if (type === PropertyType.DECIMAL) {
    return <DynTextInput {...props} type="number" precision={0.01} />;
  } else if (type === PropertyType.COMBO) {
    return <DynComboInput {...props} />;
  } else if (type === PropertyType.DATE) {
    return <DynDateInput {...props} />;
  } else if (type === PropertyType.LONGTEXT) {
    return <DynTextInput {...props} type="text" rows={getMultiFieldHeight(characterLength)} multiline />;
  } else {
    return <div>Hiba történt</div>;
  }
};

export default DynamicPropertyField;
