import React from 'react';
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from '@material-tailwind/react';

const TabsUI = ({ activeTab, handleTabClick, children, alignToStart }) => {
  return (
    <Tabs value={activeTab} className="w-full">
      <TabsHeader
        className={`${
          alignToStart ? 'inline-flex justify-start mx-4' : 'mx-auto flex flex-col md:flex-row'
        } overflow-x-auto custom-scrollbar tab-scroll`}
      >
        {React.Children.map(children, (tab, index) => {
          if (tab)
            return (
              <Tab
                key={index}
                value={index}
                onClick={() => handleTabClick(index)}
                className={alignToStart ? 'px-4 text-labelColor' : 'text-labelColor'}
              >
                {tab.props.name}
              </Tab>
            );
        })}
      </TabsHeader>
      <TabsBody
        className="mt-5"
        animate={{
          initial: { y: 250 },
          mount: { y: 0 },
          unmount: { y: 250 },
        }}
      >
        {React.Children.map(children, (tab, index) => {
          if (tab) {
            return (
              <TabPanel key={index} value={index}>
                {tab.props.children}
              </TabPanel>
            );
          }
        })}
      </TabsBody>
    </Tabs>
  );
};

export default TabsUI;
