import React from 'react';
import { ButtonUI, DataGridUI, DataGridIdentifiers, DialogUI } from '../Interface';
import { dataGridColumns } from '../../utils/helper';

export const ProductionReportDialog = ({ showDialog, setShowDialog, production, onSelectionChange }) => {
  const { productionList } = production;

  const columns = dataGridColumns([
    {
      field: 'workingNumber',
      headerName: 'Munkaszám',
      flex: 1,
    },
    {
      field: 'operationIdlabel',
      headerName: 'Művelet azonosító',
      flex: 1,
    },
    {
      field: 'operationName',
      headerName: 'Művelet',
      flex: 1,
    },
  ]);

  const rowSelectionModelChange = (newSelection) => {
    if (newSelection.length > 0) {
      const selectedRowData = productionList.find((row) => row.prodOperationId === newSelection[0]);
      if (selectedRowData) {
        onSelectionChange(selectedRowData);
      }
    }
  };

  return (
    <DialogUI open={showDialog} headerContent="Keresési találatok" fullWidth>
      <div className="mt-5">
        <div className="mt-3">
          <DataGridUI
            datagridIdentifier={DataGridIdentifiers.ProductionReportDialog}
            rows={productionList}
            columns={columns}
            onRowSelectionModelChange={rowSelectionModelChange}
            getRowId={(row) => row.prodOperationId}
            sx={{
              height: '100%',
              width: '100%',
            }}
            hideFooter
          />
        </div>
      </div>
      <div className="flex justify-center mt-5">
        <ButtonUI color="blue-gray" text="Bezárás" onClick={() => setShowDialog(false)} />
      </div>
    </DialogUI>
  );
};
