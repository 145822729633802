import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { dataGridColumns } from '../../utils/helper';
import productionService from '../../service/production.service';
import { DataGridUI, DataGridIdentifiers } from '../Interface';

export const OperationReportTable = ({ productionList, refetch }) => {
  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const { refetchTable, setRefetchTable } = refetch;

  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [{ field: 'date', sort: 'desc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  useEffect(() => {
    if (productionList?.visOrder && Object.keys(query).length > 0) {
      getProductionReports(productionList.prodOperationId);
    }
  }, [query, productionList]);

  useEffect(() => {
    if (refetchTable && query.filter?.length > 0 && productionList?.prodOperationId) {
      getProductionReports(productionList.prodOperationId);
    }
  }, [refetchTable, productionList]);

  const onPaginationChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  useEffect(() => {
    if (productionList?.prodOperationId) {
      getProductionReports(productionList.prodOperationId);
    } else {
      setReports({ rows: [], rowCount: 0 });
    }
  }, [productionList, refetchTable]);

  const getProductionReports = (prodOperationId) => {
    productionService.getProductionReports(prodOperationId, query).then((data) => {
      setReports(data);
      setRefetchTable(false);
    });
  };

  const columns = dataGridColumns([
    {
      field: 'id',
      headerName: 'Sorszám',
      flex: 1,
    },
    {
      field: 'date',
      headerName: 'Időpont',
      flex: 1,
      valueGetter: (data) => {
        return dayjs(data.row.date).format('YYYY.MM.DD. HH:mm');
      },
    },
    {
      field: 'quantity',
      headerName: 'Mennyiség',
      flex: 1,
      valueGetter: (data) => {
        return `${data.row.quantity} ${productionList.quantityUnit}`;
      },
    },
    {
      field: 'netWeight',
      headerName: 'Nettó súly',
      flex: 1,
      valueGetter: (data) => {
        return `${data.row.netWeight} kg`;
      },
    },
    {
      field: 'packingWeight',
      headerName: 'Csomagolás súly',
      flex: 1,
      valueGetter: (data) => {
        return `${data.row.packingWeight} kg`;
      },
    },
    {
      field: 'timeSpent',
      headerName: 'Idő ráfordítás',
      flex: 1,
    },
    {
      field: 'userId',
      headerName: 'Dolgozó',
      flex: 1,
      valueGetter: (data) => {
        return data.row.user?.userName;
      },
    },
  ]);

  return (
    <div className="col-span-4 flex flex-col overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="col h-full flex-col overflow-hidden bg-white p-2 shadow sm:rounded-lg">
        <div className="flex h-full overflow-hidden border-t border-gray-200">
          <div className="w-full overflow-x-auto xxl:overflow-x-hidden">
            <div className="z-40 min-w-full h-[100%]">
              <DataGridUI
                datagridIdentifier={DataGridIdentifiers.OperationReportTable}
                rows={reports.rows}
                columns={columns}
                rowCount={reports.rowCount}
                sx={{ height: 520, width: '100%' }}
                getRowId={(row) => row.id}
                paginationMode="server"
                paginationModel={query.pagination.paginationModel}
                onPaginationModelChange={onPaginationChange}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
