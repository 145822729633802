import React, { useState, useEffect } from 'react';

import appService from '../../service/app.service';
import { ButtonUI, DialogUI, FormLabelUI, SelectUI } from '../Interface';

const RoleModify = (props) => {
  const [permission, setPermission] = useState(props.hasPermission);
  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    setPermission(props.hasPermission);
    const findedPerm = props.roleSubMenuPermissions.find((f) => f.subMenuId === props.selectedSubMenu);
    setCanEdit(findedPerm?.roleSubmenuPermission[0]?.canEdit ?? true);
  }, [props]);

  const handleChangeRole = async () => {
    appService
      .updateSubMenuPermission(props.roleId, props.selectedSubMenu, permission, canEdit)
      .then(() => {
        const updatedSubMenuList = props.roleSubMenuPermissions.map((permission) =>
          permission.subMenuId === props.selectedSubMenu ? { ...permission, hasPermission: permission } : permission
        );
        props.setRoleSubMenuPermissions(updatedSubMenuList);
        props.setRefreshPage(!props.refreshPage);
        props.onHide();
      })
      .finally(() => {});
  };

  const options = [
    { value: true, label: 'Jogosult' },
    { value: false, label: 'Nem jogosult' },
  ];

  return (
    <DialogUI open={props.show} headerContent={'Jogosultság módosítás'} fullWidth>
      <div className="flex justify-center">
        <div className="mt-2 mx-2 w-2/5">
          <SelectUI
            value={permission ?? ''}
            onChange={(e) => {
              const value = e.target.value;
              setPermission(value);
            }}
            option={options}
            label={<FormLabelUI text="Jogosultság beállítása" />}
          />
        </div>
        <div className="mt-2 mx-2 w-2/5">
          <SelectUI
            value={canEdit ?? ''}
            onChange={(e) => {
              const value = e.target.value;
              setCanEdit(value);
            }}
            option={options}
            label="Szerkesztheti"
          />
        </div>
      </div>
      <div className="px-4 mt-8 flex justify-center gap-3">
        <ButtonUI text="Hozzáadás" type="button" className="bg-success" onClick={handleChangeRole}>
          Mentés
        </ButtonUI>

        <ButtonUI text="Mégse" type="button" onClick={props.onHide} color="blue-gray" variant="gradient"></ButtonUI>
      </div>
    </DialogUI>
  );
};

export default RoleModify;
