import React from 'react';
import { Field } from 'formik';

import { InputUI, AutoCompleteSelectUI, FormLabelUI, FormErrorUI } from '../Interface';

export const FormFields = ({
  viewField,
  selectedValues,
  values,
  validateField,
  setFieldValue,
  errors,
  autoCompleteData,
}) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 mx-4 mb-5">
      <div className="col-span-4 ">
        <Field
          type="text"
          name="nickName"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Név" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.nickName} />}
          InputLabelProps={{ shrink: values.nickName !== '' }}
          disabled={viewField}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="userName"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Felhasználónév" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.userName} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.userName !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="password"
          name="password"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Jelszó" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.password} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.password !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="activeId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Aktív" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.activeId} />}
          onChange={(_e, newVal) => {
            setFieldValue('activeId', newVal?.value ?? null).then(() => {
              validateField('activeId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.activeId !== '' }}
          selectedValue={values.activeId}
          selectedLabelValue={selectedValues?.webuserarchive?.arhiveName}
          table={'WebUserArchive'}
          listType={{ id: 'archiveId', name: 'arhiveName' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="roleId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Jogosultsági csoport" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.roleId} />}
          onChange={(_e, newVal) => {
            setFieldValue('roleId', newVal?.value ?? null).then(() => {
              validateField('roleId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.roleId !== '' }}
          selectedValue={values.roleId}
          selectedLabelValue={selectedValues?.roles?.roleName}
          table={'roles'}
          listType={{ id: 'roleId', name: 'roleName' }}
        />
      </div>

      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="homePage"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Kezdőképernyő" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.homePage} />}
          onChange={(_e, newVal) => {
            setFieldValue('homePage', newVal?.value ?? null);
            validateField('homePage');
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.homePage !== '' }}
          selectedValue={values.homePage}
          selectedLabelValue={selectedValues?.homePage}
          dataset={autoCompleteData}
        />
      </div>
    </div>
  );
};
