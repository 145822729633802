import { useState, useEffect } from 'react';

import { AutoCompleteSelectUI, DataGridUI, DataGridIdentifiers, OrderArrowsUI, DebouncedInputUI, FormLabelUI } from '../Interface';
import machineService from '../../service/machine.service';
import { dataGridColumns, generateFilter } from '../../utils/helper';

export default function MachineTable({ refetch, values, viewField, operatorsDisabled, submenuId }) {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [{ field: 'visOrder', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  const [filterFormState, setFilterFormState] = useState({
    machineId: '',
    machineName: '',
    archive: '',
    machineColor: '',
    machineIcon: '',
    machineTypeId: '',
    active: '',
  });

  const conditions = {
    like: ['machineName'],
  };

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'archive',
        value: 'N',
      },
    ];

    const newFilter = generateFilter(filterFormState, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
  }, [filterFormState]);

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getList();
    }
  }, [query]);

  useEffect(() => {
    if (refetchTable && query.filter?.length > 0) {
      getList();
    }
  }, [refetchTable]);

  const onPaginitonChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  const getList = () => {
    machineService.getMachines(query).then((data) => {
      setReports(data);
      setRefetchTable(false);
    });
  };

  const showActive = (params) => {
    const { active } = params.row;
    if (active === 1) {
      return 'Igen';
    } else if (active === 2) {
      return 'Nem';
    } else {
      return 'Nincs adat';
    }
  };

  const orderMachine = (machineId, newOrder) => {
    machineService.orderMachine(machineId, { newOrder }).then(() => {
      setRefetchTable(true);
    });
  };

  const handleOrderChange = (machineId, currentOrder, direction) => {
    const newOrder = direction === 'up' ? currentOrder - 1 : currentOrder + 1;
    orderMachine(machineId, newOrder);
  };

  const columns = dataGridColumns([
    {
      field: 'machineName',
      headerName: 'Név',
      flex: 1,
    },
    {
      field: 'machineGroup',
      headerName: 'Csoport',
      flex: 1,
      valueGetter: (data) => {
        return data.row.machineGroup?.itemName;
      },
    },
    {
      field: 'active',
      headerName: 'Aktív',
      flex: 1,
      renderCell: (params) => {
        return showActive(params);
      },
    },
    {
      field: 'visOrder',
      headerName: 'Sorrend',
      flex: 1,
      renderCell: (params) => {
        const handleUpClick = () => handleOrderChange(params.row.machineId, params.row.visOrder, 'up');
        const handleDownClick = () => handleOrderChange(params.row.machineId, params.row.visOrder, 'down');
        return <OrderArrowsUI onUpClick={handleUpClick} onDownClick={handleDownClick} />;
      },
      sortable: false,
    },
  ]);

  return (
    <div className="flex  overflow-hidden m-4 ">
      <div className="h-full w-full  overflow-x-auto ">
        <div className="grid grid-cols-4 gap-x-4 mx-4">
          <div className="col-span-2 md:col-span-1">
            <DebouncedInputUI
              label={<FormLabelUI text="Erőforrás neve" />}
              debounceMs={800}
              setQuickFilterSearchValue={(newValue) => {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  machineName: newValue,
                }));
              }}
              quickFilterSearchValue={filterFormState.machineName}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <AutoCompleteSelectUI
              id="machineTypeId"
              label={<FormLabelUI text="Erőforrás csoportja" />}
              selectedValue={filterFormState.machineTypeId}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    machineTypeId: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    machineTypeId: newVal.value,
                  }));
                }
              }}
              table={['ProjectGroupListitems', 'ProjectGroup']}
              joins={'ProjectGroupListitems.projectGroupStatusId = ProjectGroup.projectGroupStatusId'}
              listType={{ id: 'itemId', name: 'itemName' }}
              conditions={`ProjectGroup.subMenuId = ${submenuId}`}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <AutoCompleteSelectUI
              key="active"
              id="WebUserArchive"
              label={<FormLabelUI text="Aktív" />}
              table="WebUserArchive"
              selectedValue={filterFormState.active}
              listType={{ id: 'archiveId', name: 'arhiveName' }}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    active: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    active: newVal.value,
                  }));
                }
              }}
            />
          </div>
        </div>
        <div className="inline-block min-w-full border-b border-gray-200 align-middle mt-4 overflow-y-auto overflow-x-auto ">
          <DataGridUI
            datagridIdentifier={DataGridIdentifiers.MachineTable}
            initialState={query}
            rows={reports.rows}
            rowCount={reports.rowCount}
            columns={columns}
            onRowClick={(rowData) => {
              if (!viewField) return;
              setSelectedValues(rowData.row);
            }}
            paginationMode="server"
            onPaginationModelChange={onPaginitonChange}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            disableRowSelectionOnClick={!viewField}
            height={812}
          />
        </div>
      </div>
    </div>
  );
}
