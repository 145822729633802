export const KanbanType = {
  TASK: 'TASK',
  PROJECT: 'PROJECT',
  RESOURCE: 'RESOURCE',
  COMPANY: 'COMPANY',
};

const ViewConfig = {
  task: { label: 'Feladatok', value: KanbanType.TASK },
  project: { label: 'Projektek', value: KanbanType.PROJECT },
  resource: { label: 'Dolgozók', value: KanbanType.RESOURCE },
  company: { label: 'Cégek', value: KanbanType.COMPANY },
};

export const ViewType = [ViewConfig.task, ViewConfig.project, ViewConfig.resource];
export const FunnelViewType = [ViewConfig.task, ViewConfig.project, ViewConfig.resource, ViewConfig.company];

export const KanbanCard = [
  { type: KanbanType.TASK, paramName: 'KANBAN_TASK_CARD_CONTENT', colorField: 'priorityColor' },
  { type: KanbanType.PROJECT, paramName: 'KANBAN_PROJECT_CARD_CONTENT', colorField: 'statusColor' },
  { type: KanbanType.RESOURCE, paramName: 'KANBAN_RESOURCE_CARD_CONTENT', colorField: 'statusColor' },
  { type: KanbanType.COMPANY, paramName: 'KANBAN_COMPANY_CARD_CONTENT', colorField: 'statusColor' },
];

export const KanbanDialog = [
  { type: KanbanType.TASK, paramName: 'KANBAN_TASK_DIALOG_CONTENT', colorField: 'priorityColor' },
  { type: KanbanType.PROJECT, paramName: 'KANBAN_PROJECT_DIALOG_CONTENT', colorField: 'statusColor' },
  { type: KanbanType.RESOURCE, paramName: 'KANBAN_RESOURCE_DIALOG_CONTENT', colorField: 'statusColor' },
  { type: KanbanType.COMPANY, paramName: 'KANBAN_COMPANY_DIALOG_CONTENT', colorField: 'statusColor' },
];

export const conditions = {
  [KanbanType.TASK]: {
    like: ['simpleTaskName'],
  },
  [KanbanType.PROJECT]: {
    like: ['projectName'],
  },
  [KanbanType.RESOURCE]: {
    like: ['name'],
  },
  [KanbanType.COMPANY]: {
    like: ['companyName'],
  },
};

export const KanbanFilterFormState = {
  [KanbanType.TASK]: {
    simpleTaskName: '',
    resposiblePersonId: '',
  },
  [KanbanType.PROJECT]: {
    projectName: '',
  },
  [KanbanType.RESOURCE]: {
    name: '',
  },
  [KanbanType.COMPANY]: {
    companyName: '',
  },
};

export const getKanbanFooterTitle = (type) => {
  switch (type) {
    case KanbanType.TASK:
      return 'Feladatok száma';
    case KanbanType.PROJECT:
      return 'Projektek száma';
    case KanbanType.RESOURCE:
      return 'Dolgozók száma';
    case KanbanType.COMPANY:
      return 'Cégek száma';
    default:
      return 'Összesen';
  }
};
