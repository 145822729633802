import React, { useState, useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';
import { Formik, Form, Field, FieldArray } from 'formik';

import biService from '../../../service/bi.service';
import dashboardService from '../../../service/dashboard.service';
import addNotification from '../../../utils/addNotification';
import { NotificationType } from '../../../config';
import Yup from '../../../utils/yup';
import {
  InputUI,
  AutoCompleteSelectUI,
  FormHeaderUI,
  FormLabelUI,
  FormErrorUI,
  FormButtonPanelUI,
  SelectUI,
  CheckboxUI,
  DeleteDialogUI,
} from '../../Interface';
import DashboardReports from './DashboardReports';
import useMenus from '../../../context/MenuContext';
import useUsers from '../../../context/UsersContext';
import { DashboardTypeList } from '../../../config/dashboard';

const DashboardAdmin = () => {
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getUser, user } = useUsers();
  const [addField, setAddField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [refetchTable, setRefetchTable] = useState(true);
  const [viewField, setViewField] = useState(true);
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [canEdit, setCanEdit] = useState(true);
  const [initValues, setInitValues] = useState({});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [sourceData, setSourceData] = useState({
    biReports: [],
  });
  const [autocompleteData, setAutocompleteData] = useState({
    biReports: [],
  });

  useEffect(() => {
    getMenus();
    getAllReports();
    getUser();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('dashboardAdmin');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!isEqual(defaultValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        valuesToForm();
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  useEffect(() => {
    setAutocompleteData((prev) => {
      const newAutocompleteData = { ...prev };

      if (!isEqual(sourceData.biReports, prev.biReports)) {
        newAutocompleteData.biReports = [];
        sourceData.biReports.forEach((biReport) => {
          newAutocompleteData.biReports.push({
            value: biReport.id,
            label: biReport.name,
          });
        });
      }

      return newAutocompleteData;
    });
  }, [sourceData]);

  const getAllReports = () => {
    biService.allBiReports().then((data) =>
      setSourceData((prev) => {
        return { ...prev, biReports: data };
      })
    );
  };

  const handleSubmit = (values) => {
    const data = {
      ...values,
      ...(!values.id ? { createdBy: user.userId } : { updatedBy: user.userId }),
      dashboardReport: values.dashboardReport.map((report) => ({ biReportId: report.biReportId })),
    };

    dashboardService.upsertDashboard(data).then((data) => {
      setRefetchTable(true);
      setViewField(true);
      setEditButtonvisible(true);
      setAddField(true);
    });
  };

  const valuesToForm = () => {
    setInitValues(selectedValues);
  };

  const defaultValues = useMemo(
    () => ({
      name: '',
      type: null,
      bpFilter: false,
      yearFilter: false,
      quarterFilter: false,
      monthFilter: false,
      intervalFilter: false,
      dashboardReport: [],
    }),
    []
  );

  const defaultReports = {
    biReportId: null,
  };

  const validationSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    name: Yup.string().required(),
    type: Yup.string().required(),
    bpFilter: Yup.boolean(),
    yearFilter: Yup.boolean(),
    quarterFilter: Yup.boolean(),
    monthFilter: Yup.boolean(),
    intervalFilter: Yup.boolean(),
    dashboardReport: Yup.array().of(
      Yup.object().shape({
        biReportId: Yup.string().required(),
      })
    ),
  });

  const generateRows = (value, errors, setFieldValue) => {
    const findedType = DashboardTypeList.find((dt) => value === dt.value);

    if (findedType) {
      const rowCount = findedType.column * findedType.row;
      const rowArray = [];

      for (let i = 0; i < rowCount; i++) {
        rowArray.push(defaultReports);
      }

      return setFieldValue('dashboardReport', rowArray);
    }

    return setFieldValue('dashboardReport', []);
  };

  const handleRemoveElement = async () => {
    dashboardService.deleteDashboard(selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues({});
    });
  };

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-6 h-screen gap-2 p-4 bg-gray-100 mt-[-4rem] pt-[5rem]">
        <div className="col-span-1 lg:col-span-1 xl:col-span-2 overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="h-full overflow-auto custom-scrollbar">
            <FormHeaderUI
              addClick={() => {
                setSelectedValues({});
                setInitValues(defaultValues);
                setViewField(false);
                setAddField(false);
              }}
              addDisabled={!viewField || !canEdit}
              modClick={() => {
                setViewField(false);
                setEditButtonvisible(false);
              }}
              modDisabled={!viewField || operatorsDisabled || !canEdit}
              deleteClick={() => {
                setShowDeleteDialog(true);
              }}
              deleteDisabled={!viewField || operatorsDisabled || !canEdit}
            />

            <Formik
              initialValues={initValues}
              validationSchema={validationSchema}
              validateOnChange={false}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ values, errors, validateField, setFieldValue, resetForm }) => (
                <Form>
                  <div className="grid grid-cols-4 gap-4 mx-4 pb-6">
                    <div className="col-span-3">
                      <Field
                        type="text"
                        name="name"
                        as={InputUI}
                        fullWidth
                        disabled={viewField}
                        label={<FormLabelUI text="Alias név" />}
                        variant="standard"
                        helperText={<FormErrorUI message={errors.name} />}
                        InputLabelProps={{ shrink: values.name !== '' }}
                      />
                    </div>
                    <div className="col-span-1 ">
                      <Field
                        name="type"
                        component={SelectUI}
                        fullWidth
                        option={DashboardTypeList}
                        value={values.type ?? ''}
                        variant="standard"
                        onChange={async (e) => {
                          await setFieldValue(`type`, e.target.value ?? null);
                          await validateField(`type`);
                          generateRows(e.target.value, errors, setFieldValue);
                        }}
                        disabled={viewField}
                        label={<FormLabelUI text="Mező típus" />}
                        helperText={<FormErrorUI message={errors?.type} />}
                        InputLabelProps={{ shrink: values.type !== '' }}
                      />
                    </div>
                    <div className="col-span-1 ">
                      <Field
                        name="bpFilter"
                        type="checkbox"
                        as={CheckboxUI}
                        fullWidth
                        label={<FormLabelUI text="Üp. szűrő" />}
                        disabled={viewField}
                      />
                    </div>
                    <div className="col-span-3">
                      <Field
                        name="responsibleFilter"
                        type="checkbox"
                        as={CheckboxUI}
                        fullWidth
                        label={<FormLabelUI text="Felelős szűrő" />}
                        disabled={viewField}
                      />
                    </div>
                    <div className="col-span-1 ">
                      <Field
                        name="yearFilter"
                        type="checkbox"
                        as={CheckboxUI}
                        fullWidth
                        label={<FormLabelUI text="Év szűrő" />}
                        disabled={viewField}
                      />
                    </div>
                    <div className="col-span-1 ">
                      <Field
                        name="quarterFilter"
                        type="checkbox"
                        as={CheckboxUI}
                        fullWidth
                        label={<FormLabelUI text="Negyedév szűrő" />}
                        disabled={viewField}
                      />
                    </div>
                    <div className="col-span-1 ">
                      <Field
                        name="monthFilter"
                        type="checkbox"
                        as={CheckboxUI}
                        fullWidth
                        label={<FormLabelUI text="Hónap szűrő" />}
                        disabled={viewField}
                      />
                    </div>
                    <div className="col-span-1 ">
                      <Field
                        name="intervalFilter"
                        type="checkbox"
                        as={CheckboxUI}
                        fullWidth
                        label={<FormLabelUI text="Intervallum szűrő" />}
                        disabled={viewField}
                      />
                    </div>
                    <FieldArray name="report">
                      <>
                        {values?.dashboardReport?.map((report, index) => {
                          return (
                            <div className="col-span-4 md:col-span-2">
                              <Field
                                type="autocomplete"
                                name={`dashboardReport.${index}.biReportId`}
                                component={AutoCompleteSelectUI}
                                fullWidth
                                label={<FormLabelUI text={`Riport #${index + 1}`} />}
                                variant="standard"
                                helperText={<FormErrorUI message={errors.dashboardReport?.[index]?.biReportId} />}
                                inputLabelProps={{ shrink: report.biReportId !== null }}
                                onChange={(_e, newVal) => {
                                  setFieldValue(`dashboardReport.${index}.biReportId`, newVal?.value ?? null).then(
                                    () => {
                                      validateField(`dashboardReport.${index}.biReportId`);
                                    }
                                  );
                                }}
                                //@TODO ezt ki kell vizsgálni!!!
                                //value={report.biReportId}
                                disabled={viewField}
                                selectedValue={report.biReportId ?? null}
                                selectedLabelValue={
                                  autocompleteData.biReports.find((ac) => ac.value === report.biReportId)?.label ?? ''
                                }
                                dataset={autocompleteData.biReports}
                                isOptionEqualToValue={(option, value) => option.value === value}
                              />
                            </div>
                          );
                        })}
                      </>
                    </FieldArray>
                  </div>
                  {(!editButtonVisible || !addField) && (
                    <FormButtonPanelUI
                      onCancelClick={() => {
                        setViewField(true);
                        setEditButtonvisible(true);
                        setAddField(true);
                        resetForm();
                      }}
                    />
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="col-span-1 lg:col-span-1 xl:col-span-4 overflow-hidden bg-white shadow sm:rounded-lg  ">
          <div className="h-full overflow-auto custom-scrollbar">
            <DashboardReports
              refetch={{ refetchTable, setRefetchTable }}
              values={{ selectedValues, setSelectedValues }}
              viewField={viewField}
              operatorsDisabled={operatorsDisabled}
            />
          </div>
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};

export default DashboardAdmin;
