import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import dayjs from 'dayjs';

import Yup from '../../utils/yup';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import { DialogUI, ButtonUI, DatePickerUI, FormLabelUI, FormErrorUI, InputUI } from '../Interface';
import companyActiveService from '../../service/companyActive.service';

const CompanyDayOffDialog = ({ show, onHide, selectedValues, setSelectedValues, refetch }) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData(defaultValues);
  }, []);

  useEffect(() => {
    setFormData({
      fromDate: selectedValues?.fromDate ? dayjs(selectedValues?.fromDate) : dayjs(),
      toDate: selectedValues?.toDate ? dayjs(selectedValues?.toDate) : dayjs(),
      name: selectedValues?.name,
    });
  }, [selectedValues]);

  const defaultValues = {
    fromDate: selectedValues?.fromDate ? dayjs(selectedValues?.fromDate) : dayjs(),
    toDate: selectedValues?.toDate ? dayjs(selectedValues?.toDate) : dayjs(),
    name: '',
  };

  const validationSchema = Yup.object().shape({
    fromDate: Yup.string().required(),
    toDate: Yup.string().required(),
    name: Yup.string().nullable(),
  });

  const handleAddCompanyActive = (values) => {
    const insVal = {
      ...values,
    };
    companyActiveService
      .createCompanyDayOff(insVal)
      .then((data) => {
        setFormData(defaultValues);
        refetch();
        onHide();
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
      })
      .finally(() => {
        // @TODO loader
      });
  };

  const handleUpdateCompanyActive = (values) => {
    companyActiveService.updateCompanyDayOff(selectedValues.id, values).then((data) => {
      setFormData(defaultValues);
      refetch();
      onHide();
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setSelectedValues((prev) => {
        return { ...prev, fromDate: data.fromDate, toDate: data.toDate };
      });
    });
  };

  return (
    <DialogUI open={show} headerContent="Menetidő" fullWidth={true}>
      <div className=" text-center">
        <div className="flex mt-2 gap-10 items-center justify-center z-50">
          <Formik
            initialValues={formData}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values) => {
              if (selectedValues?.id) {
                return handleUpdateCompanyActive(values);
              }

              handleAddCompanyActive(values);
            }}
          >
            {({ errors, setFieldValue, validateField, values }) => (
              <Form>
                <div className="flex gap-10">
                  <Field
                    type="date"
                    name="fromDate"
                    component={DatePickerUI}
                    value={values.fromDate}
                    fullWidth
                    label={<FormLabelUI text="Kezdő dátum" />}
                    variant="standard"
                    helperText={<FormErrorUI message={errors.fromDate} />}
                    onChange={(date) => {
                      setFieldValue('fromDate', date).then(() => {
                        validateField('fromDate');
                      });
                    }}
                    InputLabelProps={{ shrink: values.fromDate !== '' }}
                  />
                  <Field
                    type="date"
                    name="toDate"
                    component={DatePickerUI}
                    value={values.toDate}
                    fullWidth
                    label={<FormLabelUI text="Befejező dátum" />}
                    variant="standard"
                    helperText={<FormErrorUI message={errors.toDate} />}
                    onChange={(date) => {
                      setFieldValue('toDate', date).then(() => {
                        validateField('toDate');
                      });
                    }}
                    InputLabelProps={{ shrink: values.toDate !== '' }}
                  />
                  <Field
                    type="text"
                    name="name"
                    as={InputUI}
                    fullWidth
                    label={<FormLabelUI text="Megnevezés" />}
                    variant="standard"
                    helperText={<FormErrorUI message={errors.name} />}
                    InputLabelProps={{ shrink: values.name !== '' }}
                  />
                </div>

                <div className="px-4 mt-8 flex justify-center gap-3">
                  <ButtonUI
                    text={selectedValues?.id ? 'Módosítás' : 'Hozzáadás'}
                    className="bg-success"
                    type="submit"
                  ></ButtonUI>
                  <ButtonUI
                    text="Mégse"
                    className=""
                    color="blue-gray"
                    type="button"
                    variant="gradient"
                    onClick={onHide}
                  ></ButtonUI>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </DialogUI>
  );
};

export default CompanyDayOffDialog;
