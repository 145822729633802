import React, { useEffect, useState } from 'react';
import { Field, FieldArray } from 'formik';
import { Delete, Add } from '@mui/icons-material';
import { Typography } from '@material-tailwind/react';

import { BiOrderTypeList, BiReportType } from '../../../config/bi_report';
import { InputUI, FormErrorUI, FormLabelUI, AutoCompleteSelectUI, ButtonUI, SelectUI } from '../../Interface';
import biService from '../../../service/bi.service';

const BiDrillForm = ({
  values,
  validateField,
  setFieldValue,
  errors,
  defaultDrillField,
  autocompleteData,
  fieldErrorState,
  sourceData,
  selectedReport,
}) => {
  const { fieldErrors } = fieldErrorState;

  const [linkedReportFields, setLinkedReportFields] = useState({
    fields: [],
    autocompleteData: [],
  });

  useEffect(() => {
    if (values.biDrillingLink?.linkedReport) {
      const linkFields = [];
      const drillingLinkInDb = selectedReport?.biDrillingLink?.biDrillingLinkField;

      const groupField = values?.biField.find((field) => field.isGroup);
      const findedGroupVal = drillingLinkInDb?.find(
        (savedLinkField) => savedLinkField.parentField === groupField.field
      );

      linkFields.push({
        parentField: findedGroupVal?.parentField ?? groupField.field,
        childField: findedGroupVal?.childField ?? '',
      });

      values.biDrilling.forEach((drillField) => {
        const findedDrillingVal = drillingLinkInDb?.find(
          (savedLinkField) => savedLinkField.parentField === drillField.field
        );

        linkFields.push({
          parentField: findedDrillingVal?.parentField ?? drillField.field,
          childField: findedDrillingVal?.childField ?? '',
        });
      });

      setFieldValue('biDrillingLink.biDrillingLinkField', linkFields);
    }

    if (!values.biDrillingLink?.linkedReport) {
      setFieldValue('biDrillingLink.biDrillingLinkField', []);
      setFieldValue('biDrillingLink.linkedReport', null);
    }
  }, [
    values?.biDrillingLink?.linkedReport,
    selectedReport?.biDrillingLink?.biDrillingLinkField,
    values?.biDrilling,
    values?.biField,
  ]);

  useEffect(() => {
    if (values?.biDrillingLink?.linkedReport) {
      const findedReport = sourceData.biReports.find(
        (biReport) => biReport.id === values?.biDrillingLink?.linkedReport
      );
      if (findedReport.reportType === BiReportType.TABLE) {
        const autocompleteData = findedReport.biField.map((field) => ({
          value: field.field,
          label: field.field,
        }));

        setLinkedReportFields({ fields: findedReport.biField, autocompleteData });
      } else {
        biService.getViewFields(findedReport.biHead.element).then((data) => {
          const autocompleteData = data.map((viewField) => ({
            value: viewField.COLUMN_NAME,
            label: viewField.COLUMN_NAME,
          }));

          setLinkedReportFields({ fields: data, autocompleteData });
        });
      }
    } else {
      setLinkedReportFields({ fields: [], autocompleteData: [] });
    }
  }, [values?.biDrillingLink?.linkedReport]);

  return (
    <>
      <FieldArray name="biDrilling">
        {({ remove, push }) => (
          <>
            <div className="grid grid-cols-3 gap-x-4">
              <div className="col-span-1 flex items-center mx-4">
                <Typography variant="h6" className="mr-2">
                  Mezők
                </Typography>

                <ButtonUI
                  text={<Add fontSize="small" />}
                  className="bg-success p-0.5"
                  type="button"
                  onClick={() => {
                    push(defaultDrillField);
                  }}
                  disabled={!(values.biHeadId && values.reportType)}
                />
              </div>
            </div>
            <div className="grid grid-cols-5 gap-x-4 mx-2 mb-2 justify-items-center items-center">
              {fieldErrors.length > 0 &&
                fieldErrors.map((error) => (
                  <div className="col-span-1" key={error.id}>
                    <FormErrorUI message={error.message} />
                  </div>
                ))}
            </div>
            <div>
              {values?.biDrilling?.length > 0 &&
                values.biDrilling.map((biDrilling, index) => (
                  <div className="grid grid-cols-12 gap-x-4 mx-4 mb-5 md:max-w-[100%] xl:max-w-[100%]" key={index}>
                    <div className="col-span-2 ">
                      <Field
                        type="autocomplete"
                        name={`biDrilling.${index}.field`}
                        component={AutoCompleteSelectUI}
                        fullWidth
                        label={<FormLabelUI text="Mező" />}
                        variant="standard"
                        helperText={<FormErrorUI message={errors.biDrilling?.[index]?.field} />}
                        inputLabelProps={{ shrink: biDrilling.field !== null }}
                        onChange={(_e, newVal) => {
                          setFieldValue(`biDrilling.${index}.field`, newVal?.value ?? null).then(() => {
                            validateField(`biDrilling.${index}.field`);
                          });
                        }}
                        //@TODO ezt ki kell vizsgálni!!!
                        value={biDrilling.field ?? null}
                        dataset={autocompleteData.biField}
                        isOptionEqualToValue={(option, value) => option.value === value}
                      />
                    </div>
                    <div className="col-span-2 ">
                      <Field
                        type="text"
                        name={`biDrilling.${index}.name`}
                        as={InputUI}
                        fullWidth
                        label={<FormLabelUI text="Név" />}
                        variant="standard"
                        helperText={<FormErrorUI message={errors.biDrilling?.[index]?.name} />}
                        InputLabelProps={{ shrink: biDrilling.name !== '' }}
                      />
                    </div>
                    <div className="col-span-2 ">
                      <Field
                        type="autocomplete"
                        name={`biDrilling.${index}.orderField`}
                        component={AutoCompleteSelectUI}
                        fullWidth
                        label={<FormLabelUI text="Rendezés" />}
                        variant="standard"
                        helperText={<FormErrorUI message={errors.biDrilling?.[index]?.orderField} />}
                        inputLabelProps={{ shrink: biDrilling.orderField !== null }}
                        onChange={(_e, newVal) => {
                          setFieldValue(`biDrilling.${index}.orderField`, newVal?.value ?? null).then(() => {
                            validateField(`biDrilling.${index}.orderField`);
                          });
                        }}
                        //@TODO ezt ki kell vizsgálni!!!
                        value={biDrilling.orderField ?? null}
                        dataset={autocompleteData.biField}
                        isOptionEqualToValue={(option, value) => option.value === value}
                      />
                    </div>
                    <div className="col-span-2 ">
                      <Field
                        name={`biDrilling.${index}.orderType`}
                        component={SelectUI}
                        fullWidth
                        option={BiOrderTypeList}
                        value={biDrilling.orderType ?? ''}
                        variant="standard"
                        onChange={(e) => {
                          setFieldValue(`biDrilling.${index}.orderType`, e.target.value ?? null).then(() => {
                            validateField(`biDrilling.${index}.orderType`);
                          });
                        }}
                        label={<FormLabelUI text="Rend. típ." />}
                        helperText={<FormErrorUI message={errors.biDrilling?.[index]?.orderType} />}
                        InputLabelProps={{ shrink: biDrilling.orderType !== '' }}
                      />
                    </div>
                    <div className="col-span-1 ">
                      <Field
                        type="number"
                        name={`biDrilling.${index}.limit`}
                        precision={1}
                        as={InputUI}
                        fullWidth
                        label={<FormLabelUI text="Limit" />}
                        variant="standard"
                        helperText={<FormErrorUI message={errors.biDrilling?.[index]?.limit} />}
                        InputLabelProps={{ shrink: biDrilling.limit !== '' && biDrilling.limit !== null }}
                      />
                    </div>

                    <div className="col-span-1 flex justify-center">
                      <button type="button" onClick={() => remove(index)} className="text-red-600 items-center">
                        <Delete />
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </FieldArray>
      <div className="grid grid-cols-3 gap-x-4">
        <div className="col-span-1 flex items-center mx-4">
          <Typography variant="h6" className="mr-2">
            Kapcsolódó riport
          </Typography>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-x-4 mx-4 mb-5 md:max-w-[100%] xl:max-w-[100%]">
        <div className="col-span-4">
          <Field
            type="autocomplete"
            name={`biDrillingLink.linkedReport`}
            component={AutoCompleteSelectUI}
            fullWidth
            label={<FormLabelUI text="Kapcsolat" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.biDrillingLink?.linkedReport} />}
            inputLabelProps={{ shrink: values.biDrillingLink?.linkedReport !== null }}
            onChange={(_e, newVal) => {
              setFieldValue(`biDrillingLink.linkedReport`, newVal?.value ?? null).then(() => {
                validateField(`biDrillingLink.linkedReport`);
              });
            }}
            //@TODO ezt ki kell vizsgálni!!!
            //@TODO egyik helyen működik a value másikon nem. miért??????????
            selectedValue={values.biDrillingLink?.linkedReport ?? null}
            selectedLabelValue={
              autocompleteData.biReports.find((ac) => ac.value === values.biDrillingLink?.linkedReport)?.label ?? ''
            }
            //value={biDrilling.linked ?? null}
            dataset={autocompleteData.biReports}
            isOptionEqualToValue={(option, value) => option.value === value}
          />
        </div>
      </div>
      <FieldArray name="biDrillingLink.biDrillingLinkField">
        <>
          {values?.biDrillingLink?.biDrillingLinkField?.length > 0 &&
            values?.biDrillingLink?.biDrillingLinkField.map((biDrillingLinkField, index) => {
              return (
                <div className="grid grid-cols-12 gap-x-4 mx-4 mb-5 md:max-w-[100%] xl:max-w-[100%]" key={index}>
                  <div className="col-span-2 ">
                    <Field
                      type="text"
                      name={`biDrillingLink.biDrillingLinkField.${index}.parentField`}
                      as={InputUI}
                      fullWidth
                      label={<FormLabelUI text="Mező riportban" />}
                      variant="standard"
                      helperText={
                        <FormErrorUI message={errors.biDrillingLink?.biDrillingLinkField?.[index]?.parentField} />
                      }
                      InputLabelProps={{ shrink: biDrillingLinkField.parentField !== '' }}
                      disabled
                    />
                  </div>

                  <div className="col-span-2 ">
                    <Field
                      type="autocomplete"
                      name={`biDrillingLink.biDrillingLinkField.${index}.childField`}
                      component={AutoCompleteSelectUI}
                      fullWidth
                      label={<FormLabelUI text="Mező a kapcsolódó riportban" />}
                      variant="standard"
                      helperText={
                        <FormErrorUI message={errors?.biDrillingLink?.biDrillingLinkField?.[index]?.childField} />
                      }
                      inputLabelProps={{ shrink: biDrillingLinkField.childField !== null }}
                      onChange={(_e, newVal) => {
                        setFieldValue(
                          `biDrillingLink.biDrillingLinkField.${index}.childField`,
                          newVal?.value ?? null
                        ).then(() => {
                          validateField(`biDrillingLink.biDrillingLinkField.${index}.childField`);
                        });
                      }}
                      //@TODO ezt ki kell vizsgálni!!!
                      value={biDrillingLinkField.childField ?? null}
                      dataset={linkedReportFields.autocompleteData ?? []}
                      isOptionEqualToValue={(option, value) => {
                        return option.value === value;
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </>
      </FieldArray>
    </>
  );
};

export default BiDrillForm;
