import React from 'react';
import { Field } from 'formik';

import { InputUI, AutoCompleteSelectUI, FormLabelUI, FormErrorUI } from '../Interface';

const FormFields = ({ viewField, selectedValues, values, validateField, setFieldValue, errors }) => {
  return (
    <div className="grid grid-cols-4 gap-4 mx-4 pb-6">
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="subMenuId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Form" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.subMenuId} />}
          onChange={(_e, newVal) => {
            setFieldValue('subMenuId', newVal?.value ?? null).then(() => {
              validateField('subMenuId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.subMenuId !== '' }}
          selectedValue={values.subMenuId}
          selectedLabelValue={selectedValues?.subMenu?.subMenuName}
          table={'submenus'}
          listType={{ id: 'subMenuId', name: 'subMenuName' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="selectListTitle"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Címke" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.selectListTitle} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.selectListTitle !== '' }}
        />
      </div>
    </div>
  );
};
export default FormFields;
