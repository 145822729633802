export const PropertyType = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  DECIMAL: 'DECIMAL',
  COMBO: 'COMBO',
  DATE: 'DATE',
  LONGTEXT: 'LONG_TEXT',
};

export const FieldType = [
  { label: 'Szöveges', value: PropertyType.TEXT },
  { label: 'Hosszú szöveges', value: PropertyType.LONGTEXT },
  { label: 'Egész szám', value: PropertyType.NUMBER },
  { label: 'Tört szám', value: PropertyType.DECIMAL },
  { label: 'Választólista', value: PropertyType.COMBO },
  { label: 'Dátum', value: PropertyType.DATE },
];
