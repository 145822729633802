import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Split from 'react-split';
import { Box, useMediaQuery } from '@mui/material';

import { FormIdentifiers } from '../../../config/FormIdentifiers';
import { sxConfig } from './FormBase.style';

const FormBase = ({ name, leftPanelContent, rightPanelContent }) => {
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [sizes, setSizes] = useState([30, 70]);

  useEffect(() => {
    const savedSizes = localStorage.getItem('split-sizes');
    if (savedSizes) {
      setSizes(JSON.parse(savedSizes));
    }
  }, []);

  const handleDragEnd = (newSizes) => {
    setSizes(newSizes);
    localStorage.setItem('split-sizes', JSON.stringify(newSizes));
  };

  if (!name) {
    console.error('name is required but was not provided.');
    return null; // Prevent the component from rendering if the identifier is not provided
  }

  return (
    <Box sx={sxConfig.containerBox}>
      {isMediumScreen ? (
        <Split
          sizes={sizes}
          minSize={0}
          gutterSize={5}
          direction="horizontal"
          style={{ display: 'flex', height: '100%', flexDirection: 'row' }}
          onDragEnd={handleDragEnd}
        >
          <Box sx={sxConfig.formBox}>{leftPanelContent}</Box>
          <Box sx={sxConfig.formBox}>{rightPanelContent}</Box>
        </Split>
      ) : (
        <>
          <Box sx={sxConfig.formBox}>{leftPanelContent}</Box>
          <Box sx={{ ...sxConfig.formBox, mt: 2 }}>{rightPanelContent}</Box>
        </>
      )}
    </Box>
  );
};
FormBase.propTypes = {
  name: PropTypes.oneOf(Object.values(FormIdentifiers)).isRequired,
  leftPanelContent: PropTypes.node.isRequired,
  rightPanelContent: PropTypes.node.isRequired,
};

export default FormBase;
