import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import huLocale from '@fullcalendar/core/locales/hu';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import useParam from '../../../context/ParamContext';
import { useLoader } from '../../../provider/LoaderProvider';
import { ColorPicker } from '../../../config/ColorPicker';
import EventChip from './EventChip';
import useView from '../../../hooks/useView';
import useSaveView from '../../../hooks/useSaveView';
import useUsers from '../../../context/UsersContext';
import vehicleResourcePlannerService from '../../../service/vehicleResourcePlanner.service';
import { NotificationType } from '../../../config';
import addNotification from '../../../utils/addNotification';

export const VehicleResourcePlanner = () => {
  const { getUser, user } = useUsers();
  const { getParam, params } = useParam();
  const saveView = useSaveView(user);
  const ganttFilter = useView('ganttFilter', user);
  const { showLoader, hideLoader } = useLoader();
  const [tasks, setTasks] = useState({ vehicles: [], employees: [], backgroundEvents: [] });
  const [dateRange, setDateRange] = useState({});
  const calendarRef = useRef(null);
  const [timeInterval, setTimeInterval] = useState({});
  const [intervalButtons, setIntervalButtons] = useState({});
  const [calendarViews, setCalendarViews] = useState({});
  const [selectedView, setSelectedView] = useState('');

  useEffect(() => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    setTimeInterval(slotIntervals.day);
    setCalendarViews(calendarDayViews);

    getParam();
    getUser();
    getEmployees();
  }, []);

  useEffect(() => {
    setIntervalButtons(generateButtons(selectedView));
  }, [selectedView]);

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      getVehicles();
    }
  }, [dateRange]);

  useEffect(() => {
    if (ganttFilter?.vehicleView && timeInterval) {
      setSelectedView(ganttFilter.vehicleView);

      const getSlotLabelFormat = () => {
        const view = ganttFilter.vehicleView;

        if (timeInterval.days === 1) {
          return calendarDayViews[view]?.slotLabelFormat || [];
        } else if (timeInterval.weeks === 1) {
          return calendarWeekViews[view]?.slotLabelFormat || [];
        } else if (timeInterval.months === 1) {
          return calendarMonthViews[view]?.slotLabelFormat || [];
        } else {
          return [];
        }
      };

      setCalendarViews((prevViews) => {
        const newViewConfig = {
          slotLabelFormat: getSlotLabelFormat(),
        };

        return {
          ...prevViews,
          [ganttFilter.vehicleView]: {
            ...prevViews[ganttFilter.vehicleView],
            ...newViewConfig,
          },
        };
      });
    }
  }, [ganttFilter?.vehicleView, timeInterval]);

  useEffect(() => {
    if (ganttFilter.vehicleInterval) {
      setTimeInterval(ganttFilter.vehicleInterval);
    }
  }, [ganttFilter.vehicleInterval, selectedView]);

  useEffect(() => {
    if (ganttFilter?.vehicleView && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(ganttFilter.vehicleView);
    }
  }, [ganttFilter.vehicleView]);

  useEffect(() => {
    if (selectedView && timeInterval && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      if (calendarApi) {
        setTimeout(() => {
          scrollToCurrentTimeUnit();
        }, 200);
      }
    }
  }, [selectedView, calendarRef, timeInterval]);

  const slotIntervals = {
    day: { days: 1 },
    month: { months: 1 },
    week: { weeks: 1 },
  };

  const calendarDayViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [{ day: 'numeric', weekday: 'short' }],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ month: 'short' }, { day: 'numeric' }],
    },
  };

  const calendarWeekViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
  };

  const calendarMonthViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ month: 'long' }],
    },
  };

  const getEmployees = () => {
    showLoader();
    vehicleResourcePlannerService
      .getEmployees()
      .then((data) => {
        setTasks((prevState) => ({ ...prevState, employees: data }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  const colorParam = params.VEHICLE_RESOURCE_PLANNER_CHIP_COLOR_THEME;
  const parsedParam = colorParam ? JSON.parse(colorParam) : [];

  const getVehicles = () => {
    //showLoader();
    vehicleResourcePlannerService
      .getVehicles({ dateRange })
      .then((data) => {
        const vehicles = data.map((task) => {
          const colorStyles = getColorStyles(task.eventData, parsedParam[0], 'VEHICLE');

          return {
            ...task,
            ...colorStyles,
          };
        });
        setTasks((prevState) => ({ ...prevState, vehicles }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getColorStyles = (event, colorProperties, eventType) => {
    const defaultStyles = { backgroundColor: '#fff', textColor: '#000' };

    const colorProperty = colorProperties?.[eventType];
    if (!colorProperty) return defaultStyles;

    const colorName = colorProperty.split('.').reduce((obj, key) => obj?.[key], event);
    if (!colorName) return defaultStyles;

    const colorObj = ColorPicker.find((color) => color.colorName === colorName);
    return {
      backgroundColor: colorObj?.colorBgCode || defaultStyles.backgroundColor,
      textColor: colorObj?.textColor || defaultStyles.textColor,
    };
  };

  const handleDatesSet = (dateInfo) => {
    const startDate = dayjs(dateInfo.start).startOf('day');
    const endDate = dayjs(dateInfo.end).endOf('day');

    if (dateRange.startDate?.isSame(startDate, 'day') && dateRange.endDate?.isSame(endDate, 'day')) {
      return;
    }
    setDateRange({ startDate, endDate });

    if (selectedView !== dateInfo.view.type) {
      setSelectedView(dateInfo.view.type);

      if (Object.keys(ganttFilter).length > 0) {
        saveView('ganttFilter', { vehicleView: dateInfo.view.type });
      } else {
        return;
      }
    }
  };

  const timeIntervalButtons = {
    day: {
      text: 'Nap',
      click: () => {
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);
        saveView('ganttFilter', { vehicleInterval: slotIntervals.day });
      },
    },
    week: {
      text: 'Hét',
      click: () => {
        setTimeInterval(slotIntervals.week);
        setCalendarViews(calendarWeekViews);
        saveView('ganttFilter', { vehicleInterval: slotIntervals.week });
      },
    },
    month: {
      text: 'Hónap',
      click: () => {
        setTimeInterval(slotIntervals.month);
        setCalendarViews(calendarMonthViews);
        saveView('ganttFilter', { vehicleInterval: slotIntervals.month });
      },
    },
  };

  const generateButtons = (viewName) => {
    let retVal = {};
    switch (viewName) {
      case 'resourceTimelineMonth':
        retVal = 'day,week,month';
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);

        break;
      case 'resourceTimelineYear':
        retVal = 'day,week,month';
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);

        break;
    }
    return retVal;
  };

  const scrollToCurrentTimeUnit = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const today = dayjs();

      let timeUnit;
      let difference;

      switch (selectedView) {
        case 'resourceTimelineMonth':
          timeUnit = 'day';
          const startOfMonth = dayjs().startOf('month');
          difference = today.diff(startOfMonth, timeUnit);
          break;
        case 'resourceTimelineYear':
          timeUnit = 'month';
          difference = today.diff(dayjs().startOf('year'), timeUnit);
          break;
        default:
          timeUnit = 'month';
          difference = today.diff(dayjs().startOf('year'), timeUnit);
          break;
      }

      calendarApi.scrollToTime({ [timeUnit]: difference });
    }
  };

  const updateEvent = (resizeInfo) => {
    const { event, revert } = resizeInfo;
    const employeeId = event.getResources()[0].id;

    const isResource = employeeId.startsWith('R');

    if (!isResource) {
      addNotification({
        content: 'Az esemény nem helyezhető csoportra!',
        type: NotificationType.ERROR,
      });
      revert();
      return;
    }

    const durationInMinutes = event.end ? dayjs(event.end).diff(dayjs(event.start), 'minute') : null;
    const duration = durationInMinutes / 60;
    const startDate = dayjs(event.start).toISOString();
    const endDate = event.end
      ? dayjs(event.end).toISOString()
      : dayjs(event.start).add(durationInMinutes, 'minute').toISOString();

    const updatedEventData = {
      employeeId: parseInt(employeeId.substring(1)),
      startDate,
      endDate,
      duration,
    };

    //showLoader();
    const id = event.id;

    vehicleResourcePlannerService
      .updateVehicle(updatedEventData, id)
      .then((data) => {
        addNotification({
          content: 'Sikeres módosítás!',
          type: NotificationType.SUCCESS,
        });
        setTasks((prev) => ({
          ...prev,
          employees: prev.employees.map((employee) => (employee.id === data.id ? data : employee)),
        }));
      })
      .catch(() => {
        revert();
      })
      .finally(() => {
        hideLoader();
      });
  };

  return (
    <div className="vehicleCalendar">
      <FullCalendar
        ref={calendarRef}
        customButtons={timeIntervalButtons}
        plugins={[resourceTimelinePlugin, interactionPlugin]}
        initialView={ganttFilter?.timeTableView ?? 'resourceTimelineMonth'}
        resources={tasks.employees}
        resourceOrder="visOrder"
        locales={[huLocale]}
        locale="hu"
        slotLabelFormat={[
          { month: 'long', year: 'numeric', weekday: 'long' },
          { hour: 'numeric', minute: '2-digit' },
        ]}
        datesSet={handleDatesSet}
        events={[...tasks.vehicles, ...tasks.backgroundEvents]}
        editable={true}
        eventResize={updateEvent}
        eventDrop={updateEvent}
        nowIndicator={true}
        eventClick={(clickInfo) => {
          if (clickInfo.event.display !== 'background') {
          }
        }}
        eventContent={(eventInfo) => {
          if (eventInfo.event.display !== 'background') {
            return <EventChip eventInfo={eventInfo} />;
          }
        }}
        headerToolbar={{
          left: `prev,next,today ${intervalButtons}`,
          center: 'title',
          right: 'resourceTimelineMonth,resourceTimelineYear',
        }}
        views={calendarViews}
        slotDuration={timeInterval}
        resourceAreaWidth="15%"
        resourceAreaHeaderContent="Erőforrás"
        scrollTimeReset={false}
      />
    </div>
  );
};
